import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EvaluationDetailsComponent } from "./evaluation-details/evaluation-details.component";
import { EvaluationComponent } from "./evaluation/evaluation.component";
import { EvaluationSingleComponent } from "./evaluation-single/evaluation-single.component";
import { NotesComponent } from "./notes/notes.component";
import { ProfileComponent } from "./profile/profile.component";
import { QuizComponent } from "./quiz/quiz.component";
import { VideoComponent } from "./video/video.component";
import { VideouploadComponent } from "./videoupload/videoupload.component";
import { InsightComponent } from "./insight/insight.component";
import { LiveClassesComponent } from "./live-classes/live-classes.component";
import { VideoViewerComponent } from "./video-viewer/video-viewer.component";
import { AdvertisingComponent } from "./advertising/advertising.component";
import { DigiConferenceComponent } from "./digi-conference/digi-conference.component";
import { InquiryComponent } from "./inquiry/inquiry.component";
// import { PaymentComponent } from "./payment/payment.component";
import { PaymentclassComponent } from "./paymentclass/paymentclass.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { AuthGuard } from "./guards/auth.guard";
import { NoticesComponent } from "./notices/notices.component";
import { Er404Component } from "./er404/er404.component";
import { CreateAdvertisingComponent } from "./create-advertising/create-advertising.component";
import { PaymentCardsComponent } from "./payment-cards/payment-cards.component";
import { PaymentConferenceComponent } from "./payment-conference/payment-conference.component";
const routes: Routes = [
  { path: "", component: ProfileComponent, canActivate: [AuthGuard] },
  { path: "quizes", component: QuizComponent, canActivate: [AuthGuard] },
  { path: "notes", component: NotesComponent, canActivate: [AuthGuard] },
  { path: "video", component: VideoComponent, canActivate: [AuthGuard] },
  { path: "videoupload", component: VideouploadComponent },
  {
    path: "evaluation",
    component: EvaluationComponent,
    canActivate: [AuthGuard],
  },
  // { path: "evaluationDetails/:id", component: EvaluationDetailsComponent },
  {
    path: "evaluationDetails/:classId/:quizId/:type",
    component: EvaluationDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "evaluationSingle",
    component: EvaluationSingleComponent,
    canActivate: [AuthGuard],
  },
  { path: "insight", component: InsightComponent, canActivate: [AuthGuard] },
  {
    path: "live-classes",
    component: LiveClassesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "video-viewer/:videoType/:teacherId/:subjectId/:classId/:videoId",
    component: VideoViewerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "advertising",
    component: AdvertisingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "create-advertisement",
    component: CreateAdvertisingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "create-advertisement/:type/:id",
    component: CreateAdvertisingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "create-advertisement/:type/:id/:paynow",
    component: CreateAdvertisingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "digi-conference",
    component: DigiConferenceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "notices",
    component: NoticesComponent,
    canActivate: [AuthGuard],
  },
  { path: "inquiry", component: InquiryComponent, canActivate: [AuthGuard] },
  {
    path: "paymentclass",
    component: PaymentclassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payments",
    component: PaymentCardsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "paymentconference",
    component: PaymentConferenceComponent,
    canActivate: [AuthGuard],
  },
  { path: "insight", component: InsightComponent, canActivate: [AuthGuard] },
  { path: "welcome", component: LandingPageComponent },

  // routes with params
  { path: "quizes/:classId", component: QuizComponent },
  { path: "notes/:classId", component: NotesComponent },
  { path: "video/:classId/:subjectId", component: VideoComponent },
  { path: "live-classes/:classId", component: LiveClassesComponent },
  { path: "evaluation/:classId/:tab", component: EvaluationComponent },
  { path: "videoupload/:teacherId/:type", component: VideouploadComponent },
  {
    path: "insight/:status/:classId/:classDay/:classType/:startTime/:endTime",
    component: InsightComponent,
  },
  { path: "insight/:status", component: InsightComponent },
  {
    path: "**",
    component: Er404Component,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
