import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatStepper } from "@angular/material";
import { CanceldialogComponent } from "../canceldialog/canceldialog.component";
import { video } from "../model/video";
import { UploadService } from "../services/upload.service";
import { DataService } from "../services/data.service";
import { DatetimeService } from "../util/datetime.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Class } from "../model/Class";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { AlertService } from "../services/alert.service";
import { NgxImageCompressService } from "ngx-image-compress";

@Component({
  selector: "app-videoupload",
  templateUrl: "./videoupload.component.html",
  styleUrls: ["./videoupload.component.scss"],
})
export class VideouploadComponent {
  cancelUpload: boolean = false;
  isUploaded = false;
  selectedFiles: FileList;
  video: video = new video();
  progress: { percentage: number; message: string; isComplete: boolean } = {
    percentage: 0,
    message: "Ready",
    isComplete: false,
  };
  uploadType: number = 3;
  isHovering: boolean;
  @ViewChild("stepper", { static: false }) private myStepper: MatStepper;
  videoForm: any;
  fileForm: any;
  videoThumbnail: string = "";
  public readonly NO_THUMBNAIL = "./../assets/templates/video_thumb.jpg";
  activeClassList: Class[] = this.dataService.getActiveClassList()
    ? this.dataService.getActiveClassList()
    : [];

  pause = false;

  constructor(
    public dialog: MatDialog,
    private imageCompress: NgxImageCompressService,
    public uploadService: UploadService,
    public datepipe: DatePipe,
    public dataService: DataService,
    private dateTime: DatetimeService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    const data = this.route.snapshot.paramMap.get("type");
    if (data) {
      this.uploadType = parseInt(this.route.snapshot.paramMap.get("type"));
    }
    this.buildFileForm();
    if (this.uploadType == 3) {
      //for teacher subject/class video
      this.buildVideoForm();
    } else if (this.uploadType == 7) {
      this.buildFeatureContentForm(); //for teacher feature video
    }
  }

  buildFileForm() {
    this.fileForm = this.formBuilder.group({
      file: new FormControl(null, Validators.compose([Validators.required])),
      fileName: new FormControl("File Name"),
      videoUrl: new FormControl(),
    });
  }

  buildVideoForm() {
    this.videoForm = this.formBuilder.group({
      videoId: new FormControl(
        this.guidGenerator(),
        Validators.compose([Validators.required])
      ),
      videoTitle: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      videoDescription: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      teacherId: new FormControl(
        this.route.snapshot.paramMap.get("teacherId"),
        Validators.compose([Validators.required])
      ),
      classId: new FormControl("", Validators.compose([Validators.required])),
      subjectId: new FormControl("", Validators.compose([Validators.required])),
      subjectName: new FormControl(""),
      srhSubjectName: new FormControl(""),
      srhVideoTitle: new FormControl(""),
      srhTeacherName: new FormControl(""),
      srhTimestamp: new FormControl(""),
      gradeId: new FormControl("", Validators.compose([Validators.required])),
      gradeName: new FormControl("", Validators.compose([Validators.required])),
      views: new FormControl(0),
      likes: new FormControl(0),
      dislikes: new FormControl(0),
      uploadedDate: new FormControl(
        this.formatDate(new Date()),
        Validators.compose([Validators.required])
      ),
      uploadedTime: new FormControl(
        this.getCurrentTime(new Date()),
        Validators.compose([Validators.required])
      ),
    });
  }

  buildFeatureContentForm() {
    this.videoForm = this.formBuilder.group({
      videoId: new FormControl(
        this.guidGenerator(),
        Validators.compose([Validators.required])
      ),
      videoTitle: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      videoDescription: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      teacherId: new FormControl(
        this.route.snapshot.paramMap.get("teacherId"),
        Validators.compose([Validators.required])
      ),
      views: new FormControl(0),
      likes: new FormControl(0),
      dislikes: new FormControl(0),

      uploadedDate: new FormControl(
        this.formatDate(new Date()),
        Validators.compose([Validators.required])
      ),
      uploadedTime: new FormControl(
        this.getCurrentTime(new Date()),
        Validators.compose([Validators.required])
      ),
    });
  }

  selectClass(event) {
    let gradeId = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.videoForm.value.classId).gradeId;

    let gradeName = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.videoForm.value.classId).gradeName;

    let subjectId = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.videoForm.value.classId).subjectId;
    let subjectName = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.videoForm.value.classId).subjectName;

    this.videoForm.patchValue({
      subjectId: subjectId,
      gradeId: gradeId,
      gradeName: gradeName,
      subjectName: subjectName,
      srhSubjectName: subjectName.toLowerCase(),
    });
  }

  videoPauseResume() {
    console.log("|| |>");
    if (this.pause) {
      this.uploadService.resumeUploadTask();
    } else {
      this.uploadService.pauseUploadTask();
      this.pause = true;
    }
  }

  openCancelDialog() {
    this.uploadService.pauseUploadTask();
    this.dialog.open(CanceldialogComponent);
  }

  selectFile(files: FileList, stepper: MatStepper) {
    this.selectedFiles = files;
    if (this.selectedFiles) {
      this.isUploaded = true;

      this.fileForm.patchValue({
        file: files[0],
        fileName: files[0].name,
      });

      this.getVideoCover(files[0], 1.5)
        .then((thumb: string) => {
          this.imageCompress
            .compressFile(thumb, -1, 70, 70)
            .then((output) => {
              this.videoThumbnail = output;
              this.video.videoThumbnail = output;
            })
            .finally(() => {
              this.video.fileName = files[0].name;
              stepper.next();
              if (this.uploadType == 3) {
                this.uploadVideo();
              } else if (this.uploadType == 7) {
                this.uploadFeature();
              }
            });
        })
        .catch((er) => {
          this.video.fileName = files[0].name;
          stepper.next();
          if (this.uploadType == 3) {
            this.uploadVideo();
          } else if (this.uploadType == 7) {
            this.uploadFeature();
          }
        });
    }
  }

  cancelUploading() {
    if (this.cancelUpload) {
      this.cancelUpload = false;
    } else {
      this.cancelUpload = true;
    }
  }

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }

  getCurrentTime(date: Date) {
    return this.datepipe.transform(date, "HH:mm:ss");
  }

  uploadVideo() {
    const {
      videoId,
      teacherId,
      teacherPic,
      videoTitle,
      videoDescription,
      classId,
      views,
      likes,
      dislikes,
      uploadedDate,
      uploadedTime,
      subjectId,
      gradeId,
      subjectName,
      gradeName,
      srhSubjectName,
      srhVideoTitle,
      srhTeacherName,
      teacherName,
      srhTimestamp,
      videoThumbnail,
    } = this.videoForm.value;
    const { file, fileName, videoUrl } = this.fileForm.value;
    let video = {
      videoId,
      teacherId,
      teacherPic,
      videoTitle,
      videoDescription,
      classId,
      views,
      likes,
      dislikes,
      uploadedDate,
      uploadedTime,
      file,
      fileName,
      videoUrl,
      subjectId,
      gradeId,
      subjectName,
      gradeName,
      srhSubjectName,
      srhVideoTitle,
      srhTeacherName,
      teacherName,
      srhTimestamp,
      videoThumbnail,
    };
    video.videoThumbnail = this.videoThumbnail;
    video.teacherName = this.dataService.getTeacherDetails().teacherName;
    video.srhSubjectName = this.videoForm.value.subjectName.toLowerCase();
    video.srhVideoTitle = this.videoForm.value.videoTitle.toLowerCase();
    video.teacherPic = this.dataService.getTeacherDetails().profilePic;
    video.srhTeacherName = this.dataService
      .getTeacherDetails()
      .teacherName.toLowerCase();
    video.fileName = video.fileName.replaceAll(" ", "-");
    video.file = new File([file], video.fileName, {
      type: video.file.type,
      lastModified: video.file.lastModified,
    });
    //console.log(video.fileName);
    //console.log(video.file);
    video.srhTimestamp = new Date().getTime() * -1;
    this.uploadService.onFileUpload(video, this.progress, this.uploadType);
  }

  uploadFeature() {
    const {
      videoId,
      teacherId,
      videoTitle,
      videoDescription,
      views,
      likes,
      dislikes,
      uploadedDate,
      uploadedTime,
      videoThumbnail,
    } = this.videoForm.value;
    const { file, fileName, videoUrl } = this.fileForm.value;

    let video = {
      videoId,
      teacherId,
      videoTitle,
      videoDescription,
      views,
      likes,
      dislikes,
      uploadedDate,
      uploadedTime,
      file,
      fileName,
      videoUrl,
      videoThumbnail,
    };
    video.videoThumbnail = this.videoThumbnail;
    video.fileName = video.fileName.replaceAll(" ", "-");
    video.file = new File([file], video.fileName, {
      type: video.file.type,
      lastModified: video.file.lastModified,
    });
    this.uploadService.onFileUpload(video, this.progress, this.uploadType);
  }
  closeAll() {
    this.alertService
      .showAlert(
        "Do you need to cancel all preocess",
        MessageButtons.YesNo,
        MessageType.warning
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === "yes") {
          window.close();
        } else {
          return;
        }
      });
  }
  close() {
    window.close();
  }

  getTime(time) {
    return this.dateTime.changeTimeFormat(time);
  }
  stepOneValid() {
    return true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    const mimeType = files[0].type;
    ////console.log(mimeType);
    if (mimeType.match(/video\/*/) == null) {
      this.alertService.showAlert(
        "Please add video file only",
        MessageButtons.ok,
        MessageType.warning
      );
      return;
    }
    this.selectFile(files, this.myStepper);
  }

  getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file:" + ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          ////console.log('video is now paused at %ss.', seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          var daraUri = canvas.toDataURL("image/jpeg");
          resolve(daraUri);
        });
      });
    });
  }
}
