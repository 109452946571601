import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { map } from "rxjs/operators";

import { ActivatedRoute } from "@angular/router";
import { DatabaseManager } from "../database/database-manager";
import { Marks } from "../model/Marks";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatetimeService } from "../util/datetime.service";
import { EvaluationSingleComponent } from "./../evaluation-single/evaluation-single.component";
import { AuthService } from "../services/auth.service";
import { Quiz } from "../model/quiz";

@Component({
  selector: "app-evaluation-details",
  templateUrl: "./evaluation-details.component.html",
  styleUrls: ["./evaluation-details.component.scss"],
})
export class EvaluationDetailsComponent implements OnInit {
  submittedQuiz;

  type: any = "evaluationList";
  classId: string;
  quizId: string;
  marksList: Marks[] = [];
  currentMarks: Marks = null;
  quizEvaluated: boolean;
  quiz: Quiz;
  quizListType: number; // <!-- 1 for evaluted quiz && 0 for non evaluted -->
  teacherId: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public dataService: DataService,
    public uploadService: UploadService,
    private dbManager: DatabaseManager,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.auth.isUserAvailable().then(res => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.classId = this.route.snapshot.paramMap.get("classId");
        this.quizId = this.route.snapshot.paramMap.get("quizId");
        this.quizListType = parseInt(this.route.snapshot.paramMap.get("type"));
        this.dbManager
          .getQuiz(
            this.auth.userData.uid,
            this.classId,
            this.quizId,
            this.quizListType
          )
          .then((result: any) => {
            let data = result.data();
            this.quiz = data;
            if (this.quiz) {
              this.getMarks()
            }
          });
      }
    })
  }

  getMarks() {
    this.dbManager
      .getMarksList(this.classId, this.quizId, this.teacherId)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => (c.payload.doc.data()))))
      .subscribe((data) => {
        this.marksList = data;
      });
  }

  // getNonEvalutedMarks() {
  //   this.dbManager
  //     .getAllNonEvaluatedQuizList(this.classId, this.quizId)
  //     .snapshotChanges()
  //     .pipe(map((changes) => changes.map((c) => ({ ...c.payload.val() }))))
  //     .subscribe((data) => {
  //       this.marksList = data;
  //     });
  // }

  isQuizEvaluated() {
    let value = true;
    this.marksList.forEach((x) => {
      if (!x.isEvaluated) {
        value = false;
      }
    });
    return value;
  }

  setType(type: any, mark: Marks) {
    this.type = type; //0 for evaluted 1 for un evaluated
    this.currentMarks = mark;
  }

  openDialog(mark: Marks) {
    this.dialog.open(EvaluationSingleComponent, {
      data: {
        marks: mark,
        quiz: this.quiz,
        type: this.quizListType,
        teacherId: this.teacherId
      },
    });
  }

  evaluateQuiz() {
    this.quiz.teacherEvaluated = true;
    this.dbManager.saveQuiz(this.quiz)
  }
}
