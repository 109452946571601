import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxImageCompressService } from "ngx-image-compress";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Constants } from "../model/Constants";
import { AlertService } from "../services/alert.service";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { UploadService } from "../services/upload.service";

@Component({
  selector: "app-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.component.scss"],
})
export class ImageUploadComponent implements OnInit {
  imageChangedEvent: any = "";
  croppedImage: string = "";
  isImageValid: boolean = false;
  showCropped: boolean = false;
  selectedFile: File;
  maxWidth: number;
  maxHeight: number;
  progress: { percentage: number } = { percentage: 0 };
  ratio: number;
  backText: string = "Close";
  defProfilePic: string = "";
  defCoverPic: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageCompress: NgxImageCompressService,
    private dialogRef: MatDialogRef<ImageUploadComponent>,
    private alertService: AlertService,
    private uploadService: UploadService,
    public profileValidator: ProfileValidatorService,
    private dbManager: DatabaseManager
  ) {
    // img type 2 for cover pic
    if (this.data.imgType == Constants.FIRST_TYPE) {
      this.maxWidth = 180;
      this.maxHeight = 180;
      this.ratio = 1 / 1;
    } else if (this.data.imgType == Constants.SECOND_TYPE) {
      this.maxWidth = Constants.COVER_PIC_WIDTH;
      this.maxHeight = Constants.COVER_PIC_HEIGHT;
      this.ratio = 16 / 9;
    }
  }

  ngOnInit() {}

  fileChangeEvent(event: any): void {
    this.selectedFile = event.target.files[0];
    var fSize = event.target.files[0].size / 1024;
    if (fSize > Constants.MAX_PROFILE_PIC_SIZE) {
      this.alertService.showAlert(
        "The maximum size for the image upload is 6MB.",
        MessageButtons.ok,
        MessageType.accessBlocked
      );
      return;
    }
    if (event.target.files[0] && event.target.files.length) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          this.isImageValid = true;
          this.imageChangedEvent = event;
        };
      };
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imageCompress.compressFile(event.base64, -1, 50, 50).then((result) => {
      //console.log('f');
      this.croppedImage = result;
    });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  save() {
    this.showCropped = true;
    this.backText = "Back";
  }
  back() {
    if (this.showCropped) {
      this.showCropped = false;
      this.backText = "Close";
    } else {
      this.dialogRef.close();
    }
  }

  checkImgValidity(width, height) {
    if (this.data.imgType == Constants.FIRST_TYPE) {
      var value =
        width <= Constants.PROFILE_PIC_WIDTH &&
        height <= Constants.PROFILE_PIC_HEIGHT
          ? true
          : false;
    } else if (this.data.imgType == Constants.SECOND_TYPE) {
      var value =
        width <= Constants.COVER_PIC_WIDTH &&
        height <= Constants.COVER_PIC_HEIGHT
          ? true
          : false;
    }
    return value;
  }

  base64ToImage(dataURI) {
    const fileDate = dataURI.split(",");
    // const mime = fileDate[0].match(/:(.*?);/)[1];
    const byteString = atob(fileDate[1]);
    const bytes = new Array(byteString.length);
    for (var x = 0; x < byteString.length; x++) {
      bytes[x] = byteString.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], { type: "image/jpeg" });
    return blob;
  }

  upload() {
    const teacherId = this.data.teacherId;
    if (this.data.imgType == Constants.FIRST_TYPE) {
      this.dbManager.updateTeacherProfile(
        { profilePic: this.croppedImage },
        teacherId
      );
      let uploadFile = {
        file: this.base64ToImage(this.croppedImage),
        teacherId,
      };

      this.uploadService.pushFileToStorage(uploadFile, this.progress, 5);
    } else if (this.data.imgType == Constants.SECOND_TYPE) {
      this.dbManager.updateTeacherProfile(
        { coverPic: this.croppedImage },
        teacherId
      );
      let uploadFile = {
        file: this.base64ToImage(this.croppedImage),
        teacherId,
      };
      this.uploadService.pushFileToStorage(uploadFile, this.progress, 6);
    }

    this.close();
  }
  close() {
    this.dialogRef.close();
  }

  remove() {
    const teacherId = this.data.teacherId;
    if (this.data.imgType == Constants.FIRST_TYPE) {
      this.dbManager.updateTeacherProfile({ profilePic: "" }, teacherId);
    } else if (this.data.imgType == Constants.SECOND_TYPE) {
      this.dbManager.updateTeacherProfile({ coverPic: "" }, teacherId);
    }
  }
}
