/**
 * Profile Validator
 * Version 2.0
 */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DatabaseManager } from '../database/database-manager';
import { BankDetails } from '../model/BankDetails';
import { Teacher } from '../model/teacher';
import { AuthService } from './auth.service';
import { Notification, NotificationService } from './notification.service';

export interface ValidateResult {
  isValid: boolean,
  validationMsg: string,
}

@Injectable({
  providedIn: 'root'
})
export class ProfileValidatorService {

  //cost
  private readonly BELL_COLOR_NORMAL: string = "#2e3131";
  private readonly NO_DIP: string = "assets/images/noprofile.png";
  private readonly NO_DIC: string = "assets/images/coverpic.png";

  private validUser: boolean = false;
  teacher: Teacher
  bankDetails: BankDetails

  constructor(private authService: AuthService,
    private notifyService: NotificationService, private dbManager: DatabaseManager) {

    //wait until user connecting with the server
    authService.isUserAvailable().then(res => {
      if (res) {
        this.checkProfile();
      }
    });
  }

  private checkProfile() {
    if (this.authService.isLoggedIn) {
      this.dbManager.getTeacherDetails(this.authService.userData.uid)
        .pipe(map(c => (c.payload.doc.data())))
        .subscribe((data: Teacher) => {

          this.teacher = data;
          this.dbManager.getBankDetails(this.teacher.teacherId).then(element => {
            this.bankDetails = element;
            var validResult = this.validateUser(this.teacher, this.bankDetails)
            let message: Notification;
            if (!validResult.isValid) {
              message = {
                bellColor: "#f89406",
                message: validResult.validationMsg,
                priority: 1
              }
              this.validUser = false;
            } else {
              message = {
                bellColor: this.BELL_COLOR_NORMAL,
                message: "",
                priority: -1
              }
              this.validUser = true;
            }
            this.notifyService.sendNewMessage(message);
          })
        });
    }
  }

  private validateUser(user: Teacher, bankData: BankDetails): ValidateResult {
    var validate: ValidateResult = {
      isValid: false,
      validationMsg: "000x1",
    }

    if (user.teacherName === "" || user.teacherName.length === 0 || !user.teacherName) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your name."
      return validate;
    }

    if (user.teacherName.length < 3 && user.teacherName.length > 15) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Name must be more or equal to 3 characters and less than or equal to 15 characters."
      return validate;
    }
    if (user.teacherInfo === " " || user.teacherInfo.length === 0 || !user.teacherInfo) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your info."
      return validate;
    }
    if (user.teacherInfo.length < 10) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Your Info must be equal or more than 10 characters."
      return validate;
    }
    try {
      if (user.nic === " " || user.nic.length === 0 || !user.nic) {
        validate.isValid = false;
        validate.validationMsg = "Your profile is not complete yet. Please enter your NIC number."
        return validate;
      }
      if (user.nic.length < 10) {
        validate.isValid = false;
        validate.validationMsg = "Your profile is not complete yet. NIC must be  more than or equal to 10 characters."
        return validate;
      }
    } catch (exception) { }
    if (user.teacherAddress === " " || user.teacherAddress.length === 0 || !user.teacherAddress) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your address."
      return validate;
    }
    if (user.teacherAddress.length < 5) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Address must be equal or more than 5 characters."
      return validate;
    }
    if (user.city.length < 1) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Enter your city"
      return validate;
    }
    if ((user.province === "--Select--") || (user.province === "")) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please select your province"
      return validate;
    }

    if (user.postalcode === " " || user.postalcode.length === 0 || !user.postalcode) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your postal code."
      return validate;
    }
    if (!(user.postalcode.length >= 3) || !(user.postalcode.length <= 5)) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Postal Code must be equal to 5 characters."
      return validate;
    }
    if ((!this.validateTelephone(user.teacherTp1))) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter contact number"
      return validate;
    }
    if (user.teacherTp1.length < 10) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Contact number must be equal to 10 characters."
      return validate;
    }
    if (!bankData.bankName || !bankData.accountNumber || !bankData.branchName) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Bank details should be provided."
      return validate;
    }

    validate.isValid = true;
    validate.validationMsg = "no errors"
    return validate;
  }

  private validateTelephone(number: string): boolean {
    if ((number === " ") || (number === "")) {
      return false;
    }
    if (number.length < 10) {
      return false;
    }
    return true;
  }

  public isProfileValid(): boolean {
    return this.validUser;
  }

  public getUserProfilePic(): string {
    if ((this.teacher !== null) && (this.teacher !== undefined))
      return this.teacher.profilePic !== "" ? this.teacher.profilePic : this.NO_DIP;
    else
      return this.NO_DIP;
  }

  public getUserCoverPic(): string {
    if ((this.teacher !== null) && (this.teacher !== undefined))
      return this.teacher.coverPic !== "" ? this.teacher.coverPic : this.NO_DIP;
    else
      return this.NO_DIP;
  }

  /**
   * Request to check bank details and re-validate the profile
   */
  public updateBankDetails(): void {
    this.dbManager.getBankDetails(this.teacher.teacherId).then(element => {
      this.bankDetails = element;
      var validResult = this.validateUser(this.teacher, this.bankDetails)
      let message: Notification;
      if (!validResult.isValid) {
        message = {
          bellColor: "#f89406",
          message: validResult.validationMsg,
          priority: 1
        }
        this.validUser = false;
      } else {
        message = {
          bellColor: this.BELL_COLOR_NORMAL,
          message: "",
          priority: -1
        }
        this.validUser = true;
      }
      this.notifyService.sendNewMessage(message);
    })
  }

}