import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Notice } from '../model/notice';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  notices: Notice[];
  hasNext: boolean;
  hasPrevious: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public note: any, public dialog: MatDialog) {
    this.notices = note.notices;
    this.hasNext = note.hasNext;
    this.hasPrevious = note.hasPrevious;
  }

  ngOnInit(): void {
  }

  openNewMessage(id: number) {
    if (this.notices.length > id + 1) {
      this.hasNext = true;
    } else {
      this.hasNext = false;
    }
    if (0 < id) {
      this.hasPrevious = true;
    } else {
      this.hasPrevious = false;
    }
    this.dialog.open(NoteComponent, { data: { id: id, hasNext: this.hasNext, hasPrevious: this.hasPrevious, notices: this.notices } });
  }

}
