import { Component, Input, OnInit } from "@angular/core";
import { map } from "rxjs/operators";

import { MatDialog } from "@angular/material";
import { CreateLiveClassComponent } from "../create-live-class/create-live-class.component";
import { DatabaseManager } from "../database/database-manager";
import { LiveClass } from "../model/LiveClass";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatetimeService } from "../util/datetime.service";
import { Class } from "../model/Class";
import { Constants } from "../model/Constants";
import { AnimationOptions } from "ngx-lottie";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { AlertService } from "../services/alert.service";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-live-classes",
  templateUrl: "./live-classes.component.html",
  styleUrls: ["./live-classes.component.scss"],
})
export class LiveClassesComponent implements OnInit {
  liveClasses: LiveClass[] = [];
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];

  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : "";
  public readonly addNewClass = Constants.FIRST_TYPE;
  public readonly editClass = Constants.SECOND_TYPE;
  teacherId: any;
  termSubscriber: Subscription;

  constructor(
    public dialog: MatDialog,
    private title: Title,
    public uploadService: UploadService,
    private dbManager: DatabaseManager,
    public datetime: DatetimeService,
    public dataService: DataService,
    public profileValidator: ProfileValidatorService,
    private alertService: AlertService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Live Classes | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
            ////console.log(this.classId)
          }

          ////console.log("tab",this.teacherId,this.classId)

          if (this.classId && this.teacherId) {
            this.getLiveClasses();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.termSubscriber.unsubscribe();
  }

  onSelectChange() {
    if (this.classId) {
      this.route.params["classId"] = this.classId;
      this.router.navigate(["/live-classes", this.classId]);
    }
  }

  getLiveClasses() {
    if (this.classId) {
      this.route.params["classId"] = this.classId;
      this.router.navigate(["/live-classes", this.classId]);
    }
    this.dbManager
      .getTeacherLiveClass(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        ////console.log("live clas",data)
        this.liveClasses = data;
      });
  }

  openLiveClassDialog(type, liveClass) {
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(CreateLiveClassComponent, {
        data: {
          selectedClass: liveClass,
          type: type,
        },
      });
    } else {
      this.alertService.showAlert(
        "profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
}
