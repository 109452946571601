import { Injectable, NgZone } from "@angular/core";
import { User, LoginState } from "../services/user";
import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import {
  DialogResult,
  MessageboxComponent,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { MatDialog } from "@angular/material/dialog";
import { DatabaseManager } from "../database/database-manager";
import { Teacher } from "../model/teacher";
import { DataService } from "./data.service";
import { map } from "rxjs/operators";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //constants
  private readonly WRONG_PASSWORD = "auth/wrong-password";
  private readonly INVALID_EMAIL = "auth/invalid-email";
  private readonly NOT_REGISTERD = "auth/user-not-found";

  userData: any; // Save logged in user data
  processSucceed: boolean = true;
  authMessage: string;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public dialog: MatDialog, //mat dialog to display extra messages
    private dbManager: DatabaseManager, //database manager
    private dataService: DataService,
    public router: Router,
    private alertService: AlertService
  ) {
    this.initilizeUser();
    this.checkParms();
  }

  //initialize user
  initilizeUser() {
    /* Saving user data in localstorage when
        logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem("user", JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem("user"));
        this.setTeacherData(this.userData.uid);
        this.setTeacherClass(this.userData.uid);
        // *#remove this*
        //this.setTeacherSubjectIds(this.userData.uid);
        // *#remove this*
      } else {
        localStorage.setItem("user", null);
        JSON.parse(localStorage.getItem("user"));
      }
    });
  }

  private checkParms() {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the action to complete.
    var mode = urlParams.get("mode");
    // Get the one-time code from the query parameter.
    var actionCode = urlParams.get("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    var continueUrl = urlParams.get("continueUrl");
    // (Optional) Get the language code if available.
    var lang = urlParams.get("lang") || "en";

    // Handle the user management action.
    switch (mode) {
      case "resetPassword":
        // alert("hello");
        // Display reset password handler and UI.
        // handleResetPassword(auth, actionCode, continueUrl, lang);
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        //  handleRecoverEmail(auth, actionCode, lang);
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        this.handleVerifyEmail(actionCode, continueUrl, lang);
        break;
      default:
      // Error: invalid mode.
    }
  }

  handleResetPassword() {}

  handleRecoverEmail() {}

  private handleVerifyEmail(actionCode, continueUrl, lang) {
    // Try to apply the email verification code.
    this.afAuth.auth
      .applyActionCode(actionCode)
      .then((resp) => {
        // Email address has been verified.
        this.showMessage(
          "Click on the orange bell icon to complete your account and finish registration.",
          MessageButtons.ok,
          MessageType.wow
        );
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        if (this.loginState === LoginState.notVerified) {
          /* this.showMessage("It seems like your verification link has been expired/invalid.", MessageButtons.resend_verification_email, MessageType.accessBlocked).afterClosed().subscribe((result: DialogResult) => {
                   switch (result) {
                     case DialogResult.resend_verification_email:
                       this.SendVerificationMail();
                       break;
                   }
                 });*/
        }
      });
  }

  //return user availability
  isUserAvailable() {
    return new Promise<boolean>((resolve) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.userData = user;
          resolve(true);
        } else {
        }
      });
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          //alert("logged In");
        });
      })
      .catch((error) => {
        this.generateError(error.code);
        this.processSucceed = false;
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
                up and returns promise */
        this.SendVerificationMail();

        //add new teacher to the system
        var newUser: Teacher = {
          teacherId: result.user.uid,
          teacherEmail: result.user.email,
          srhTeacherName: "",
          teacherName: "",
          nic: "",
          teacherInfo: "",
          profilePic: "assets/images/noprofile.png",
          coverPic: "",
          teacherTp1: "",
          teacherAddress: "",
          city: "",
          postalcode: "",
          province: "",
          country: "",
          reviewId: "",
          revisionClz: false,
          groupClz: false,
          paperClz: false,
          seminarClz: false,
          teacherSubjects: [],
          ratingSet: [0, 0, 0, 0, 0],
        };
        this.dbManager.addNewTeacher(newUser);
      })
      .catch((error) => {
        // window.alert(error.message)
        this.authMessage = error.message;
        this.processSucceed = false;
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      this.alertService
        .showAlert(
          "Verification email has sent to " + this.userData.email,
          MessageButtons.ok,
          MessageType.AccountCreated
        )
        .afterClosed()
        .subscribe((result: DialogResult) => {});
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.processSucceed = true;
        this.authMessage = "Password reset email sent, check your inbox.";
        this.alertService
          .showAlert(
            "Password reset email sent, check your inbox.",
            MessageButtons.ok,
            MessageType.Success
          )
          .afterClosed()
          .subscribe((result: DialogResult) => {});
      })
      .catch((error) => {
        this.generateError(error.code);
        this.processSucceed = false;
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    return user !== null &&
      (user.emailVerified !== false ||
        user.providerData[0].providerId === "facebook.com")
      ? true
      : false;
  }

  // Return login state by checking user data with verification
  get loginState(): any {
    const user = JSON.parse(localStorage.getItem("user"));
    if (
      user !== null &&
      (user.emailVerified !== false ||
        user.providerData[0].providerId === "facebook.com")
    )
      return LoginState.VerfiedLogin;
    else if (user !== null) return LoginState.notVerified;
    else return LoginState.notLoggedIn;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          // this.router.navigate(['dashboard']);
        });
        //check whether this user is a new member
        this.dbManager
          .isTeacherAvailable(result.user.uid)
          .then((output: boolean) => {
            if (!output) {
              //add new teacher to the system
              var newUser: Teacher = {
                teacherId: result.user.uid,
                teacherEmail: result.user.email,
                teacherName: "",
                srhTeacherName: "",
                nic: "",
                teacherInfo: "",
                profilePic: "assets/images/noprofile.png",
                coverPic: "",
                teacherTp1: "",
                teacherAddress: "",
                city: "",
                postalcode: "",
                province: "--Select--",
                country: "",
                reviewId: "",
                revisionClz: false,
                groupClz: false,
                paperClz: false,
                seminarClz: false,
                teacherSubjects: [],
                ratingSet: [0, 0, 0, 0, 0],
              };
              this.dbManager.addNewTeacher(newUser);
            }
          });
      })
      .catch((error) => {
        window.alert(error);
        ////console.log(error);
      });
  }

  setTeacherClass(uid) {
    this.dbManager
      .getTeacherAllClass(uid)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        //////console.log("teacher class list", data);
        this.dataService.setClassList(data);
      });
  }

  setTeacherData(id) {
    this.dbManager
      .getTeacherDetails(id)
      .pipe(map((c) => c.payload.doc.data()))
      .subscribe((data: Teacher) => {
        this.dataService.setSubjectIdList(data.teacherSubjects);
        this.setTeacherSubjects();
        this.dataService.setTeacherDetails(data);
      });
  }

  setTeacherSubjects() {
    this.dbManager
      .getSubjects()
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        ////console.log("admin subject list", data);
        let list = data.filter((x) => {
          if (
            this.dataService.getSubjectIdList().includes(x.subjectId.trim())
          ) {
            return true;
          }
        });
        this.dataService.setSubscribedSubjectList(list);
      });
  }

  updateDisplayName(name: string) {
    this.afAuth.auth.currentUser
      .updateProfile({
        displayName: name,
      })
      .then(() => {
        //////console.log('DisplayName updated')
      });
  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem("user");
      sessionStorage.removeItem("activeClassList");
      this.router.navigate(["welcome"]);
      // alert("go");
      this.userData = null;
    });
  }

  //generate Error message from the service
  private generateError(code: string) {
    if (code == this.WRONG_PASSWORD) {
      this.authMessage = "Incorrect password. Please try again!";
    } else if (code == this.INVALID_EMAIL) {
      this.authMessage = "The email you entered is incorrect.";
    } else if (code == this.NOT_REGISTERD) {
      this.authMessage = "You are not registered. Please Sign Up now!";
    }
  }

  /**
   * Clerar authMessage
   */
  public clearAuthMessage() {
    this.authMessage = "";
    this.processSucceed = false;
  }

  showMessage(
    message: string,
    messageButtons: MessageButtons,
    messageType: MessageType
  ): any {
    const dialogRef = this.dialog.open(MessageboxComponent, {
      width: "500px",
      maxWidth: "95%",
      data: {
        message: message,
        messageButtons: messageButtons,
        messageType: messageType,
      },
    });
    return dialogRef;
  }
}
