import { Component, OnInit, SimpleChanges } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AnimationOptions } from "ngx-lottie";

// Create class Dialog box import
import { MatDialog } from "@angular/material";

// Profile Validater
import { ProfileValidatorService } from "../services/profile-validator.service";

// New Digi-Conference Dialog
import { CreateDigiConferenceComponent } from "../create-digi-conference/create-digi-conference.component";
import { DatabaseManager } from "../database/database-manager";
import { AuthService } from "../services/auth.service";

import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { AlertService } from "../services/alert.service";

new Date("1002:12:23 " + "T 21:21");

@Component({
  selector: "app-digi-conference",
  templateUrl: "./digi-conference.component.html",
  styleUrls: ["./digi-conference.component.scss"],
})
export class DigiConferenceComponent implements OnInit {
  teacherId: any;
  conferences: any = null;
  emphty = true;
  option: AnimationOptions = {
    path: "/assets/lti/lti_not-available-feature.json",
    loop: false,
  };
  haveexpconf = false;

  constructor(
    private title: Title,
    public profileValidator: ProfileValidatorService,
    public dialog: MatDialog,
    private db: DatabaseManager,
    private userauth: AuthService
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Digi Conference | Tutor - Digi Panthiya");
    this.userauth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.userauth.userData.uid;
        this.db
          .getAllConference(this.teacherId)
          .then((responce) => {
            this.conferences = responce;
            this.emphty = this.conferences.length > 0 ? false : true;
          })
          .catch((err) => console.error(err));
      }
    });
  }

  editConference(i: number) {
    if (this.profileValidator.isProfileValid()) {
      this.dialog
        .open(CreateDigiConferenceComponent, {
          data: this.conferences[i],
        })
        .afterClosed()
        .subscribe((res) => {
          this.db
            .getAllConference(this.teacherId)
            .then((responce) => {
              this.conferences = responce;
              this.emphty = this.conferences.length > 0 ? false : true;
            })
            .catch((err) => console.error(err));
        });
    }
  }

  openDigiConferenceDialog() {
    if (this.profileValidator.isProfileValid()) {
      this.dialog
        .open(CreateDigiConferenceComponent)
        .afterClosed()
        .subscribe((res) => {
          this.db
            .getAllConference(this.teacherId)
            .then((responce) => {
              this.conferences = responce;
              this.emphty = this.conferences.length > 0 ? false : true;
            })
            .catch((err) => console.error(err));
        });
    }
  }

  isExpired(startDate) {
    let today = new Date().toISOString().slice(0, 10);
    if (today > startDate) {
      this.haveexpconf = true;
      return true;
    } else {
      return false;
    }
  }
}
