import { LiveClass } from "./../model/LiveClass";
import { classType } from "./../model/Class";
import { Quiz } from "./../model/quiz";
import { Component, Inject, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  Form,
  ControlContainer,
  ValidationErrors,
} from "@angular/forms";
import { UploadService } from "../services/upload.service";
import { DataService } from "../services/data.service";
import { DatabaseManager } from "../database/database-manager";
import { DatetimeService } from "../util/datetime.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Class } from "../model/Class";
import { AlertService } from "../services/alert.service";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";

@Component({
  selector: "app-quizupload",
  templateUrl: "./quizupload.component.html",
  styleUrls: ["./quizupload.component.scss"],
})
export class QuizuploadComponent implements OnInit {
  isUploaded = false;
  selectedFiles: FileList = null;
  currentQuiz: Quiz = new Quiz();
  progress: { percentage: number } = { percentage: 0 };

  ansArray: any[];
  isFileChanged: boolean = false;
  mcqForm: FormGroup;
  startTime: string;
  endTime: string;
  duration: { hour: number; minute: number };
  format: string = "pdf";
  answer: string = null;

  oldQuiz: Quiz;
  uploadType: number = 1; // 1 for quiz
  activeClassList: Class[] = this.dataService.getActiveClassList()
    ? this.dataService.getActiveClassList()
    : [];

  isHovering: boolean;
  step1Submitted: boolean = false;
  step2Submitted: boolean = false;
  step3Submitted: boolean = false;

  constructor(
    public uploadService: UploadService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private dbManager: DatabaseManager,
    public dataService: DataService,
    private datetime: DatetimeService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<QuizuploadComponent>,
    private alertService: AlertService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    ////console.log("data", this.data);
    this.currentQuiz = this.data.quiz;

    if (this.data.type == 2) {
      this.isUploaded = true;
      this.oldQuiz = this.currentQuiz;

      this.startTime = this.oldQuiz.startTime;
      this.endTime = this.oldQuiz.endTime;
      this.duration = this.datetime.getObjectTime(
        this.oldQuiz.durationHours + ":" + this.oldQuiz.durationMinutes
      );
    } else if (this.data.type == 1) {
      //new part for get selected class
      ////console.log("test" + this.data.classId)
      this.currentQuiz.classId = this.data.classId;
      this.currentQuiz.teacherPic =
        this.dataService.getTeacherDetails().profilePic;
      this.currentQuiz.teacherId =
        this.dataService.getTeacherDetails().teacherId;
      this.currentQuiz.teacherName =
        this.dataService.getTeacherDetails().teacherName;
      this.currentQuiz.uploadedDate = this.formatDate(new Date());
      this.currentQuiz.uploadTime = this.getCurrentTime(new Date());
      this.currentQuiz.quizId = this.guidGenerator();
      var cls = this.dataService
        .getClassList()
        .find((x) => x.classId == this.currentQuiz.classId);
      this.currentQuiz.subjectName = `${cls.subjectName}`;
      this.currentQuiz.gradeName = `${cls.gradeName}`;
    }
  }

  selectFile(files: FileList) {
    this.selectedFiles = files;

    var array = files[0].name.split(".");
    var uploadedfileformat = array[array.length - 1];

    if (this.format == uploadedfileformat) {
      if (this.selectedFiles) {
        this.isUploaded = true;
        this.isFileChanged = files[0].name !== this.currentQuiz.fileName;
        this.currentQuiz.fileName = files[0].name;
        this.currentQuiz.file = this.selectedFiles.item(0);
        this.selectedFiles = null;

        if (this.data.type == 2) {
          //1 for new quiz and 2 for edit quiz
        }
      }
    } else {
      this.alertService.showAlert(
        "Please add pdf type file only",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  getMcqRowControls() {
    return (this.mcqForm.get("mcqRow") as FormArray).controls;
  }

  clickNext() {
    this.buildMcqForm();
    const control = <FormArray>this.mcqForm.controls.mcqRow;
    control.clear();

    for (let i = 0; i < this.currentQuiz.noQuestions; i++) {
      this.addNewInputField();
    }

    this.ansArray = new Array(this.currentQuiz.mcqChoise);

    if (this.data.type == 2 && !this.isFileChanged) {
      this.mcqForm.patchValue({
        mcqRow: this.currentQuiz.answers,
      });
    }
  }

  buildMcqForm() {
    this.mcqForm = this.formBuilder.group({
      mcqRow: this.formBuilder.array([this.initMcqQuestionField()]),
    });
  }

  addNewInputField(): void {
    const control = <FormArray>this.mcqForm.controls.mcqRow;
    control.push(this.initMcqQuestionField());
  }

  initMcqQuestionField(): FormGroup {
    return this.formBuilder.group({
      answer: [null, Validators.required],
    });
  }

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  upload() {
    // const file = this.selectedFiles.item(0);
    // this.currentQuiz.file = file;

    var selectanswer = this.mcqForm.value.mcqRow;
    ////console.log(this.mcqForm.value.mcqRow);

    for (let [key, value] of Object.entries(selectanswer)) {
      ////console.log(value["answer"]);
      var allAnswered = true;
      if (value["answer"] == null) {
        allAnswered = false;
      }

      if (!allAnswered) {
        this.alertService.showAlert(
          "Please add Answers",
          MessageButtons.ok,
          MessageType.warning
        );
        return;
      }
    }

    this.currentQuiz.answers = this.mcqForm.value.mcqRow;
    ////console.log(this.mcqForm.value.mcqRow);
    this.selectedFiles = null;

    this.currentQuiz.startTime = this.startTime;
    this.currentQuiz.endTime = this.endTime;
    this.currentQuiz.durationHours = this.datetime.getStringHours(
      this.duration
    );
    this.currentQuiz.durationMinutes = this.datetime.getStringMinutes(
      this.duration
    );

    this.uploadService.pushFileToStorage(
      this.currentQuiz,
      this.progress,
      this.uploadType
    );
    this.close();
  }

  save() {
    this.currentQuiz.answers = this.mcqForm.value.mcqRow;
    ////console.log(this.mcqForm.value.mcqRow);

    this.currentQuiz.startTime = this.startTime;
    this.currentQuiz.endTime = this.endTime;
    this.currentQuiz.durationHours = this.datetime.getStringHours(
      this.duration
    );
    this.currentQuiz.durationMinutes = this.datetime.getStringMinutes(
      this.duration
    );

    if (this.isFileChanged) {
      // ////console.log("deleting old file");
      this.uploadService.deleteQuizFileStorage(this.oldQuiz);
      this.uploadService.pushFileToStorage(
        this.currentQuiz,
        this.progress,
        this.uploadType
      );
      this.close();
    } else {
      this.dbManager.saveQuiz(this.currentQuiz).then((res) => {
        this.close();
      });
    }
  }

  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  validateEndDate() {
    var startDate = new Date(this.currentQuiz.startDate);
    var endDate = new Date(this.currentQuiz.endDate);

    if (endDate < startDate) {
      ////console.log(this.currentQuiz)
      this.alertService.showAlert(
        "Ending Date Should be Greater than Starting Date ",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  selectClass() {
    var cls = this.dataService
      .getClassList()
      .find((x) => x.classId == this.currentQuiz.classId);
    this.currentQuiz.gradeName = `${cls.gradeName}`;
    this.currentQuiz.subjectName = `${cls.subjectName}`;
  }

  close() {
    this.uploadService.isActiveRunning().subscribe((resp) => {
      if (!resp) {
        this.uploadService.uploadTask = null;
        this.dialogRef.close();
      }
    });
  }
  openCancelDialog() {
    this.alertService
      .showAlert(
        "Do you want to Cancel Uploading?",
        MessageButtons.ok,
        MessageType.warning
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            this.progress.percentage = 0;
            this.uploadService.cancelUploadTask();

            break;

          case DialogResult.close:
            break;
        }
      });
  }
  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }

  getCurrentTime(date: Date) {
    return this.datepipe.transform(date, "HH:mm:ss");
  }

  stepValid1() {
    if (
      this.currentQuiz.classId &&
      this.currentQuiz.quizTitle &&
      this.currentQuiz.quizDescription &&
      this.currentQuiz.startDate &&
      this.currentQuiz.endDate &&
      this.startTime &&
      this.endTime &&
      this.duration
    ) {
      return true;
    }
    return false;
  }

  stepValid2() {
    if (
      this.currentQuiz.fileName &&
      this.currentQuiz.mcqChoise &&
      this.currentQuiz.noQuestions &&
      this.currentQuiz.type &&
      this.currentQuiz.mcqChoise > 1
    ) {
      return true;
    }
    return false;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    const mimeType = files[0].type;
    if (mimeType.match(/pdf\/*/) == null) {
      this.alertService.showAlert(
        "Please add pdf type file only",
        MessageButtons.ok,
        MessageType.warning
      );
      return;
    }

    this.selectFile(files);
  }
}
