import { Grade } from "./Grade";

export enum classType {
  PAPER = "Paper",
  REVISION = "Revision",
  GROUP = "Group",
}

export class Class {
  classId: string;
  teacherEmail: string;
  teacherId: string;
  teacherName: string;
  teacherPic: string;
  subjectId: string;
  subjectName: string;
  subjectPic: string;
  gradeId: string = null;
  gradeName: string = null;
  classType: string = null;
  day: string;
  isDeleted: boolean;
  classStatus: number;
  startingTime: string;
  endingTime: string;
  classFee: number;
  isNeedZoom: boolean = false;
  srhTeacherName: string;
  srhSubjectName: string;
  teacherTp1:string
}
