import { map } from "rxjs/operators";

import { MatDialog } from "@angular/material";
import { QuizuploadComponent } from "../quizupload/quizupload.component";
import { Component, OnInit } from "@angular/core";
import { Quiz } from "../model/quiz";
import { UploadService } from "../services/upload.service";

import { DataService } from "../services/data.service";
import { DatabaseManager } from "../database/database-manager";
import { DatetimeService } from "../util/datetime.service";
import { Constants } from "../model/Constants";
import { AlertService } from "../services/alert.service";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { AnimationOptions } from "ngx-lottie";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Class } from "../model/Class";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.scss"],
})
export class QuizComponent implements OnInit {
  pastValue;
  quizIdList: any[];
  quizList: Quiz[] = [];
  teacherId: string;
  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : "";
  gradeId: string;
  termSubscriber: Subscription;
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];

  constructor(
    public uploadService: UploadService,
    private title: Title,
    private dbManager: DatabaseManager,
    public auth: AuthService,
    public dialog: MatDialog,
    public dataService: DataService,
    private datetime: DatetimeService,
    private alertService: AlertService,
    private profileValidator: ProfileValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Quiz | Tutor - Digi Panthiya");

    this.pastValue = -1;

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
            ////console.log(this.classId)
          }

          ////console.log("tab",this.teacherId,this.classId)

          if (this.classId && this.teacherId) {
            this.getQuiz();
          }
        });
      }
    });
  }
  ngOnDestroy() {
    // ////console.log("destroying")
    this.termSubscriber.unsubscribe();
  }

  onSelectChange() {
    if (this.classId) {
      this.route.params["classId"] = this.classId;
      this.router.navigate(["/quizes", this.classId]);
    }
  }

  getQuiz() {
    if (this.classId) {
      this.route.params["classId"] = this.classId;
      this.router.navigate(["/quizes", this.classId]);
    }

    this.dbManager
      .getTeacherQuiz(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes
            .map((c) => c.payload.doc.data())
            .filter((x) => this.dataService.isQuizNotPassed(x))
        )
      )
      .subscribe((data) => {
        ////console.log(" list ", data);
        this.quizList = data;
        ////console.log("quiz list ", this.quizList);
      });
  }
  edit(quiz: Quiz) {
    if (this.isQuizInProgress(quiz)) {
      this.alertService
        .showAlert(
          "Quiz in progress,are you sure you want to edit it",
          MessageButtons.YesNo,
          MessageType.warning
        )
        .afterClosed()
        .subscribe((result: DialogResult) => {
          switch (result) {
            case DialogResult.yes:
              this.openEditDialog(quiz);
              break;

            case DialogResult.close:
              break;
          }
        });
    } else {
      this.openEditDialog(quiz);
    }
  }

  deleteQuiz(id, quiz: Quiz) {
    ////console.log(quiz)
    if (this.isQuizInProgress(quiz)) {
      this.alertService
        .showAlert(
          "Quiz in progress,are you sure you want to delete it",
          MessageButtons.YesNo,
          MessageType.Delete
        )
        .afterClosed()
        .subscribe((result: DialogResult) => {
          switch (result) {
            case DialogResult.yes:
              this.deleteRow(id, quiz);
              break;

            case DialogResult.close:
              break;
          }
        });
    } else {
      this.deleteRow(id, quiz);
    }
  }

  deleteRow(id, quiz: Quiz) {
    var table = document.getElementById("quizTable") as HTMLTableElement;
    var el = document.getElementById(id) as HTMLTableRowElement;
    this.alertService
      .showAlert(
        "Do you want to Delete Quiz?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            table.deleteRow(el.rowIndex);

            this.uploadService.deleteQuizFileUpload(quiz); //delete quiz;
            break;

          case DialogResult.close:
            break;
        }
      });
  }

  openUploadDialog(classId) {
    //1 for new quiz and 2 for edit quiz
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(QuizuploadComponent, {
        data: {
          classId: classId,
          type: Constants.FIRST_TYPE,
          quiz: new Quiz(),
        },
      });
    } else {
      this.alertService.showAlert(
        "profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  openEditDialog(quiz: Quiz) {
    //1 for new quiz and 2 for edit quiz
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(QuizuploadComponent, {
        data: {
          type: Constants.SECOND_TYPE,
          quiz: quiz,
        },
      });
    } else {
      this.alertService.showAlert(
        "profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  getMenu(id) {
    this.pastValue = id;
  }

  quitMenu() {
    this.pastValue = -1;
  }
  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }

  openQuiz(link) {
    window.open(link, "_blank");
  }
  isQuizInProgress(quiz: Quiz) {
    const start = moment(quiz.startDate + " " + quiz.startTime);
    const end = moment(quiz.endDate + " " + quiz.endTime);
    const now = moment();
    ////console.log('start',start.format())
    ////console.log('end',end.format())
    ////console.log('now',now.format())
    ////console.log(now.isBetween(start, end))
    return now.isBetween(start, end);
  }
}
