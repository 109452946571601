import { Component, OnInit } from "@angular/core";
import { DatabaseManager } from "../database/database-manager";
import { map } from "rxjs/operators";
import { Subject } from "../model/subject";
import { DataService } from "../services/data.service";
import { Class } from "../model/Class";
import { Grade } from "../model/Grade";
import { DatetimeService } from "../util/datetime.service";
import { MatDialogRef } from "@angular/material";
import { Teacher } from "../model/teacher";
import { start } from "repl";

@Component({
  selector: "app-create-class",
  templateUrl: "./create-class.component.html",
  styleUrls: ["./create-class.component.scss"],
})
export class CreateClassComponent implements OnInit {
  subjectList: Subject[] = [];
  subject: Subject;
  currentClass: Class = new Class();
  startTime: string;
  endTime: string;
  formSubmitted: boolean = false;

  constructor(
    private dbManager: DatabaseManager,
    public dataService: DataService,
    private datetime: DatetimeService,
    private dialogRef: MatDialogRef<CreateClassComponent>
  ) {}

  ngOnInit() {
    this.subjectList = this.dataService.getSubscribedSubjectList();
  }

  selectSubject(value) {
    this.currentClass.subjectId = this.subject.subjectId;
    this.currentClass.subjectName = this.subject.subjectName;
    this.currentClass.gradeId = this.subject.gradeId;
    this.currentClass.gradeName = this.subject.gradeName;
    this.currentClass.srhSubjectName = this.subject.subjectName.toLowerCase();
    this.currentClass.subjectPic = this.subject.thumbnailUrl;
  }

  create() {
    var teacher: Teacher = this.dataService.getTeacherDetails();
    ////console.log(teacher);

    this.currentClass.isDeleted = false;
    this.currentClass.teacherId = teacher.teacherId;
    this.currentClass.teacherName = teacher.teacherName;
    this.currentClass.teacherEmail = teacher.teacherEmail;
    this.currentClass.srhTeacherName = teacher.teacherName.toLowerCase();
    this.currentClass.teacherTp1 = teacher.teacherTp1;
    this.currentClass.teacherPic = teacher.profilePic;
    this.formSubmitted = true;
    if (this.isFormValid()) {
      this.currentClass.classStatus = 0;
      this.currentClass.classId = "";
      this.currentClass.startingTime = this.startTime;
      this.currentClass.endingTime = this.endTime;

      // //console.log(this.currentClass);

      this.dbManager.addTeacherClass(this.currentClass).then((result) => {
        this.close();
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  isFormValid() {
    if (
      this.currentClass.subjectId &&
      this.currentClass.subjectName &&
      this.currentClass.gradeId &&
      this.currentClass.classType &&
      this.currentClass.classFee &&
      this.currentClass.day &&
      this.currentClass.teacherId &&
      this.startTime &&
      this.endTime &&
      this.isFeeValid()
    ) {
      return true;
    }
    return false;
  }
  onClassFeeChange(event: any) {
    // //console.log(event);
  }
  isFeeValid() {
    if (this.currentClass.classFee % 4 === 0) {
      return true;
    }
    return false;
  }

  stepValid1() {
    if (this.currentClass.subjectId && this.currentClass.classType) {
      return true;
    }
    return false;
  }

  stepValid2() {
    if (this.currentClass.day && this.startTime && this.endTime) {
      return true;
    }
    return false;
  }
}
