import { Component, OnInit } from "@angular/core";
import { map, take } from "rxjs/operators";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Grade } from "../model/Grade";
import { Subject } from "../model/subject";
import { AlertService } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";

@Component({
  selector: "app-subscribe-subject",
  templateUrl: "./subscribe-subject.component.html",
  styleUrls: ["./subscribe-subject.component.scss"],
})
export class SubscribeSubjectComponent implements OnInit {
  gradeList: Grade[] = [];
  subjectList: Subject[];
  subject: Subject;
  teacherId = this.auth.userData.uid;

  constructor(
    private dbManager: DatabaseManager,
    private dataService: DataService,
    private auth: AuthService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.dbManager
      .getGrades()
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        this.gradeList = data;
      });
  }

  loadSubjects(gradeId) {
    this.dbManager
      .getSubjectsWithGrades(gradeId)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        this.subjectList = data;
        ////console.log(this.subjectList);
      });
  }

  save() {
    //edit the select subject part
    let list = this.dataService.getSubjectIdList();
    ////console.log(this.subject)
    ////console.log('list', list)
    // debugger;
    if (this.subject) {
      this.subject.subjectId = this.subject.subjectId.trim();
      if (list.includes(this.subject.subjectId)) {
        this.alertService.showAlert(
          "Subject already subscribed",
          MessageButtons.ok,
          MessageType.warning
        );
      } else {
        list.push(this.subject.subjectId);
        this.dbManager
          .addTeacherSubject(
            this.dataService.getTeacherDetails().teacherId,
            list
          )
          .then((res) => {
            ////console.log('enter');
            this.addSubjectEnrollment(this.subject.subjectId);
          });
      }
    } else {
      this.alertService.showAlert(
        "Please select subject.",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  addSubjectEnrollment(subjectId) {
    this.dbManager.getSubjectEnrollments(subjectId).then((result) => {
      let data: string[] = [];
      if (result.data()) {
        data.concat(result.data().idSet);
        ////console.log(result.data());
      }
      let teacherIdList = data;
      ////console.log("subject teacherId list", data);
      if (!data.includes(this.teacherId)) {
        teacherIdList.push(this.teacherId);
        this.dbManager.addSubjectEnrollment(subjectId, teacherIdList);
      }
    });
  }
}
