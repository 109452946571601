import { Component, OnInit } from "@angular/core";
import { UploadService } from "../services/upload.service";

@Component({
  selector: "app-canceldialog",
  templateUrl: "./canceldialog.component.html",
  styleUrls: ["./canceldialog.component.scss"],
})
export class CanceldialogComponent implements OnInit {
  constructor(
    private uploadService: UploadService,
  ) {}

  ngOnInit() {}
  cancel() {
    this.uploadService.cancelUploadTask();
    window.close();
  }
  resume(){
    this.uploadService.resumeUploadTask();

  }

}
