import { Component, OnInit } from "@angular/core";
import { Subject } from "../model/subject";
import { map } from "rxjs/operators";

import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { UploadService } from "../services/upload.service";
import { DatabaseManager } from "../database/database-manager";
import { AuthService } from "../services/auth.service";
import { Class } from "../model/Class";
import { CreateClassComponent } from "../create-class/create-class.component";
import { DatetimeService } from "../util/datetime.service";
import { DataService } from "../services/data.service";
import { AlertService } from "../services/alert.service";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { ImageUploadComponent } from "../image-upload/image-upload.component";
import { Constants } from "../model/Constants";
import { SubscribeSubjectComponent } from "../subscribe-subject/subscribe-subject.component";
import { video } from "../model/video";
import { AnimationOptions } from "ngx-lottie";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { Router } from "@angular/router";
import { element } from "protractor";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  readonly profilePicType = 1;
  readonly coverPicType = 2;
  submitted: boolean = false;

  editor = 1;

  editValues1 = true;
  editValues2 = true;
  editValues3 = true;
  editValues4 = true;
  editValues5 = true;
  editValues6: boolean = true;
  editValues7: boolean = true;
  editValues8: boolean = true;
  editValues9: boolean = true;
  isPatchLoaded: boolean;
  teacherData: any;
  message: string;
  teacher_form: FormGroup;
  bankDetailsData: any;
  bankForm: FormGroup;
  overDip: boolean = true;
  // teacherCoverPic: string;

  nosubscriptionLot: AnimationOptions = {
    path: "assets/lti/lti_not-available-feature.json",
    loop: true,
  };

  currentClasses: Class[] = [];
  subscribedSubjects: Subject[] = [];
  featureContents: video[] = [];
  public readonly NO_THUMBNAIL = "./../assets/templates/video_thumb.jpg";

  //store provinces
  provinces: string[] = [
    "--Select--",
    "Northern",
    "North Western",
    "Western",
    "North Central",
    "Central",
    "Sabaragamuwa",
    "Eastern",
    "Uva",
    "Southern",
  ];
  option1: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
  option2: AnimationOptions = {
    path: "/assets/lti/lti_videos.json",
    loop: false,
  };

  constructor(
    private formBuilder: FormBuilder,
    private title: Title,
    public dialog: MatDialog,
    public uploadService: UploadService,
    private dbManager: DatabaseManager,
    public auth: AuthService,
    private datetime: DatetimeService,
    public dataService: DataService,
    private alertService: AlertService,
    public profileValidator: ProfileValidatorService,
    private router: Router
  ) {
    //wait until user connecting with the server
    this.buildForm();
    this.buildBankForm();
    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacher_form.patchValue({
          teacherId: this.auth.userData.uid,
        });
        this.bankForm.patchValue({
          teacherId: auth.userData.uid,
        });
        this.loadUserDetailsData();
        this.loadBankDetails();
        // this.teacherCoverPic = this.teacher_form.value.coverPic ? this.teacher_form.value.coverPic : "./assets/images/coverpic.png";
      }
    });
  }

  ngOnInit(): void {
    // set title & meta
    this.title.setTitle("My Account | Tutor - Digi Panthiya");
  }

  buildForm() {
    this.teacher_form = this.formBuilder.group({
      teacherId: new FormControl("", Validators.compose([Validators.required])),
      teacherEmail: new FormControl(
        "",
        Validators.compose([
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ])
      ),

      teacherName: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(30),
        ])
      ),
      srhTeacherName: new FormControl(""),
      nic: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(10)])
      ),

      teacherTp1: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[0-9]+$"),
          Validators.maxLength(11),
          Validators.minLength(10),
        ])
      ),
      teacherInfo: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(10)])
      ),
      teacherAddress: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(5)])
      ),
      coverPic: new FormControl(
        "./assets/images/coverpic.png",
        Validators.compose([Validators.required])
      ),
      profilePic: new FormControl(
        "./../assets/images/noprofile.png",
        Validators.compose([Validators.required])
      ),
      city: new FormControl("", Validators.compose([Validators.required])),
      postalcode: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(5),
        ])
      ),
      province: new FormControl(
        "--Select--",
        Validators.compose([Validators.required])
      ),
      country: new FormControl("", Validators.compose([Validators.required])),
      reviewId: new FormControl(""),
      revisionClz: new FormControl(false),
      groupClz: new FormControl(false),
      paperClz: new FormControl(false),
      seminarClz: new FormControl(false),
    });
  }

  buildBankForm() {
    this.bankForm = this.formBuilder.group({
      teacherId: new FormControl("", Validators.compose([Validators.required])),
      bankName: new FormControl("", Validators.compose([Validators.required])),
      branchName: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      branchCode: new FormControl(
        "",
        Validators.compose([
          Validators.minLength(3),
          Validators.pattern("^[0-9]+$"),
        ])
      ),
      accountNumber: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.pattern("^[0-9]+$"),
        ])
      ),
    });
  }

  loadUserDetailsData() {
    this.dbManager
      ._getTeacherDetails(this.auth.userData.uid)
      .snapshotChanges()
      .pipe(
        map((a) => {
          const data = a.payload.data();
          return data;
        })
      )
      .subscribe((result) => {
        let data = result;
        if (data) {
          this.teacherData = data;
          let {
            teacherId,
            teacherEmail,
            teacherName,
            teacherTp1,
            teacherInfo,
            teacherAddress,
            nic,
            profilePic,
            coverPic,
            city,
            province,
            postalcode,
            country,
          } = this.teacherData;

          this.teacher_form.patchValue({
            teacherId,
            teacherEmail,
            teacherName,
            teacherTp1,
            teacherInfo,
            teacherAddress,
            nic,
            profilePic,
            coverPic,
            city,
            province,
            postalcode,
            country,
          });
        }
      });

    this.getSubscribedSubjects();
    this.currentClasses = this.dataService.getClassList();

    this.dbManager
      .getTeacherFeatureContent(this.auth.userData.uid)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        this.featureContents = data;
        ////console.log("featured video list ", this.featureContents);
      });

    /*  let data = ["001", "002"];
    this.dbManager.addTeacherSubject("aqQ4KIUSAubuKLQN2NA3DWX5HQ32", data); */
  }

  loadBankDetails() {
    this.dbManager.getBankDetails(this.auth.userData.uid).then((element) => {
      this.bankDetailsData = element;
      let { teacherId, bankName, branchName, branchCode, accountNumber } =
        this.bankDetailsData;

      this.bankForm.patchValue({
        teacherId,
        bankName,
        branchName,
        branchCode,
        accountNumber,
      });
    });
  }

  getSubscribedSubjects() {
    this.subscribedSubjects = this.dataService.getSubscribedSubjectList();
  }

  updateProfile(): void {
    const {
      teacherEmail,
      teacherName,
      teacherTp1,
      teacherInfo,
      teacherAddress,
      profilePic,
      coverPic,
      city,
      nic,
      province,
      postalcode,
      country,
      srhTeacherName,
    } = this.teacher_form.value;
    let userDetails = {
      teacherEmail,
      teacherName,
      teacherTp1,
      teacherInfo,
      teacherAddress,
      profilePic,
      coverPic,
      city,
      nic,
      province,
      postalcode,
      country,
      srhTeacherName,
    };
    userDetails.srhTeacherName = this.teacher_form.value.teacherName
      ? this.teacher_form.value.teacherName.toLowerCase()
      : "";

    if (!userDetails.coverPic) {
      this.teacher_form.patchValue({
        coverPic: "./assets/images/coverpic.png",
      });
    }
    if (!userDetails.profilePic) {
      this.teacher_form.patchValue({
        profilePic: "./../assets/images/noprofile.png",
      });
    }
    this.submitted = true;

    if (
      userDetails.teacherName &&
      userDetails.teacherTp1 &&
      userDetails.teacherInfo &&
      userDetails.teacherAddress &&
      userDetails.city &&
      userDetails.nic &&
      userDetails.province != "--Select--" &&
      userDetails.postalcode
    ) {
      this.dbManager
        .updateTeacherProfile(userDetails, this.teacher_form.value.teacherId)
        .then((value) => {
          // //console.log(value);
          this.alertService.showAlert(
            "Profile updated successfully!",
            MessageButtons.ok,
            MessageType.Success
          );
        });
    }
  }

  updateBankDetails() {
    const { teacherId, bankName, branchName, branchCode, accountNumber } =
      this.bankForm.value;
    let bankDetails = {
      teacherId,
      bankName,
      branchName,
      branchCode,
      accountNumber,
    };
    if (this.bankForm.valid) {
      this.dbManager
        .updateBankDetails(this.bankForm.value.teacherId, bankDetails)
        .then((value) => {
          this.alertService.showAlert(
            "Bank details updated successfully!",
            MessageButtons.ok,
            MessageType.Success
          );
          this.profileValidator.updateBankDetails();
        });
    }
  }

  imagePicHandler(type) {
    this.dialog.open(ImageUploadComponent, {
      data: {
        imgType: type,
        teacherId: this.teacher_form.value.teacherId,
      },
    });
    // if (type == Constants.FIRST_TYPE) {
    //   this.dbManager.updateTeacherProfile(
    //     { profilePic: "/../assets/images/noprofile.png" },
    //     this.teacher_form.value.teacherId
    //   );
    // } else if (type == Constants.SECOND_TYPE) {
    //   this.dbManager.updateTeacherProfile(
    //     { coverPic: "./assets/images/coverpic.png" },
    //     this.teacher_form.value.teacherId
    //   );
    // }
  }

  openCreateClassDialog() {
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(CreateClassComponent);
    } else {
      this.alertService.showAlert(
        "Profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  openSubjectDialog() {
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(SubscribeSubjectComponent);
    } else {
      this.alertService.showAlert(
        "Profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  removeSubject(subjectId: string) {
    this.alertService
      .showAlert(
        "Do you want to remove this subject?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            subjectId = subjectId.trim();
            let subjectIdList = this.dataService.getSubjectIdList();
            const index = subjectIdList.indexOf(subjectId);
            if (index > -1) {
              subjectIdList.splice(index, 1);
              this.dbManager
                .addTeacherSubject(
                  this.dataService.getTeacherDetails().teacherId,
                  subjectIdList
                )
                .then((res) => {
                  this.removeSubjectEnrollment(subjectId);
                });
            }
            break;
          case DialogResult.close:
            break;
        }
      });
  }

  removeSubjectEnrollment(subjectId) {
    this.dbManager.getSubjectEnrollments(subjectId).then((data: any) => {
      if (data.data()) {
        let teacherIdList = data.data().idSet;
        if (teacherIdList.includes(this.auth.userData.uid)) {
          const index = teacherIdList.indexOf(this.auth.userData.uid);
          if (index > -1) {
            teacherIdList.splice(index, 1);
            this.dbManager.addSubjectEnrollment(subjectId, teacherIdList);
          }
        }
      }
    });
  }

  openVideoDialog(url) {
    if (this.profileValidator.isProfileValid()) {
      if (this.featureContents.length < 4) {
        window.open(url, "_blank");
      } else {
        this.alertService.showAlert(
          "Maximum four(4) videos can be uploaded as feature videos",
          MessageButtons.ok,
          MessageType.warning
        );
      }
    } else {
      this.alertService.showAlert(
        "Profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  removeFeatureVideo(video) {
    this.alertService
      .showAlert(
        "Do you want to delete this video?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            this.uploadService.deleteFeatureVideoFileUpload(video); //delete video
            break;
          case DialogResult.close:
            break;
        }
      });
  }

  clearForm() {
    this.teacher_form.reset();
    this.loadBankDetails();
  }

  clearBankForm() {
    this.bankForm.reset();
    let { teacherId, bankName, branchName, branchCode, accountNumber } =
      this.bankDetailsData;

    this.bankForm.patchValue({
      teacherId,
      bankName,
      branchName,
      branchCode,
      accountNumber,
    });
  }

  viewFeatureVideo(video) {
    ////console.log(video)
    this.router.navigate([
      "/video-viewer",
      2,
      video.teacherId,
      "0",
      "0",
      video.videoId,
    ]);
  }
}
