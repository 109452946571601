import { Component, OnInit, ViewChild } from "@angular/core";
import { DigiConfernce } from "../model/digi-conference";
import { DatePipe } from "@angular/common";
import { UploadService } from "../services/upload.service";
import { AuthService } from "../services/auth.service";

import { DatabaseManager } from "../database/database-manager";
import { DataService } from "../services/data.service";
import { MatDialogRef } from "@angular/material";

import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { AlertService } from "../services/alert.service";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";

import { Teacher } from "../model/teacher";

@Component({
  selector: "app-create-digi-conference",
  templateUrl: "./create-digi-conference.component.html",
  styleUrls: ["./create-digi-conference.component.scss"],
})
export class CreateDigiConferenceComponent implements OnInit {
  @ViewChild("bannerupload", { static: true }) input;

  digiConference: DigiConfernce;
  progress: { percentage: number } = { percentage: 0 };
  teacherId: string = this.auth.userData.uid;
  fileUpload: any;
  uploaded: any;
  src: any;
  update = false;
  upload = false;

  @ViewChild("stepper", {
    static: true,
  })
  stepper;

  constructor(
    private datepipe: DatePipe,
    private uploadService: UploadService,
    private auth: AuthService,
    private db: DatabaseManager,
    private ds: DataService,
    private matref: MatDialogRef<CreateDigiConferenceComponent>,
    @Inject(MAT_DIALOG_DATA) public editData,
    private alert: AlertService,
    private dataservice: DataService
  ) { }

  ngOnInit() {
    if (this.editData) {
      this.digiConference = this.editData;
      this.update = true;
    } else {
      const teacher: Teacher = this.dataservice.getTeacherDetails();

      this.digiConference = new DigiConfernce();

      this.digiConference.teacherId = this.auth.userData.uid;
      this.digiConference.teacherName = teacher.teacherName;
      this.digiConference.teacherEmail = teacher.teacherEmail;
      this.digiConference.teacherPic = this.ds.getTeacherDetails().profilePic;
      this.digiConference.uploadedTime = this.datepipe.transform(
        new Date(),
        "HH:mm:ss"
      );
      this.digiConference.uploadedDate = this.datepipe.transform(
        new Date(),
        "MMM d, y"
      );

      this.digiConference.role = 0;
      this.digiConference.conferenceId = this.db.generateKey();
      this.digiConference.conferenceBannerUrl = teacher.coverPic;
    }
  }

  ngOnDestroy() {
    this.matref.close(this.digiConference);
  }

  createConference() {
    if (this.update) {
      this.db.updateConference(
        this.teacherId,
        this.digiConference.conferenceId,
        this.digiConference
      );
    } else {
      this.db.createConference(this.digiConference);
    }

    if (this.fileUpload) {
      this.uploadService.pushFileToStorage(this.fileUpload, this.progress, 8);
    }

    if (this.progress.percentage >= 100) {
      //console.log(this.fileUpload);
    }
  }

  stepValid1() {
    if (this.digiConference.conferenceTitle) {
      return true;
    } else {
      return false;
    }
  }

  deleteConference(conferenceId: string) {
    this.db.isSomeonePayed(conferenceId).then((payed: boolean) => {
      //console.log(payed);
      if (!payed) {
        this.alert
          .showAlert(
            "Are you sure you want to delete this conference",
            MessageButtons.YesNo,
            MessageType.Delete
          )
          .afterClosed()
          .subscribe((res) => {
            if (res === "yes") {
              this.db.deleteConference(
                this.digiConference.teacherId,
                this.digiConference.conferenceId
              );
              this.uploadService.deleteConference(
                this.digiConference.teacherId,
                this.digiConference.conferenceId
              );
              this.matref.close();
            } else {
              return;
            }
          });
      } else {
        this.alert.showAlert(
          "Some student have payed for the conference you cannot delete",
          MessageButtons.ok,
          MessageType.warning
        );
      }
    });
  }
  stepValid2() {
    if (
      this.digiConference.startingTime &&
      this.digiConference.endingTime &&
      this.digiConference.day
    ) {
      return true;
    } else {
      return false;
    }
  }

  stepValid3() {
    if (this.digiConference.password && this.digiConference.meetingNumber) {
      return true;
    } else {
      return false;
    }
  }

  uploadBannerImage(event: any) {
    this.upload = true;

    this.fileUpload = {
      file: event.target.files[0],
      teacherId: this.teacherId,
      conferenceId: this.digiConference.conferenceId,
    };

    let filereader = new FileReader();

    filereader.readAsDataURL(event.target.files[0]);

    filereader.onload = () => {
      this.src = filereader.result;
    };

    //
  }
}
