import { Injectable } from "@angular/core";
import { Class } from "../model/Class";
import { note } from "../model/notes";
import { Quiz } from "../model/quiz";
import { Subject } from "../model/subject";
import { Teacher } from "../model/teacher";
import { teacherQuiz } from "../model/teacherQuiz";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  quizAction: number;
  private quizDetail: Quiz;
  private quizIdList: teacherQuiz;
  private classId: string;
  private subjectId: string;
  private noteAction: number;
  private noteDetail: note;
  private gradeId: string;
  private evaluatedQuizList: any = [];
  private unEvaluatedQuizList: any = [];
  private classList: Class[] = [];
  private subjectIdList: any = [];
  private teacherData: Teacher;
  private subjectList: Subject[] = [];

  constructor() {}

  setTeacherDetails(data: Teacher) {
    this.teacherData = data;
  }

  setQuizAction(option) {
    this.quizAction = option;
  }
  setNoteAction(option: number) {
    this.noteAction = option;
  }
  setClassId(id: string) {
    this.classId = id;
  }
  setSubjectId(id: string) {
    this.subjectId = id;
  }
  setGradeId(id: string) {
    this.gradeId = id;
  }
  setEvaluatedQuizList(list) {
    this.evaluatedQuizList = list;
  }
  setClassList(list) {
    this.classList = list;
    ////console.log("setting ",list)

    const active = this.classList.filter((x) => x.classStatus == 1);
    const available = localStorage.getItem("activeClassList");
    if (active) {
      ////console.log('setting')
      localStorage.setItem("activeClassList", JSON.stringify(active));
    }
  }
  setSubjectIdList(list) {
    if (list) this.subjectIdList = list;
  }
  setSubscribedSubjectList(list) {
    this.subjectList = list;
  }

  //getters

  getTeacherDetails() {
    return this.teacherData;
  }
  getSubscribedSubjectList() {
    return this.subjectList;
  }
  getSubjectIdList() {
    return this.subjectIdList;
  }
  getClassList() {
    return this.classList;
  }
  getActiveClassList() {
    const available = localStorage.getItem("activeClassList");
    if (available.length > 0) {
      return JSON.parse(localStorage.getItem("activeClassList"));
    } else {
      return [];
    }
  }

  getEvaluatedQuizList() {
    return this.evaluatedQuizList;
  }
  setUnEvaluatedQuizList(list) {
    this.unEvaluatedQuizList = list;
  }

  getUnEvaluatedQuizList() {
    return this.unEvaluatedQuizList;
  }

  getClassId() {
    return this.classId;
  }
  getSubjectId() {
    return this.subjectId;
  }
  getGradeId() {
    return this.gradeId;
  }

  getQuizAction() {
    return this.quizAction;
  }
  getNoteAction() {
    return this.noteAction;
  }
  setEditQuiz(quiz: Quiz) {
    this.quizDetail = quiz;
    ////console.log(quiz.file);
    ////console.log(quiz);
  }
  setEditNote(note: note) {
    this.noteDetail = note;
    ////console.log(note);
  }
  getQuizDetail() {
    return this.quizDetail;
  }
  getNoteDetail() {
    return this.noteDetail;
  }
  setQuizIdList(list: teacherQuiz) {
    this.quizIdList = list;
  }
  getQuizIdList() {
    return this.quizIdList;
  }
  isQuizNotPassed(quiz: Quiz) {
    // //console.log(quiz)
    const today = moment(new Date());
    let endDay = new Date(quiz.endDate);
    let endTime = quiz.endTime.split(":");
    const day = endDay.getDate();
    const month = endDay.getMonth();
    const year = endDay.getFullYear();
    const hour = parseInt(endTime[0]);
    const min = parseInt(endTime[1]);

    const endDate = moment({
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: min,
      second: 0,
    });

    // //console.log("endtime",endDate.format("HH:mm:ss"))
    // //console.log("endDay",endDate.format("YYYY-MM-DD"))
    // //console.log("today date",today.format("YYYY-MM-DD"))
    // //console.log("today time",today.format("HH:mm:ss"))

    // //console.log("quiz ended",today.isAfter(endDate))
    return today.isBefore(endDate);
  }
}
