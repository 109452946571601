import { Component, OnInit } from "@angular/core";
import { DatabaseManager } from "../database/database-manager";
import { DigiConfernce } from "../model/digi-conference";
import { AuthService } from "../services/auth.service";
import { ConferencePayment } from "../model/conference-payment";
import { AnimationOptions } from "ngx-lottie";
interface Profit {
  isFree: boolean;
  profit: number;
}

@Component({
  selector: "app-payment-conference",
  templateUrl: "./payment-conference.component.html",
  styleUrls: ["./payment-conference.component.scss"],
})
export class PaymentConferenceComponent implements OnInit {
  payedStudents: ConferencePayment[];
  Students: ConferencePayment;
  conferences: DigiConfernce;
  confprofit: Profit = { isFree: false, profit: 0 };

  price: number;
  nettotal: number = 0;
  count: number = 0;
  total: number = 0;

  selected = true;
  isLoding = false;

  lottie: AnimationOptions = {
    path: "assets/lti/lti_not-available-feature.json",
    loop: false,
  };
  nolottie: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
  constructor(private db: DatabaseManager, private auth: AuthService) {}

  ngOnInit() {
    this.auth.isUserAvailable().then(() => {
      this.db
        .getAllConference(this.auth.userData.uid)
        .then((res: DigiConfernce) => {
          this.conferences = res;
        });

      this.db.getConferenceProfit().then((res: Profit) => {
        this.confprofit = res;
      });
    });
  }

  onSelectChange(event: any) {
    console.log(event.target.value);
    this.selected = event.target.value === "";
    this.isLoding = true;
    this.count = 0;
    this.total = 0;
    this.nettotal = 0;
    this.price = 0;

    let value = event.target.value;

    this.db.getEnrolledStudents(value).then((res: ConferencePayment[]) => {
      this.payedStudents = res;
      this.count = 0;
      this.total = 0;
      this.nettotal = 0;
      this.price = 0;
      this.isLoding = false;

      res.forEach((res) => {
        this.price = res.price;
        this.total += res.price;
        this.nettotal = this.confprofit.isFree
          ? this.total
          : (this.total * (100 - this.confprofit.profit)) / 100;
        this.count += 1;
      });
    });
  }

  calTotal(ammount: number) {
    this.total += ammount;
  }
}
