import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import {
  MessageboxComponent,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(
    public dialog: MatDialog //mat dialog to display extra messages
  ) {}

  showAlert(
    message: string,
    messageButtons: MessageButtons,
    messageType: MessageType
  ): any {
    const dialogRef = this.dialog.open(MessageboxComponent, {
      data: {
        message: message,
        messageButtons: messageButtons,
        messageType: messageType,
      },
    });
    return dialogRef;
  }
}
