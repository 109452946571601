import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import * as firebase from "firebase/app";
import "firebase/storage";
import { Quiz } from "../model/quiz";
import { note } from "../model/notes";
import { video } from "../model/video";
import { Marks } from "../model/Marks";
import { DatabaseManager } from "../database/database-manager";
import { BehaviorSubject, Observable } from "rxjs";

import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { AlertService } from "./alert.service";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { teacherNote } from "../model/teacherNote";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private readonly UPLOAD_PRESIGNED_URL =
    "https://us-central1-digipanthiya-bc66f.cloudfunctions.net/generatePresignedUrl";
  private readonly DELTA_PRESIGNED_URL =
    "https://us-central1-digipanthiya-bc66f.cloudfunctions.net/deleteVideoIob";
  // private readonly TEST_PRESIGNED_URL = "http://localhost:1338/generatePresignedUrl";
  // private readonly PRESIGNED_URL =TEST_PRESIGNED_URL;
  private static readonly DBPATH_TEACHER = "Teacher";
  private static readonly QUIZ_PATH = "/Quizes";
  private static readonly NOTE_PATH = "/Notes";
  private static readonly CONTENT_PATH = "/Content";
  private static readonly AWS_CONTENT_PATH = "Content";
  private static readonly AWS_TEACHER_FEATURE_PATH = "Feature";
  private static readonly AWS_ADVERTISING_CONTENT_PATH = "Advertisement";
  private static readonly EVALUATED_QUIZ_PATH = "/EvaluatedQuiz";
  private static readonly TEACHER_FEATURE_VIDEO_PATH = "/Feature";
  private static readonly COVER_PIC = "cover_pic";
  private static readonly PROFILE_PIC = "profile_pic";
  private static readonly BANK_SLIP = "bnkslips";
  private static readonly CONFERENCE_PIC = "conference_pic";
  private readonly teacherProfileImgPath = "/profile_pic";
  private readonly teacherCoverImgPath = "/cover_pic";
  public uploadTask: firebase.storage.UploadTask = null;
  public uploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private alertService: AlertService,
    private dbManager: DatabaseManager,
    private http: HttpClient
  ) {}

  public pushFileToStorage(
    fileUpload: any,
    progress: { percentage: number },
    type: number
  ) {
    //console.log("Im not the balca fvasvi ");
    this.uploading.next(true);
    const storageRef = firebase.storage().ref();
    if (type == 1) {
      //type 1 for quizez
      var storagePath = `${UploadService.QUIZ_PATH}/${fileUpload.teacherId}/${fileUpload.classId}/${fileUpload.quizId}`;
    } else if (type == 2) {
      //type 2 for notes
      var storagePath = `${UploadService.NOTE_PATH}/${fileUpload.teacherId}/${fileUpload.classId}/${fileUpload.noteId}`;
    } else if (type == 4) {
      //type 3 for quiz marking scheme
      var storagePath = `${UploadService.CONTENT_PATH}/${fileUpload.teacherId}/${fileUpload.classId}/${fileUpload.videoId}`;
    } else if (type == 5) {
      //type 5 for profile img
      var storagePath = `${UploadService.DBPATH_TEACHER}/${
        fileUpload.teacherId
      }/${UploadService.PROFILE_PIC}/${"profilePic"}`;
    } else if (type == 6) {
      //type 6 for cover img
      var storagePath = `${UploadService.DBPATH_TEACHER}/${
        fileUpload.teacherId
      }/${UploadService.COVER_PIC}/${"coverPic"}`;
    } else if (type == 8) {
      var storagePath = `${UploadService.DBPATH_TEACHER}/${
        fileUpload.teacherId
      }/${UploadService.CONFERENCE_PIC}/${
        fileUpload.conferenceId
      }/${"conference"}`;
    } else if (type == 9) {
      var storagePath = `${UploadService.AWS_ADVERTISING_CONTENT_PATH}/${
        fileUpload.teacherId
      }/${fileUpload.classAdId}/${"adcontent"}`;
    } else if (type == 10) {
      var storagePath = `${UploadService.BANK_SLIP}/${fileUpload.teacherId}/${
        UploadService.AWS_ADVERTISING_CONTENT_PATH
      }/${fileUpload.classAdId}/${"bnkslip"}`;
    }

    this.uploadTask = storageRef.child(storagePath).put(fileUpload.file);
    //console.log("Heallo How are you im under the water . . .");
    this.uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        progress.percentage = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
      },
      (error) => {
        // fail
        this.uploading.next(false);
        alert(error);
      },
      () => {
        // success
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          ////console.log("File available at", downloadURL);
          this.uploading.next(false);

          if (type == 1) {
            //type 1 for upload  quizez
            fileUpload.fileUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            this.dbManager.saveQuiz(fileUpload);
          } else if (type == 2) {
            //type 2 for upload  notes
            ////console.log("for notes");
            fileUpload.noteUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            this.dbManager.saveNote(fileUpload);
          } else if (type == 3) {
            //3 for upload video
            fileUpload.videoUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            this.dbManager.saveVideo(fileUpload);
          } else if (type == 4) {
            //4 for upload quiz marking scheme(done by teacher)
            fileUpload.answerSheetUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            // this.dbManager.saveQuizMarking(fileUpload);
          } else if (type == 5) {
            //4 for upload quiz marking scheme(done by teacher)
            fileUpload.profileImgUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            this.dbManager.updateTeacherProfile(
              { profilePic: downloadURL },
              fileUpload.teacherId
            );
          } else if (type == 6) {
            //4 for upload quiz marking scheme(done by teacher)
            fileUpload.coverImgUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            this.dbManager.updateTeacherProfile(
              { coverPic: downloadURL },
              fileUpload.teacherId
            );
          } else if (type == 7) {
            //4 for upload quiz marking scheme(done by teacher)
            ////console.log("upliading feature coenc")
            fileUpload.imageUrl = downloadURL;
            fileUpload.fileName = fileUpload.file.name;
            // this.dbManager.addTeacherFeatureContent(fileUpload);
          } else if (type == 8) {
            //4 for upload quiz marking scheme(done by teacher)
            ////console.log("upliading feature coenc")
            fileUpload.downloadUrl = downloadURL;
            this.dbManager.updateConference(
              fileUpload.teacherId,
              fileUpload.conferenceId,
              { conferenceBannerUrl: downloadURL }
            );
            // this.dbManager.addTeacherFeatureContent(fileUpload);
          } else if (type == 9) {
            //4 for upload quiz marking scheme(done by teacher)
            ////console.log("upliading feature coenc")
            fileUpload.downloadUrl = downloadURL;

            this.dbManager.updateAdvertisement(fileUpload.classAdId, {
              adFileUrl: downloadURL,
            });

            // this.dbManager.addTeacherFeatureContent(fileUpload);
          } else if (type == 10) {
            fileUpload.downloadUrl = downloadURL;

            this.dbManager.updateAdvertisePayment(
              fileUpload.teacherId,
              fileUpload.classAdId,
              { bnkslip: downloadURL }
            );
            this.dbManager.updateAdvertisement(fileUpload.classAdId, {
              isPaid: true,
            });
          }

          ////console.log(fileUpload);
        });
      }
    );
  }

  public fileUpload(
    fileUpload: any,
    progress: { percentage: number },
    type: number
  ) {}

  public cancelUploadTask() {
    this.uploading.next(false);
    this.uploadTask.cancel();
  }

  public pauseUploadTask() {
    this.uploading.next(false);
    this.uploadTask.pause();
  }
  public resumeUploadTask() {
    this.uploading.next(false);
    this.uploadTask.resume();
  }

  public isActiveRunning(): Observable<boolean> {
    return this.uploading.asObservable();
  }

  isActive() {
    if (this.uploadTask) {
      return (
        this.uploadTask.snapshot.state === "running" &&
        this.uploadTask.snapshot.bytesTransferred <
          this.uploadTask.snapshot.totalBytes
      );
    } else {
      return false;
    }
  }

  public deleteQuizFileUpload(quiz: Quiz) {
    this.dbManager
      .deleteQuizFileDatabase(quiz)
      .then((result) => {
        this.deleteQuizFileStorage(quiz);
      })
      .catch((error) => console.error(error));
  }

  public deleteNoteFileUpload(note: note) {
    this.dbManager
      .deleteNoteFileDatabase(note)
      .then((result) => {
        if (!note.webLink) {
          this.deleteNoteFileStorage(note);
        }
      })
      .catch((error) => console.error(error));
  }

  public deleteFeatureVideoFileUpload(video: video) {
    this.dbManager
      .removeTeacherFeatureContent(video)
      .then((result) => {
        this.deleteFeatureVideoFileStorage(video);
      })
      .catch((error) => console.error(error));
  }

  public deleteVideoFileUpload(video: video) {
    this.dbManager
      .deleteVideoFileDatabase(video)
      .then((result) => {
        this.deleteVideoFileStorage(video);
      })
      .catch((error) => console.error(error));
  }

  ///deleting file,imgages,pdf from storage
  deleteQuizFileStorage(quiz: Quiz) {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(
        `${UploadService.QUIZ_PATH}/${quiz.teacherId}/${quiz.classId}/${quiz.quizId}`
      )
      .delete();
  }

  deleteNoteFileStorage(note: note) {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(
        `${UploadService.NOTE_PATH}/${note.teacherId}/${note.classId}/${note.noteId}`
      )
      .delete();
  }

  deleteVideoFileStorage(video: video) {
    let getheaders = new HttpHeaders().set("Accept", "application/json");
    let params = new HttpParams().set("filePath", video.videoUrl);
    this.http
      .get<any>(this.DELTA_PRESIGNED_URL, {
        params: params,
        headers: getheaders,
      })
      .subscribe((output) => {
        if (output.success) {
          this.alertService.showAlert(
            "You have successfully deleted this video.",
            MessageButtons.ok,
            MessageType.Done
          );
        } else {
          this.alertService.showAlert(
            "Error deleting this video.",
            MessageButtons.ok,
            MessageType.warning
          );
        }
      });
  }

  deleteFeatureVideoFileStorage(video: video) {
    let getheaders = new HttpHeaders().set("Accept", "application/json");
    let params = new HttpParams().set("filePath", video.videoUrl);
    this.http
      .get<any>(this.DELTA_PRESIGNED_URL, {
        params: params,
        headers: getheaders,
      })
      .subscribe((output) => {
        if (output.success) {
          this.alertService.showAlert(
            "You have successfully deleted this video.",
            MessageButtons.ok,
            MessageType.Done
          );
        } else {
          this.alertService.showAlert(
            "Error deleting this video.",
            MessageButtons.ok,
            MessageType.warning
          );
        }
      });
  }

  deleteProfilePicFile(teacherId) {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(
        `${UploadService.DBPATH_TEACHER}/${teacherId}/${this.teacherProfileImgPath}`
      )
      .delete();
  }
  deleteCoverPicFile(teacherId) {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(
        `${UploadService.DBPATH_TEACHER}/${teacherId}/${this.teacherCoverImgPath}`
      )
      .delete();
  }

  deleteQuizMarkingSchemeFile(mark: Marks) {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(`${UploadService.EVALUATED_QUIZ_PATH}/${mark.markId}`)
      .delete();
  }
  //retrive file from storage
  getQuizFileStorage(name) {
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${UploadService.QUIZ_PATH}/${name}`);
  }

  getNoteFileStorage(name) {
    const storageRef = firebase.storage().ref();
    return storageRef.child(`${UploadService.NOTE_PATH}/${name}`);
  }

  //Upload File to Amazon S3 Bucket Using Presigned URL
  onFileUpload(
    fileUpload: any,
    progress: { percentage: number; message: string; isComplete: boolean },
    type: number
  ) {
    const fileObj = fileUpload.file;
    if (!fileObj) {
      return;
    }
    if (type == 3) {
      //type 3 for videos/content
      var storagePath = `${UploadService.AWS_CONTENT_PATH}/${fileUpload.teacherId}/${fileUpload.classId}/${fileUpload.videoId}`;
    } else if (type == 7) {
      //type 7 for feature content
      var storagePath = `${UploadService.AWS_TEACHER_FEATURE_PATH}/${fileUpload.teacherId}/${fileUpload.videoId}`;
    } else if (type == 8) {
      //type 8 for advertising content
      var storagePath = `${UploadService.AWS_ADVERTISING_CONTENT_PATH}/${fileUpload.teacherId}/${fileUpload.classAdId}`;
    }

    this.getpresignedurls(fileObj.name, fileObj.type, storagePath).subscribe(
      (res) => {
        if (res.success) {
          const fileuploadurl = res.urls;
          const videoForm = new FormData();
          videoForm.append("file", fileObj);
          this.uploadfileAWSS3(fileuploadurl, fileObj.type, fileObj).subscribe(
            (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                progress.message = "Uploading";
                progress.percentage = Math.round(
                  (100 * event.loaded) / event.total
                );
              }
              if (event.status == 200) {
                progress.message = "Done";
                progress.isComplete = true;
                fileUpload.videoUrl = storagePath + fileUpload.fileName;
                fileUpload.fileName = fileUpload.fileName;

                if (type == 3) {
                  //type 3 for videos/content
                  this.dbManager.saveVideo(fileUpload);
                } else if (type == 7) {
                  //type 7 for feature content
                  this.dbManager.addTeacherFeatureContent(fileUpload);
                } else if (type == 8) {
                  //type 7 for advertising
                  // this.dbManager.addTeacherFeatureContent(fileUpload);
                }
              }
            },
            (error) => {
              ////console.log("err", error);
            }
          );
        } else {
          ////console.log(res);
        }
      },
      (error) => {
        ////console.log("pre signed url err", error);
      }
    );
  }

  getpresignedurls(logNamespace, fileType, path) {
    let getheaders = new HttpHeaders().set("Accept", "application/json");
    let params = new HttpParams()
      .set("fileName", logNamespace)
      .set("fileType", fileType)
      .set("storagePath", path);
    return this.http.get<any>(this.UPLOAD_PRESIGNED_URL, {
      params: params,
      headers: getheaders,
    });
  }

  uploadfileAWSS3(fileuploadurl, contenttype, file) {
    const headers = new HttpHeaders({ "Content-Type": contenttype });
    const req = new HttpRequest("PUT", fileuploadurl, file, {
      headers: headers,
      reportProgress: true,
    });
    return this.http.request(req);
  }

  deleteConference(teacherId: string, conferenceId: string) {
    const storageRef = firebase.storage().ref();

    storageRef
      .child(
        `${UploadService.DBPATH_TEACHER}/${teacherId}/${
          UploadService.CONFERENCE_PIC
        }/${conferenceId}/${"conference"}`
      )
      .delete();
  }

  deleteAdvertisementMedia(
    teacherId: string,
    classAdId: string,
    deletepayment = false
  ) {
    const storageRef = firebase.storage().ref();

    storageRef
      .child(
        `${UploadService.AWS_ADVERTISING_CONTENT_PATH}/${teacherId}/${classAdId}/adcontent`
      )
      .delete();
    if (deletepayment) {
      this.deleteAdvertisementPaymentMedia(teacherId, classAdId);
    } else {
      return;
    }
  }
  deleteAdvertisementPaymentMedia(teacherId: string, classAdId: string) {
    const storageRef = firebase.storage().ref();

    storageRef
      .child(
        `${UploadService.BANK_SLIP}/${teacherId}/${UploadService.AWS_ADVERTISING_CONTENT_PATH}/${classAdId}/bnkslip`
      )
      .delete()
      .then(() => {
        return;
      });
  }
}

// torageRef
//       .child(
//         `${UploadService.QUIZ_PATH}/${quiz.teacherId}/${quiz.classId}/${quiz.quizId}`
//       )
//       .delete();

// `${UploadService.DBPATH_TEACHER}/${fileUpload.teacherId}/${UploadService.CONFERENCE_PIC}/${fileUpload.conferenceId}/${"conference"}`;
