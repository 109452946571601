export class Constants {
  static readonly KEY_DEFAULT_LANGUAGE: string = "lang";
  static readonly EMAIL: string = "email";
  static readonly FIRST_TYPE: number = 1;
  static readonly SECOND_TYPE: number = 2;
  static readonly PROFILE_PIC_WIDTH: number = 800;
  static readonly PROFILE_PIC_HEIGHT: number = 800;
  static readonly COVER_PIC_WIDTH: number = 1024;
  static readonly COVER_PIC_HEIGHT: number = 576;
  static readonly MAX_PROFILE_PIC_SIZE: number = 6000;
}
