export class DigiConfernce {
  uploadedDate: string = null;
  uploadedTime: string = null;
  conferenceId: string = null;


  role: number = null;
  
  // Confercen Details
  conferenceBannerUrl: string = null;
  conferenceTitle:string = null;
  confernceDesciption:string = null;
  conferencePrice:string = null;
  conferenceState:number = 0;

  // Time
  startingTime: string = null;
  day: string = null;
  endingTime: string = null;
  

  // Zoom Details
  meetingNumber: string = null;
  password: string = null;
  shareScreen: boolean = false;


  teacherPic: string = null;
  teacherId: string = null;
  teacherName:string = null;
  teacherEmail:string = null;
  isPaid:boolean = false;
  
}

