export class Inquiry {
  inquiryId:string;
  isStudent: boolean=false;
  telNumber: string
  name: string
  message: string ;
   email: string
   date:string;
   time:string;
}
