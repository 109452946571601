import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from '../services/auth.service';
import { LoginState } from '../services/user';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  registerType: string = "signin";
  private timeLeft: number = 60;
  private counterId;

  welcome: AnimationOptions = {
    path: '/assets/lti/lti_welcome.json', loop: false
  };
  unveryfied: AnimationOptions = {
    path: '/assets/lti/lti_unveryfied.json', loop: false
  };

  constructor(public authService: AuthService, private router: Router) {
    this.startAttempt();
  }

  ngOnInit(): void {
    this.authService.clearAuthMessage();
  }

  loginWithGoogle() {
    this.authService.GoogleAuth();
    this.router.navigate(['']);
  }

  changeRegType(type: string) {
    this.authService.clearAuthMessage();
    this.registerType = type;
  }

  startAttempt() {
    this.counterId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
      if (this.authService.loginState === LoginState.VerfiedLogin) {
        clearInterval(this.counterId);
        this.router.navigate(['']);
      } else if (
        this.authService.loginState === LoginState.notVerified &&
        this.registerType === "signin"
      ) {
        clearInterval(this.counterId);
        this.router.navigate(['']);
      }
    }, 1000);
  }
}
