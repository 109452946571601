import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DatetimeService {
  constructor(private datepipe: DatePipe) { }

  changeTimeFormat(time: string): string {
    return this.datepipe.transform(new Date("2020-01-01 " + time), "hh:mm aa");
  }
  changeTimeFormat2(time: string): string {
    return this.datepipe.transform(new Date("2020-01-01 " + time), "hh:mm");
  }

  getStringTime(time: { hour: number; minute: number }) {
    //please use his funcion to display time if using nbn spinner to get time
    let hour = time.hour < 10 ? "0" + time.hour : time.hour;
    let minute = time.minute < 10 ? "0" + time.minute : time.minute;

    let value = hour + ":" + minute;

    return value;
  }

  getStringHours(time: { hour: number; minute: number }) {
    let hour = time.hour < 10 ? "0" + time.hour : time.hour.toString();
    return parseInt(hour);
  }
  getStringMinutes(time: { hour: number; minute: number }) {
    let minute = time.minute < 10 ? "0" + time.minute : time.minute.toString();
    return parseInt(minute);
  }

  getObjectTime(value: string) {
    const hour = parseInt(value.split(":")[0]);
    const min = parseInt(value.split(":")[1]);
    ////console.log(hour, min);
    return { hour: hour, minute: min };
  }

  getStringDay(value: Date) {
    ////console.log(value);

    var date: Date = new Date(value);
    switch (date.getDay()) {
      case 1:
        return "Mon";
      case 2:
        return "Tues";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      default:
        return "Sun";
    }
  }

  /**
   * 
   * @param time24Hrs 
   * @returns 
   */
  public get12HrsTime(time24Hrs: string): string {
    let hrs: number = Number.parseInt(time24Hrs.split(":")[0]);
    var midday = "AM";
    if (hrs > 12) {
      hrs = hrs - 12;
      midday = "PM";
    }
    if (hrs === 0)
      hrs = 12;
    return hrs.toString() + ":" + time24Hrs.split(":")[1] + " " + midday;
  }
}
