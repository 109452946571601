import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { Class } from "../model/Class";
import { Grade } from "../model/Grade";
import { SubjectEnrollDoc } from "../model/SubjectEnrollDoc";
import { Inquiry } from "../model/Inquiry";
import { LiveClass } from "../model/LiveClass";
import { Marks } from "../model/Marks";
import { note } from "../model/notes";
import { Quiz } from "../model/quiz";
import { Subject } from "../model/subject";
import { Teacher } from "../model/teacher";
import { video } from "../model/video";
import * as firebase from "firebase/app";
import { Notice } from "../model/notice";
import { BankDetails } from "../model/BankDetails";
import { Discussion } from "../model/discussion";
import { PriceData } from "../model/pricing";
import { ClassAd } from "../model/classAd";
import { resolve } from "url";
import { ConferencePayment } from "../model/conference-payment";
import { ClassAdPayment } from "../model/classAdPayment";
//injectable module
@Injectable({
  providedIn: "root",
})
export class DatabaseManager {
  //angular fire ref
  angularfireRef: AngularFireList<any>;

  //database paths
  private static readonly DBPATH_TEACHER = "Teacher";
  private static readonly DBPATH_STUDENT = "Student";
  private static readonly DBPATH_STUDENT_PAYMENT = "StudentPayment";
  private static readonly TEACHER_CLASS_PATH = "Teacher_Class";
  private static readonly CONTENT = "Content";
  private static readonly SUBJECT_PATH = "Subject";
  private static readonly GRADE_PATH = "Grade";
  private static readonly TEACHER_QUIZ_PATH = "Quiz";
  private static readonly TEACHER_EVALUATED_QUIZ = "TeacherEvaluatedQuiz";
  private static readonly CLASS_STUDENT_ENROLLMENT = "ClassStudent_Enrollment";
  private static readonly DBPATH_TEACHER_NOTICES = "TeacherNotice";
  private static readonly TEACHER_NOTE_PATH = "TeacherNotes";
  private static readonly EVALUATED_QUIZ_PATH = "EvaluatedQuiz";
  private static readonly NONEVALUATED_QUIZ_PATH = "NonEvaluatedQuiz";
  private static readonly TEACHER_LIVE_CLASS = "TeacherLiveClass";
  private static readonly SUBJECT_ENROLLMENTS_PATH = "Subject_Enrollments";
  private static readonly FEATURE_CONTENT_PATH = "FeatureContent";
  private static readonly INQUIRY_PATH = "Inquires";
  private static readonly MARKS_PATH = "Marks";
  private static readonly DBPATH_BANK_DETAILS = "BankDetails";
  private static readonly DBPATH_DISCUSSIONS = "Discussion";
  private static readonly DBPATH_SUB_DISCUSSIONS = "Replies";
  private static readonly DBPATH_SETTINGS = "Settings";
  private static readonly DBPATH_DOC_PRICING = "Pricing";
  private static readonly DBPATH_ADVERTISING = "Advertisements";
  private static readonly DBPATH_ADVERTISE_PAYMENT = "AdvertisePayment";
  private static readonly DBPATH_EXPIRED_ADVERTISEMENTS =
    "ExpiredAdvertisements";
  private static readonly DBPATH_CONFERENCE = "Conference";
  private static readonly DBPATH_CONFERENCE_PAYMENT = "ConferencePayment";
  private static readonly DBPATH_ADVERTISE_PRICES = "AdvertisementPrice";

  // collections ref
  private teacherRef: AngularFirestoreCollection<Teacher> = null;

  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore
  ) {
    this.teacherRef = firestore.collection(DatabaseManager.DBPATH_TEACHER);
  }

  /**
   *
   * @returns a unique key
   */
  generateKey(): string {
    return this.firestore.createId();
  }

  /**
   *
   * @param teacherId
   * @returns
   */
  isTeacherAvailable(teacherId: string) {
    return new Promise<boolean>((resolve, reject) => {
      var docRef = this.firestore
        .collection(DatabaseManager.DBPATH_TEACHER)
        .doc(teacherId);
      docRef.ref
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          ////console.log("Error getting user data:", error);
        });
    });

    // *#remove this*
    // this.angularfireRef = this.db.list(
    //   DatabaseManager.DBPATH_TEACHER + "/" + teacherId
    // );

    // return new Promise<boolean>((resolve, reject) => {
    //   this.angularfireRef
    //     .snapshotChanges()
    //     .pipe(
    //       map((changes: any[]) =>
    //         changes.map((c) => ({ val: c.payload.val() }))
    //       )
    //     )
    //     .subscribe((classes) => {
    //       if (classes.length !== 0) resolve(true);
    //       else resolve(false);
    //     });
    // });
    // *#remove this*
  }

  /**
   * Add new teacher to the system
   * @param teacher
   */
  addNewTeacher(teacher: Teacher) {
    // *#remove this*
    // this.angularfireRef = this.db.list(DatabaseManager.DBPATH_TEACHER);
    // return this.angularfireRef.set(teacher.teacherId, teacher);
    // *#remove this*

    this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacher.teacherId)
      .set(teacher);
  }

  //saving functions

  public addTeacherSubject(teacherId, values) {
    // *#remove this*
    // return this.db
    //   .object(`${DatabaseManager.TEACHER_ENROLLMENT_PATH}/${teacherId}`)
    //   .set(values);
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .ref.update("teacherSubjects", values);
  }

  public addSubjectEnrollment(subjectId: string, values: string[]) {
    // *#remove this*
    // return this.db
    //   .object(`${DatabaseManager.SUBJECT_ENROLLMENTS_PATH}/${subjectId}`)
    //   .set(values);
    // *#remove this*
    const obj: SubjectEnrollDoc = {
      idSet: values,
    };
    return this.firestore
      .collection(DatabaseManager.SUBJECT_PATH)
      .doc(subjectId.trim())
      .collection(DatabaseManager.SUBJECT_ENROLLMENTS_PATH)
      .doc("SubjectEnrollDoc")
      .set(Object.assign({}, obj));
  }

  public saveQuiz(myQuiz: Quiz) {
    // return this.db
    //   .object(
    //     `${DatabaseManager.TEACHER_QUIZ_PATH}/${values.teacherId}/${values.classId}/${values.quizId}`
    //   )
    //   .set(values);
    // //console.log(myQuiz)

    delete myQuiz.file;
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(myQuiz.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(myQuiz.classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH)
      .doc(myQuiz.quizId)
      .set(Object.assign({}, myQuiz));
  }

  public saveNote(myNote: note) {
    //delete file field from the object
    delete myNote.file;
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(myNote.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(myNote.classId)
      .collection(DatabaseManager.TEACHER_NOTE_PATH)
      .doc(myNote.noteId)
      .set(Object.assign({}, myNote));
  }

  public saveVideo(value: video) {
    // *#remove this*
    // return this.db
    //   .object(
    //     `${DatabaseManager.CONTENT}/${values.teacherId}/${values.subjectId}/${values.classId}/${values.videoId}`
    //   )
    //   .set(values);
    // *#remove this*

    //delete file field from the object
    delete value.file;
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(value.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(value.classId)
      .collection(DatabaseManager.CONTENT)
      .doc(value.videoId)
      .set(Object.assign({}, value));
  }

  public saveSubject(values: Subject) {
    return this.db
      .object(`${DatabaseManager.SUBJECT_PATH}/${values.subjectId}`)
      .set(values);
  }

  public saveQuizMarking(mark: Marks, classId, quizId, type, teacherId) {
    // let root =
    //   type == 0
    //     ? DatabaseManager.NONEVALUATED_QUIZ_PATH
    //     : DatabaseManager.EVALUATED_QUIZ_PATH;
    // return this.db
    //   .object(`${root}/${classId}/${quizId}/${mark.markId}`)
    //   .set(mark);

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH)
      .doc(quizId)
      .collection(DatabaseManager.MARKS_PATH)
      .doc(mark.markId)
      .set(Object.assign({}, mark));
  }

  addTeacherClass(value: Class) {
    // *#remove this*
    // let classId = this.db.database
    //   .ref(`${DatabaseManager.TEACHER_CLASS_PATH}/${value.teacherId}`)
    //   .push().key;
    // value.classId = classId;

    // return this.db
    //   .object(
    //     `${DatabaseManager.TEACHER_CLASS_PATH}/${value.teacherId}/${classId}`
    //   )
    //   .set(value).then((res) => {
    //     this.db
    //       .object(
    //         `${DatabaseManager.CLASS_REQUEST_PATH}/${classId}`
    //       )
    //       .set(value)
    //   });
    // *#remove this*
    const key = this.firestore.createId();
    value.classId = key;
    return this.firestore
      .doc(
        `${DatabaseManager.DBPATH_TEACHER}/${value.teacherId}/${DatabaseManager.TEACHER_CLASS_PATH}/${key}`
      )
      .set(Object.assign({}, value));
  }

  addTeacherLiveClass(value: LiveClass, teacherId: string) {
    // *#remove this*
    // let liveClassRef = this.db.database
    //   .ref(
    //     `${DatabaseManager.TEACHER_LIVE_CLASS}/${teacherId}/${values.classId}`
    //   )
    //   .push();
    // value.liveId = liveClassRef.key;
    // return liveClassRef.set(values);
    // *#remove this*
    const key = this.firestore.createId();
    value.liveId = key;
    return this.firestore
      .doc(
        `${DatabaseManager.DBPATH_TEACHER}/${teacherId}/${DatabaseManager.TEACHER_CLASS_PATH}/${value.classId}/${DatabaseManager.TEACHER_LIVE_CLASS}/${key}`
      )
      .set(Object.assign({}, value));
  }

  addTeacherFeatureContent(values: video) {
    delete values.file;
    console.table(values);
    this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(values.teacherId)
      .collection(DatabaseManager.FEATURE_CONTENT_PATH)
      .doc(values.videoId)
      .set(Object.assign({}, values));
  }

  addTeacherEvaluatedQuiz(quiz: Quiz) {
    return this.db
      .object(
        `${DatabaseManager.TEACHER_EVALUATED_QUIZ}/${quiz.teacherId}/${quiz.classId}/${quiz.quizId}`
      )
      .set(quiz);
  }

  addEvaluatedQuiz(list, classId, quizId) {
    return this.db
      .object(DatabaseManager.EVALUATED_QUIZ_PATH + `/${classId}/${quizId}`)
      .set(list);
  }

  addInquiry(inquiry: Inquiry) {
    const key = this.db.createPushId();
    return this.db
      .object(DatabaseManager.INQUIRY_PATH + `/${key}`)
      .set(inquiry);
    // const key = this.firestore.createId()
    // inquiry.inquiryId = key;
    // return this.firestore.doc(
    //   `${DatabaseManager.INQUIRY_PATH}/${key}`).
    //   set(Object.assign({}, inquiry));
  }

  //retriving functions
  getTeacherAllClass(teacherId): AngularFirestoreCollection<Class> {
    // *#remove this*
    // return this.db.list(DatabaseManager.TEACHER_CLASS_PATH + `/${teacherId}`);
    // *#remove this*
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH);
  }
  getStudentEnrolledClass(teacherId, classId) {
    // //console.log("classId", classId);
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.CLASS_STUDENT_ENROLLMENT)
      .doc("ClassStudentDoc")
      .ref.get();
  }
  getStudentPayments(stdId, teacherId, year, month) {
    // //console.log("std" + stdId);
    // //console.log("year" + parseInt(year));

    let yearValue = parseInt(year);
    let monthValue = parseInt(month);

    return this.firestore
      .collection(DatabaseManager.DBPATH_STUDENT)
      .doc(stdId)
      .collection(DatabaseManager.DBPATH_STUDENT_PAYMENT, (ref) =>
        ref
          .where("year", "==", yearValue)
          .where("month", "==", monthValue)
          .where("teacherId", "==", teacherId)
          .where("paymentStatus", "==", 1)
      )
      .ref.get();
  }

  getEnrolledStudentsPayemnt(
    classId: string,
    yearValue: number,
    monthValue: number
  ) {
    return new Promise((resolve) => {
      this.firestore
        .collectionGroup(DatabaseManager.DBPATH_STUDENT_PAYMENT, (ref) =>
          ref
            .where("year", "==", yearValue)
            .where("month", "==", monthValue)
            .where("classId", "==", classId)
            .where("paymentStatus", "==", 1)
        )
        .get()
        .subscribe((data) => {
          resolve(data.docs.map((docs) => docs.data()));
        });
    });
  }

  getTeacherLiveClass(
    teacherId,
    classId
  ): AngularFirestoreCollection<LiveClass> {
    // *#remove this*
    // return this.db.list(
    //   DatabaseManager.TEACHER_LIVE_CLASS + `/${teacherId}/${classId}`
    // );
    // *#remove this*
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_LIVE_CLASS);
  }

  getTeacherFeatureContent(teacherId): AngularFirestoreCollection<video> {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.FEATURE_CONTENT_PATH);
  }

  getTeacherEnrollments(teacherId: string) {
    // return this.db.list(
    //   DatabaseManager.TEACHER_ENROLLMENT_PATH + `/${teacherId}`
    // );

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId);
  }

  getSubjectEnrollments(subjectId: string) {
    // *#remove this*
    // return this.db.list(
    //   DatabaseManager.SUBJECT_ENROLLMENTS_PATH + `/${subjectId}`
    // );
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.SUBJECT_PATH)
      .doc(subjectId)
      .collection(DatabaseManager.SUBJECT_ENROLLMENTS_PATH)
      .doc("SubjectEnrollDoc")
      .ref.get();
  }

  getSubjects(): AngularFirestoreCollection<Subject> {
    // *#remove this*
    // return this.db.list(DatabaseManager.SUBJECT_PATH);
    // *#remove this*

    return this.firestore.collection(DatabaseManager.SUBJECT_PATH);
  }

  getSubjectsWithGrades(gradeId: string): AngularFirestoreCollection<Subject> {
    // *#remove this*
    // return this.db.list(DatabaseManager.SUBJECT_PATH);
    // *#remove this*

    return this.firestore.collection(DatabaseManager.SUBJECT_PATH, (res) =>
      res.where("gradeId", "==", gradeId)
    );
  }

  getGrades(): AngularFirestoreCollection<Grade> {
    // *#remove this*
    // return this.db.list(DatabaseManager.GRADE_PATH);
    // *#remove this*
    return this.firestore.collection(DatabaseManager.GRADE_PATH);
  }

  getTeacherQuiz(teacherId, classId): AngularFirestoreCollection<Quiz> {
    // return this.db.list(
    //   DatabaseManager.TEACHER_CURRENT_QUIZ_PATH + `/${teacherId}/${classId}`
    // );
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH);
  }

  getTeacherEvalutedQuiz(teacherId, classId): AngularFireList<Quiz> {
    return this.db.list(
      DatabaseManager.TEACHER_EVALUATED_QUIZ + `/${teacherId}/${classId}`
    );
  }

  getAllNote(teacherId, classId): AngularFirestoreCollection<note> {
    // *#remove this*
    // return this.db.list(
    //   DatabaseManager.TEACHER_NOTE_PATH + `/${teacherId}/${classId}`
    // );
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_NOTE_PATH);
  }

  getAllVideos(teacherId, classId): AngularFirestoreCollection<video> {
    // *#remove this*
    // return this.db.list(
    //   DatabaseManager.CONTENT +
    //   `/${teacherId}/${subjectId}/${classId}`
    // );
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.CONTENT, (ref) =>
        ref.orderBy("srhTimestamp", "asc")
      );
  }

  getMarksList(classId, quizId, teacherId): AngularFirestoreCollection<Marks> {
    // return this.db.list(
    //   DatabaseManager.EVALUATED_QUIZ_PATH + `/${classId}/${quizId}`
    // );
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH)
      .doc(quizId)
      .collection(DatabaseManager.MARKS_PATH);
  }

  getAllNonEvaluatedQuizList(classId, quizId): AngularFireList<Marks> {
    return this.db.list(
      DatabaseManager.NONEVALUATED_QUIZ_PATH + `/${classId}/${quizId}`
    );
  }

  getVideo(teacherId, classId, videoId) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.CONTENT)
      .doc(videoId)
      .ref.get();
  }

  /**
   * Return video object
   * @param myClass
   * @returns
   */
  getVideoFile(teacherId: string, classId: string, videoId: string) {
    return new Promise<video>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .get()
        .subscribe((element) => {
          resolve(<video>element.data());
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param videoId
   * @returns
   */
  getFeaturedVideo(teacherId: string, videoId: string) {
    return new Promise<video>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.FEATURE_CONTENT_PATH)
        .doc(videoId)
        .get()
        .subscribe((element) => {
          resolve(<video>element.data());
        });
    });
  }

  /**
   *
   */
  private featuredVideos: video[] = [];
  getFeaturedVideos(teacherId: string) {
    return new Promise((resolve) => {
      if (this.featuredVideos.length === 0) {
        this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.FEATURE_CONTENT_PATH)
          .get()
          .subscribe((elements) => {
            this.featuredVideos = <video[]>(
              elements.docs.map((doc) => doc.data())
            );
            resolve(this.featuredVideos);
          });
      } else resolve(this.featuredVideos);
    });
  }

  /**
   *
   * @param myVideo
   * @returns
   */
  getSuggestVideos(myVideo: video) {
    return new Promise((resolve, reject) => {
      var collectionRef = this.teacherRef
        .doc(myVideo.teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(myVideo.classId)
        .collection(DatabaseManager.CONTENT, (results) =>
          results
            .orderBy("srhTimestamp", "desc")
            .startAfter(myVideo.srhTimestamp)
            .limit(10)
        );
      collectionRef.get().subscribe((elements) => {
        let videos: video[] = <video[]>elements.docs.map((doc) => doc.data());
        resolve(videos);
      });
    });
  }

  getStudent(stdId) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_STUDENT)
      .doc(stdId)
      .ref.get();
  }

  getFeatureVideo(teacherId, videoId) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.FEATURE_CONTENT_PATH)
      .doc(videoId)
      .ref.get();
  }

  _getVideo(teacherId, subjectId, classId, videoId) {
    return new Promise<video>((resolve, reject) => {
      firebase
        .database()
        .ref(
          DatabaseManager.CONTENT +
            `/${teacherId}/${subjectId}/${classId}/${videoId}`
        )
        .once("value")
        .then((item) => {
          ////console.log(item.val());
          resolve(item.val());
        });
    });
  }

  getQuiz(teacherId, classId, quizId, type: number) {
    let root =
      type == 0
        ? DatabaseManager.TEACHER_QUIZ_PATH
        : DatabaseManager.TEACHER_EVALUATED_QUIZ;

    // return this.db
    //   .object(root + `/${teacherId}/${classId}/${quizId}`)
    //   .valueChanges();

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH)
      .doc(quizId)
      .ref.get();
  }

  _getQuiz(teacherId, classId, quizId, type: number) {
    let root =
      type == 0
        ? DatabaseManager.TEACHER_QUIZ_PATH
        : DatabaseManager.TEACHER_EVALUATED_QUIZ;
    return new Promise<Quiz>((resolve, reject) => {
      firebase
        .database()
        .ref(root + `/${teacherId}/${classId}/${quizId}`)
        .once("value")
        .then((item) => {
          ////console.log(item.val());
          resolve(item.val());
        });
    });
  }

  // *#remove this*
  // getNote(noteId) {
  //   return this.db.list(DatabaseManager.TEACHER_NOTE_PATH, (ref) =>
  //     ref.orderByChild("noteId").equalTo(noteId)
  //   );
  // }
  // *#remove this*
  getNote(noteId) {
    return this.db.list(DatabaseManager.TEACHER_NOTE_PATH, (ref) =>
      ref.orderByChild("noteId").equalTo(noteId)
    );
  }
  _getNote(noteId) {
    return new Promise<note>((resolve, reject) => {
      firebase
        .database()
        .ref(DatabaseManager.DBPATH_TEACHER)
        .orderByChild("noteId")
        .equalTo(noteId)
        .once("value")
        .then((item) => {
          ////console.log(item.val());
          resolve(item.val());
        });
    });
  }

  getTeacherDetails(id) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER, (ref) =>
        ref.where("teacherId", "==", id)
      )
      .snapshotChanges()
      .pipe(map((val) => (val.length > 0 ? val[0] : null)));
  }

  _getTeacherDetails(teacherId) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId);
  }

  //updating function
  updateTeacherProfile(value: any, key: string): Promise<void> {
    // *#remove this*
    // return this.db
    //   .object(`${DatabaseManager.DBPATH_TEACHER}/${key}`)
    //   .update(value);
    // *#remove this*
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(key)
      .update(value);
  }

  updateLiveClass(value: LiveClass, teacherId) {
    // *#remove this*
    // return this.db
    //   .object(
    //     `${DatabaseManager.TEACHER_LIVE_CLASS}/${teacherId}/${values.classId}/${values.liveId}`
    //   )
    //   .update(values);
    // *#remove this*
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(value.classId)
      .collection(DatabaseManager.TEACHER_LIVE_CLASS)
      .doc(value.liveId)
      .update(Object.assign({}, value));
  }

  //deleting or removing

  deleteLiveClass(value: LiveClass, teacherId) {
    // *#remove this*
    // return this.db
    //   .object(
    //     `${DatabaseManager.TEACHER_LIVE_CLASS}/${teacherId}/${values.classId}/${values.liveId}`
    //   )
    //   .remove();
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(value.classId)
      .collection(DatabaseManager.TEACHER_LIVE_CLASS)
      .doc(value.liveId)
      .delete();
  }

  deleteQuizFileDatabase(quiz: Quiz) {
    // return this.db
    //   .list(
    //     `${DatabaseManager.TEACHER_QUIZ_PATH}/${quiz.teacherId}/${quiz.classId}`
    //   )
    //   .remove(quiz.quizId);
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(quiz.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(quiz.classId)
      .collection(DatabaseManager.TEACHER_QUIZ_PATH)
      .doc(quiz.quizId)
      .delete();
  }

  deleteNoteFileDatabase(note: note) {
    // *#remove this*
    // return this.db
    //   .list(
    //     `${DatabaseManager.TEACHER_NOTE_PATH}/${note.teacherId}/${note.classId}`
    //   )
    //   .remove(note.key);
    // *#remove this*

    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(note.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(note.classId)
      .collection(DatabaseManager.TEACHER_NOTE_PATH)
      .doc(note.noteId)
      .delete();
  }

  deleteVideoFileDatabase(video: video) {
    // return this.db
    //   .list(
    //     `${DatabaseManager.CONTENT}/${video.teacherId}/${video.subjectId}/${video.classId}`
    //   )
    //   .remove(video.videoId);
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(video.teacherId)
      .collection(DatabaseManager.TEACHER_CLASS_PATH)
      .doc(video.classId)
      .collection(DatabaseManager.CONTENT)
      .doc(video.videoId)
      .delete();
  }
  removeNonEvaluatedQuiz(classId, quizId) {
    return this.db
      .list(`${DatabaseManager.NONEVALUATED_QUIZ_PATH}/${classId}`)
      .remove(quizId);
  }

  removeTeacherFeatureContent(video: video) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(video.teacherId)
      .collection(DatabaseManager.FEATURE_CONTENT_PATH)
      .doc(video.videoId)
      .delete();
  }

  //testing
  getStudentNotices(studentId: string) {
    return new Promise((resolve, reject) => {
      this.db
        .list("StudentNotices")
        .snapshotChanges()
        .pipe(
          map((changes: any[]) =>
            changes.map((c) => ({ key: c.payload.key, val: c.payload.val() }))
          )
        )
        .subscribe((students) => {
          students.forEach((student) => {
            if (student.key === studentId) {
              resolve(student.val);
            }
          });
        });
    });
  }

  /*** student notices */

  deleteTeacherNotice(key: string, teacherId: string) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_NOTICES)
      .doc(key)
      .delete();
  }

  getTeacherNotices(teacherId: string) {
    return new Promise<Array<Notice>>((resolve, reject) => {
      var noticeList: Notice[] = new Array();

      this.firestore
        .collection(DatabaseManager.DBPATH_TEACHER)
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_NOTICES, (res) =>
          res.orderBy("timestamp", "desc")
        )
        .snapshotChanges()
        .pipe(
          map((changes: any[]) =>
            changes.map((c) => ({
              key: c.payload.doc.id,
              ...c.payload.doc.data(),
            }))
          )
        )
        .subscribe((notices) => {
          noticeList = new Array<Notice>();
          notices.forEach((notice) => {
            var n: Notice = {
              noticeId: notice.key,
              gradeName: notice.gradeName,
              isSystemMessage: notice.isSystemMessage,
              messageDescription: notice.messageDescription,
              messageName: notice.messageName,
              publishDate: notice.publishDate,
              publishTime: notice.publishTime,
              subjectName: notice.subjectName,
              teacherId: notice.teacherId,
              teacherName: notice.teacherName,
              teacherPic: notice.teacherPic,
            };
            noticeList.push(n);
          });
          resolve(noticeList);
        });
    });
  }

  getBankDetails(teacherId: string) {
    return new Promise<BankDetails>((resolve, reject) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_BANK_DETAILS)
        .doc(teacherId)
        .get()
        .subscribe((snapshot) => {
          if (snapshot.exists) {
            resolve(<BankDetails>snapshot.data());
          } else {
            var bankDetails: any = {
              accountNumber: "",
              bankName: "",
              branchCode: "",
              branchName: "",
              teacherId: teacherId,
            };
            this.firestore
              .collection(DatabaseManager.DBPATH_BANK_DETAILS)
              .doc(teacherId)
              .set(bankDetails);
          }
        });
    });
  }

  updateBankDetails(teacherId: string, data: any): Promise<void> {
    return this.firestore
      .collection(DatabaseManager.DBPATH_BANK_DETAILS)
      .doc(teacherId)
      .update(data);
  }

  //////////////////// new /////////////////////////////

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param discussion
   * @returns
   */
  addDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    discussion: Discussion
  ) {
    return new Promise<boolean>((resolve, reject) => {
      discussion.messageId = this.firestore.createId();
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(discussion.messageId)
        .set(discussion)
        .then(
          (res) => {
            resolve(true);
          },
          (err) => reject(err)
        );
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @param discussion
   * @returns
   */
  addSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string,
    discussion: Discussion
  ) {
    return new Promise<boolean>((resolve, reject) => {
      discussion.messageId = this.firestore.createId();
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .doc(discussion.messageId)
        .set(discussion)
        .then(
          (res) => {
            //updating the main discussion doc
            this.teacherRef
              .doc(teacherId)
              .collection(DatabaseManager.TEACHER_CLASS_PATH)
              .doc(classId)
              .collection(DatabaseManager.CONTENT)
              .doc(videoId)
              .collection(DatabaseManager.DBPATH_DISCUSSIONS)
              .doc(messageId)
              .update({ replyAvailable: true })
              .then((out) => {
                resolve(true);
              });
          },
          (err) => reject(err)
        );
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @returns
   */
  getDiscussion(teacherId: string, classId: string, videoId: string) {
    return new Promise<Array<Discussion>>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Discussion> = <Array<Discussion>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @returns
   */
  removeDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .delete()
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   */
  noDicussionReply(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .update({ replyAvailable: false });
    });
  }

  /**
   * Edit discussion
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @param message
   * @returns
   */
  editDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string,
    message: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .update({ message: message })
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @returns
   */
  getSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<Array<Discussion>>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Discussion> = <Array<Discussion>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param commentId
   * @param messageId
   * @returns
   */
  removeSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    commentId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.TEACHER_CLASS_PATH)
        .doc(classId)
        .collection(DatabaseManager.CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(commentId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .doc(messageId)
        .delete()
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   * Get price data
   * @returns
   */
  getPricing() {
    return new Promise<PriceData>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_SETTINGS)
        .doc(DatabaseManager.DBPATH_DOC_PRICING)
        .get()
        .subscribe((element) => {
          resolve(<PriceData>element.data());
        });
    });
  }

  getAdvertisePricing() {
    return new Promise((resolve, reject) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_SETTINGS)
        .doc(DatabaseManager.DBPATH_ADVERTISE_PRICES)
        .get()
        .subscribe((res) => {
          resolve(res.data());
        });
    });
  }

  //advertising
  advertisePayment(payment: ClassAdPayment) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(payment.teacherId)
      .collection(DatabaseManager.DBPATH_ADVERTISE_PAYMENT)
      .doc(payment.classAdId)
      .set(payment);
  }
  updateAdvertisePayment(teacherId: string, classAdId: string, data: Object) {
    this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_ADVERTISE_PAYMENT)
      .doc(classAdId)
      .update(data);
  }
  createAdvertisement(advertisement: ClassAd) {
    return this.firestore
      .collection(DatabaseManager.DBPATH_ADVERTISING)
      .doc(advertisement.classAdId)
      .set(advertisement);
  }

  getAdvertisements(teacherId: string) {
    return new Promise<ClassAd[]>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISING, (res) =>
          res.where("teacherId", "==", teacherId)
        )
        .get()
        .subscribe((elements) => {
          resolve(<ClassAd[]>elements.docs.map((doc) => doc.data()));
        });
    });
  }

  updateAdvertisement(classAdId: string, data: any) {
    this.firestore
      .collection(DatabaseManager.DBPATH_ADVERTISING)
      .doc(classAdId)
      .update(data);
  }

  updateAdvertisementVariable(
    classAdId: string,
    variableName: string,
    data: any
  ) {
    this.firestore
      .collection(DatabaseManager.DBPATH_ADVERTISING)
      .doc(classAdId)
      .ref.update(variableName, data);
  }

  getAdvertisementById(classAdId: string) {
    return new Promise((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISING)
        .doc(classAdId)
        .get()
        .subscribe((res) => {
          resolve(res.data() as ClassAd);
        });
    });
  }

  deleteAdvertisement(classAdId, teacherId: string) {
    this.firestore
      .collection(DatabaseManager.DBPATH_ADVERTISING)
      .doc(classAdId)
      .delete()
      .then(() => {
        this.firestore
          .collection(DatabaseManager.DBPATH_TEACHER)
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_ADVERTISE_PAYMENT)
          .doc(classAdId)
          .delete()
          .then((res) => {
            //console.log(res);
          })
          .catch((errr) => console.error(errr));
      });
  }

  //expired advertisements

  createExpiredAdvertisement(advertisement: ClassAd) {
    this.teacherRef
      .doc(advertisement.teacherId)
      .collection(DatabaseManager.DBPATH_EXPIRED_ADVERTISEMENTS)
      .doc(advertisement.classAdId)
      .set(advertisement);
  }

  getExpiredAdvertisements(teacherId: string) {
    return new Promise<ClassAd[]>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_EXPIRED_ADVERTISEMENTS)
        .get()
        .subscribe((elements) => {
          resolve(<ClassAd[]>elements.docs.map((doc) => doc.data()));
        });
    });
  }

  deleteExpiredAdvertisements(teacherId: string, classAdId: string) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_EXPIRED_ADVERTISEMENTS)
      .doc(classAdId)
      .delete();
  }

  // Add Conference
  createConference(conferenceObj: any) {
    this.teacherRef
      .doc(conferenceObj.teacherId)
      .collection(DatabaseManager.DBPATH_CONFERENCE)
      .doc(conferenceObj.conferenceId)
      .set(Object.assign({}, conferenceObj))
      .catch((err) => console.error(err));
  }

  updateConference(teacherId: any, id: string, data: any) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_CONFERENCE)
      .doc(id)
      .update(data)
      .catch((err) => console.error(err));
  }

  isSomeonePayed(conferenceId: string) {
    return new Promise((resolve, reject) => {
      this.firestore
        .collectionGroup(DatabaseManager.DBPATH_CONFERENCE_PAYMENT, (result) =>
          result.where("conferenceId", "==", conferenceId)
        )
        .get()
        .subscribe((res) => {
          resolve(!res.empty);
        });
    });
  }

  deleteConference(teacherId: string, conferenceId: string) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_CONFERENCE)
      .doc(conferenceId)
      .delete();
  }

  getAllConference(teacherId: any) {
    return new Promise((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_CONFERENCE)
        .get()
        .subscribe((data) => {
          let conferences: Array<Discussion> = <Array<Discussion>>(
            data.docs.map((doc) => doc.data())
          );
          resolve(conferences);
        });
    });
  }

  getConferenceProfit() {
    return new Promise((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_SETTINGS)
        .doc("conferenceprice")
        .get()
        .forEach((doc) => {
          resolve(doc.data());
        });
    });
  }

  getEnrolledStudents(conferenceId: string) {
    /**
     * Get Enrolled for a specific conference
     */

    return new Promise((resolve, reject) => {
      this.firestore
        .collectionGroup(DatabaseManager.DBPATH_CONFERENCE_PAYMENT, (result) =>
          result.where("conferenceId", "==", conferenceId)
        )
        .get()
        .subscribe((res) => {
          let conferences: Array<ConferencePayment> = <
            Array<ConferencePayment>
          >res.docs.map((doc) => doc.data());
          resolve(conferences);
        });
    });
  }
}
