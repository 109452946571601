import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Inquiry } from "../model/Inquiry";
import { AlertService } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { DatetimeService } from "../util/datetime.service";

@Component({
  selector: "app-inquiry",
  templateUrl: "./inquiry.component.html",
  styleUrls: ["./inquiry.component.scss"],
})
export class InquiryComponent implements OnInit {
  inquiry: Inquiry = new Inquiry();
  userEmail: string = "";
  userName: string = "";
  emailLock: boolean = false;
  nameLock: boolean = false;

  constructor(
    private auth: AuthService,
    private dataService: DataService,
    private dbManager: DatabaseManager,
    private title: Title,
    private alertService: AlertService,
    public datepipe: DatePipe,
    private datetime: DatetimeService
  ) {
    //wait until user connecting with the server
    auth.isUserAvailable().then((res) => {
      if (res) {
        this.inquiry.email = auth.userData.email;
        this.emailLock = true;
      } else {
        this.inquiry.email = "";
        this.emailLock = false;
      }
      if (this.dataService.getTeacherDetails()) {
        this.inquiry.name = this.dataService.getTeacherDetails().teacherName;
      } else {
        this.inquiry.name = "";
      }
    });
  }

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Inquiry | Tutor - Digi Panthiya");
  }

  sendInquiry() {
    this.inquiry.isStudent = false;
    this.inquiry.telNumber = "";
    this.inquiry.date = this.formatDate(new Date());
    this,
      (this.inquiry.time = this.datetime.getStringTime({
        hour: new Date().getHours(),
        minute: new Date().getMinutes(),
      }));

    if (this.inquiry.message && this.inquiry.name && this.inquiry.email) {
      this.dbManager.addInquiry(this.inquiry).then((res) => {
        // //console.log(res)
      });
    } else if (!this.inquiry.email) {
      this.alertService.showAlert(
        "please complete email fields",
        MessageButtons.ok,
        MessageType.warning
      );
    } else if (!this.inquiry.name) {
      this.alertService.showAlert(
        "please complete name fields",
        MessageButtons.ok,
        MessageType.warning
      );
    } else if (!this.inquiry.message) {
      this.alertService.showAlert(
        "please complete message fields",
        MessageButtons.ok,
        MessageType.warning
      );
    } else {
      this.alertService.showAlert(
        "please complete all fields",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }
}
