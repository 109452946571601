import { Component, OnInit } from "@angular/core";
import { video } from "../model/video";
import { DataService } from "../services/data.service";
import { map } from "rxjs/operators";
import { UploadService } from "../services/upload.service";
import { DatabaseManager } from "../database/database-manager";
import { Class } from "../model/Class";
import { DatetimeService } from "../util/datetime.service";
import { AuthService } from "../services/auth.service";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { AlertService } from "../services/alert.service";
import { MatDialog } from "@angular/material";
import { EditVideoComponent } from "../edit-video/edit-video.component";
import { AnimationOptions } from "ngx-lottie";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit {
  video: video;
  videoList: video[] = [];

  teacherId: string;
  termSubscriber: Subscription;
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];
  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : null;
  subjectId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].subjectId
      : null;
  public readonly NO_THUMBNAIL = "./../assets/templates/video_thumb.jpg";

  constructor(
    private uploadService: UploadService,
    private title: Title,
    public dataService: DataService,
    private dbManager: DatabaseManager,
    private dateTime: DatetimeService,
    public auth: AuthService,
    private alertService: AlertService,
    public dialog: MatDialog,
    public profileValidator: ProfileValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Video | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
          }
          if (param["subjectId"]) {
            this.subjectId = param["subjectId"];
          }

          // ////console.log("tab",this.teacherId,this.classId)

          if (this.classId && this.teacherId) {
            this.getVideos();
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.termSubscriber.unsubscribe();
  }

  onSelectChange() {
    if (this.classId) {
      this.subjectId = this.activeClassList.find(
        (x) => x.classId == this.classId
      ).subjectId;

      this.route.params["classId"] = this.classId;
      this.route.params["subjectId"] = this.subjectId;
      this.router.navigate(["/video", this.classId, this.subjectId]);
    }
  }

  getVideos() {
    this.dbManager
      .getAllVideos(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        this.videoList = data;
        ////console.log("video list ", this.videoList);
      });
  }

  getTime(time) {
    return this.dateTime.changeTimeFormat(time);
  }

  deleteVideo(id, video: video) {
    var table = document.getElementById("videoTable") as HTMLTableElement;
    var el = document.getElementById(id) as HTMLTableRowElement;

    this.alertService
      .showAlert(
        "Do you want to delete this video?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        // ////console.log(result)
        switch (result) {
          case DialogResult.yes:
            table.deleteRow(el.rowIndex);

            this.uploadService.deleteVideoFileUpload(video); //delete video
            break;

          case DialogResult.close:
            break;
        }
      });
  }

  editVideo(video: video) {
    if (this.profileValidator.isProfileValid()) {
      this.dialog.open(EditVideoComponent, {
        data: {
          video: video,
        },
      });
    } else {
      this.alertService.showAlert(
        "profile not valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_video.json",
    loop: false,
  };

  showProfileAlert() {
    this.alertService.showAlert(
      "profile not valid!",
      MessageButtons.ok,
      MessageType.warning
    );
  }

  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }
}
