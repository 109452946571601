import { Component, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { AnimationItem } from "lottie-web";
import { SigninComponent } from "./signin/signin.component";
import { ProfileValidatorService } from "./services/profile-validator.service";
import { NotificationService } from "./services/notification.service";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  private animationItem: AnimationItem;
  router: Router;
  // title = 'digi01';
  ///bell vr
  bellIconCol: string = "#2e3131";
  message: string = "";
  navitem = 1;
  isMenuHidden = false;
  isSignedIn = false;
  isChecked = false;

  constructor(
    public dialog: MatDialog,
    private route: Router,
    public authservice: AuthService,
    public profileValidator: ProfileValidatorService,
    private notifyService: NotificationService,
    private ngZone: NgZone
  ) {
    this.notifyService.notificationObserver.subscribe((data) => {
      this.bellIconCol = data.bellColor;
      this.message = data.message;
    });
  }
  ngOnInit() {
    setTimeout(() => {
      const location = window.location.href;
      if (location.includes("my-account")) {
        this.navitem = 2;
      } else if (location.includes("live-classes")) {
        this.navitem = 2;
      } else if (location.includes("notes")) {
        this.navitem = 3;
      } else if (location.includes("quizes")) {
        this.navitem = 4;
      } else if (location.includes("evaluation")) {
        this.navitem = 5;
      } else if (location.includes("video")) {
        this.navitem = 6;
      } else if (location.includes("insight")) {
        this.navitem = 8;
      } else if (location.includes("payments")) {
        this.navitem = 9;
      } else if (location.includes("notices")) {
        this.navitem = 13;
      } else if (location.includes("inquiry")) {
        this.navitem = 10;
      } else if (location.includes("advertising")) {
        this.navitem = 11;
      } else if (location.includes("digi-conference")) {
        this.navitem = 12;
      }
    }, 50);
    console.log("hello");
  }
  //starting lottie config
  animOption: AnimationOptions;
  option1: AnimationOptions = {
    path: "/assets/lti/lti_nonotification.json",
    loop: false,
  };

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.play();
  }

  playAnimation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.play();
    });
    this.animOption = this.option1;
  }

  pauseAnimation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.stop();
    });
  }

  hideMenu(): void {
    if (this.isMenuHidden) {
      this.isMenuHidden = false;
    } else {
      this.isMenuHidden = true;
    }
  }

  changeNav(id): void {
    this.navitem = id;

    setTimeout(() => {
      const location = window.location.href;
      if (location.includes("my-account")) {
        this.navitem = 2;
      } else if (location.includes("live-classes")) {
        this.navitem = 2;
      } else if (location.includes("notes")) {
        this.navitem = 3;
      } else if (location.includes("quizes")) {
        this.navitem = 4;
      } else if (location.includes("evaluation")) {
        this.navitem = 5;
      } else if (location.includes("video")) {
        this.navitem = 6;
      } else if (location.includes("insight")) {
        this.navitem = 8;
      } else if (location.includes("payments")) {
        this.navitem = 9;
      } else if (location.includes("notices")) {
        this.navitem = 13;
      } else if (location.includes("inquiry")) {
        this.navitem = 10;
      } else if (location.includes("advertising")) {
        this.navitem = 11;
      } else if (location.includes("digi-conference")) {
        this.navitem = 12;
      }
    }, 50);
    switch (id) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      case 7:
        window.open(
          "/videoupload/" + this.authservice.userData.uid + "/" + 3,
          "_blank"
        );
        break;
      default:
    }
  }

  signedIn(): void {
    if (this.isSignedIn) {
      this.isSignedIn = false;
    } else {
      this.isSignedIn = true;
    }
  }

  oncheck(): string {
    if (this.isChecked) {
      return "on";
    } else {
      return "off";
    }
  }

  isDark() {
    let daark = {
      light_default: !this.isChecked,
      dark_default: this.isChecked,
    };
    return daark;
  }

  openTab() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/videoupload"])
    );

    ////console.log(url);
    window.open(url);
  }

  openSignIn(): void {
    this.dialog.open(SigninComponent);
  }

  getFirstName(fullName: string): string {
    var firstName = fullName.split(" ", 1);
    return firstName[0];
  }
}
