import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { DateAdapter, MatDialog } from "@angular/material";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { DatabaseManager } from "../database/database-manager";
import { ClassAd } from "../model/classAd";
import { AuthService } from "../services/auth.service";
import { AlertService } from "../services/alert.service";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { UploadService } from "../services/upload.service";
@Component({
  selector: "app-advertising",
  templateUrl: "./advertising.component.html",
  styleUrls: ["./advertising.component.scss"],
})
export class AdvertisingComponent implements OnInit {
  option: AnimationOptions = {
    path: "/assets/lti/lti_not-available-feature.json",
    loop: false,
  };

  advertisementList: ClassAd[] = [];

  constructor(
    private title: Title,
    private dateService: DatePipe,
    private db: DatabaseManager,
    private auth: AuthService,
    private router: Router,
    private alert: AlertService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Advertising | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then(() => {
      this.db.getAdvertisements(this.auth.userData.uid).then((res) => {
        this.advertisementList = res;
      });
    });
  }

  addURL(addURL: string) {
    return addURL + "#t=4";
  }
  expirationDate(expirationDate: string) {
    return new Date(expirationDate).toDateString();
  }
  editad(index: number, ad: ClassAd) {
    //console.log(index);
    this.router.navigate(["/create-advertisement/edit", ad.classAdId]);
  }

  paynow(index: number, ad: ClassAd) {
    this.router.navigate([
      "/create-advertisement/edit/",
      ad.classAdId,
      "paynow",
    ]);
  }

  deleteRow(ad: ClassAd) {
    this.alert
      .showAlert(
        "Do you need to delete this advertisement?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((res) => {
        if (res === "yes") {
          this.db.deleteAdvertisement(ad.classAdId, ad.teacherId);
          this.ngOnInit();
          this.uploadService.deleteAdvertisementMedia(
            ad.teacherId,
            ad.classAdId,
            true
          );
        }
      });
  }
}
