import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DatabaseManager } from "../database/database-manager";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Class } from "../model/Class";
import { Constants } from "../model/Constants";
import { LiveClass } from "../model/LiveClass";
import { AlertService } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatetimeService } from "../util/datetime.service";

@Component({
  selector: "app-create-live-class",
  templateUrl: "./create-live-class.component.html",
  styleUrls: ["./create-live-class.component.scss"],
})
export class CreateLiveClassComponent implements OnInit {
  liveClass: LiveClass = new LiveClass();
  activeClassList: Class[] = this.dataService.getActiveClassList()
    ? this.dataService.getActiveClassList()
    : [];

  // classId: string=this.dataService.getActiveClassList().length>0?this.dataService.getActiveClassList()[0].classId:"" ;
  startTime: { hour: number; minute: number };
  endTime: { hour: number; minute: number };
  formSubmitted: boolean = false;

  public readonly editType = Constants.SECOND_TYPE;
  public readonly newType = Constants.FIRST_TYPE;

  constructor(
    public dialog: MatDialog,
    public uploadService: UploadService,
    private dbManager: DatabaseManager,
    private datetime: DatetimeService,
    private dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateLiveClassComponent>,
    private alertService: AlertService,
    private auth: AuthService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    ////console.log(this.data);
    this.activeClassList = this.dataService
      .getClassList()
      .filter((x) => x.classStatus == 1);

    //new part for get selected class
    ////console.log("test" + this.data.selectedClass)
    this.liveClass.classId = this.data.selectedClass;

    if (this.data.type == Constants.SECOND_TYPE) {
      this.liveClass = this.data.selectedClass;
      this.startTime = this.datetime.getObjectTime(this.liveClass.startingTime);
      this.endTime = this.datetime.getObjectTime(this.liveClass.endingTime);
      // this.liveClass.classId = this.data.liveId.selectedClass;
    }
  }
  createLiveClass() {
    this.formSubmitted = true;
    // //console.log(this.isFormValid())

    if (this.isFormValid()) {
      this.liveClass.role = 0;
      this.liveClass.uploadedTime = this.datepipe.transform(
        new Date(),
        "HH:mm:ss"
      );
      this.liveClass.uploadedDate = this.datepipe.transform(
        new Date(),
        "MMM d, y"
      );
      this.liveClass.teacherId = this.auth.userData.uid;
      this.liveClass.teacherPic =
        this.dataService.getTeacherDetails().profilePic;

      var cls = this.dataService
        .getClassList()
        .find((x) => x.classId == this.liveClass.classId);
      this.liveClass.subjectName = `${cls.subjectName}`;
      this.liveClass.gradeName = `${cls.gradeName}`;
      this.dbManager
        .addTeacherLiveClass(
          this.liveClass,
          this.dataService.getTeacherDetails().teacherId
        )
        .then((res) => {
          this.close();
        });
    } else {
    }
  }

  saveLiveClass() {
    this.formSubmitted = true;
    ////console.log(this.isFormValid())

    if (this.isFormValid()) {
      this.dbManager
        .updateLiveClass(
          this.liveClass,
          this.dataService.getTeacherDetails().teacherId
        )
        .then((res) => {
          this.close();
        });
    } else {
    }
  }

  deleteLiveClass() {
    this.alertService
      .showAlert(
        "Do you want to delete ?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            this.dbManager
              .deleteLiveClass(
                this.liveClass,
                this.dataService.getTeacherDetails().teacherId
              )
              .then((res) => {
                this.close();
              });
            break;

          case DialogResult.close:
            break;
        }
      });
  }
  close() {
    this.dialogRef.close();
  }

  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  isFormValid() {
    if (
      this.liveClass.classId &&
      this.liveClass.classTitle &&
      this.liveClass.meetingNumber &&
      this.liveClass.day &&
      this.liveClass.password &&
      this.liveClass.startingTime &&
      this.liveClass.endingTime
    ) {
      //  if(this.liveClass.extraClass ){
      //       if(this.liveClass.extraClassDate){
      if (this.liveClass.extraClass) {
        if (this.liveClass.extraClassDate) {
          ////console.log(this.liveClass.day);
          this.liveClass.day = this.datetime.getStringDay(
            this.liveClass.extraClassDate
          );
          ////console.log(this.liveClass.day);
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  stepValid1() {
    if (this.liveClass.classTitle && this.liveClass.classId) {
      return true;
    }
    return false;
  }

  stepValid2() {
    if (this.liveClass.startingTime && this.liveClass.endingTime) {
      if (this.liveClass.extraClass) {
        if (this.liveClass.extraClassDate) {
          return true;
        }
        return false;
      } else if (this.liveClass.day) {
        return true;
      }
    }
    return false;
  }
}
