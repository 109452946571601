import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../services/auth.service";
import { LoginState } from "../services/user";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  registerType: string = "signin";
  private timeLeft: number = 60;
  private counterId;

  constructor(
    public authService: AuthService,
    public dialogRef: MatDialogRef<SigninComponent>
  ) {
    this.startAttempt();
  }

  ngOnInit(): void {
    this.authService.clearAuthMessage();
  }

  loginWithGoogle() {
    this.authService.GoogleAuth();
  }

  changeRegType(type: string) {
    this.authService.clearAuthMessage();
    this.registerType = type;
  }

  startAttempt() {
    this.counterId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
      if (this.authService.loginState === LoginState.VerfiedLogin) {
        clearInterval(this.counterId);
        this.onClose(true);
      } else if (
        this.authService.loginState === LoginState.notVerified &&
        this.registerType === "signin"
      ) {
        clearInterval(this.counterId);
        this.onClose(true);
      }
    }, 1000);
  }

  onClose(parm?: boolean): void {
    this.dialogRef.close(parm);
  }
}
