import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-payment-cards",
  templateUrl: "./payment-cards.component.html",
  styleUrls: ["./payment-cards.component.scss"],
})
export class PaymentCardsComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit() {
    this.title.setTitle("Payments | Tutor Digi Panthiya ");
  }
}
