import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Class } from "../model/Class";
import { video } from "../model/video";
import { AlertService } from "../services/alert.service";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatetimeService } from "../util/datetime.service";

@Component({
  selector: "app-edit-video",
  templateUrl: "./edit-video.component.html",
  styleUrls: ["./edit-video.component.scss"],
})
export class EditVideoComponent implements OnInit {
  classId;
  video: video = new video();
  activeClassList: Class[] = this.dataService.getActiveClassList()
    ? this.dataService.getActiveClassList()
    : [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public uploadService: UploadService,
    public datepipe: DatePipe,
    public dataService: DataService,
    private dateTime: DatetimeService,
    private dbManager: DatabaseManager,
    private dialogRef: MatDialogRef<EditVideoComponent>,
    private alertService: AlertService
  ) {
    ////console.log(data);
  }

  ngOnInit() {
    this.video = this.data.video;
    this.classId = this.video.classId;
  }

  getTime(time) {
    return this.dateTime.changeTimeFormat(time);
  }

  selectClass(event) {
    ////console.log(event);
    this.video.gradeId = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.classId).gradeId;

    this.video.gradeName = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.classId).gradeName;

    this.video.subjectId = this.dataService ///use this if we need
      .getActiveClassList()
      .find((x) => x.classId == this.classId).subjectId;

    ////console.log(event.target.value, this);
  }
  saveVideo() {
    ////console.log("video", this.video);
    if (
      this.video.videoTitle &&
      this.video.videoDescription &&
      this.video.classId &&
      this.video.gradeId &&
      this.video.subjectId
    ) {
      this.dbManager.saveVideo(this.video).then((res) => {
        this.close();
      });
    } else {
      this.alertService.showAlert(
        "please fill all fields",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  close() {
    this.dialogRef.close();
  }
}
