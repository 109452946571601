import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AnimationOptions } from "ngx-lottie";
import { DatabaseManager } from "../database/database-manager";
import { Notice } from "../model/notice";
import { AuthService } from "../services/auth.service";
import { DatetimeService } from '../util/datetime.service';

@Component({
  selector: "app-notices",
  templateUrl: "./notices.component.html",
  styleUrls: ["./notices.component.scss"],
})
export class NoticesComponent implements OnInit {
  expandId = -1;
  hideDEL: number = -1;
  myLineHeight = 0;
  teacherNotices: Notice[] = [];
  option: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };

  constructor(
    private dataservice: DatabaseManager,
    private auth: AuthService, private datetime: DatetimeService,
    private title: Title
  ) {
    //wait until user connecting with the server
    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.loadNotices();
      }
    });
  }

  ngOnInit(): void {
    // set title & meta
    this.title.setTitle("Notices | Tutor - Digi Panthiya");
  }

  loadNotices() {
    this.dataservice
      .getTeacherNotices(this.auth.userData.uid)
      .then((elements) => {
        // //console.log(elements);
        this.teacherNotices = elements;
      });
  }

  showDEL(id: number) {
    this.hideDEL = id;
  }

  removeDEL() {
    this.hideDEL = -1;
  }

  deleteNotice(id: string) {
    this.dataservice.deleteTeacherNotice(id, this.auth.userData.uid);
    this.loadNotices();
  }

  getTime(time: string) {
    var data = '--';
    try {
      data = this.datetime.get12HrsTime(time);
    } catch (e) {
    }
    return data;
  }
}
