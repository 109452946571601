import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { CancelUploadEvaluationComponent } from "../cancel-upload-evaluation/cancel-upload-evaluation.component";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Marks } from "../model/Marks";
import { Quiz } from "../model/quiz";
import { AlertService } from "../services/alert.service";
import { UploadService } from "../services/upload.service";
import * as moment from "moment";

@Component({
  selector: "app-evaluation-single",
  templateUrl: "./evaluation-single.component.html",
  styleUrls: ["./evaluation-single.component.scss"],
})
export class EvaluationSingleComponent implements OnInit {
  isUploaded: boolean = false;
  selectedFiles: FileList;
  isFileChanged: boolean;
  marks: Marks = new Marks();
  progress: { percentage: number } = { percentage: 0 };
  uploadType: number = 4; //for  marking scheme
  type: number; // <!-- 1 for evaluted quiz && 0 for non evaluted -->
  quiz: Quiz;
  teacherId: any;

  constructor(
    private dbManager: DatabaseManager,
    private uploadService: UploadService,
    public dialog: MatDialog,
    private alertService: AlertService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.marks = this.data.marks;
    this.quiz = this.data.quiz;
    this.type = this.data.type;
    this.teacherId = this.data.teacherId;
  }

  addMark() {
    if (this.marks.marks) {
      this.marks.isEvaluated = true;
      const today = moment(new Date());
      this.marks.markedDate = today.format("YYYY-MM-DD");
      this.marks.markedTime = today.format("HH:mm");
      ////console.log(this.marks)

      this.dbManager.saveQuizMarking(
        this.marks,
        this.quiz.classId,
        this.quiz.quizId,
        this.type,
        this.teacherId
      );
    } else {
      this.alertService.showAlert(
        "please enter marks",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  /* addScheme(event) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      this.isUploaded = true;
      this.marks.fileName = event.target.files[0].name;
      if (this.dialogAction == 1) {
        this.marks.file = this.selectedFiles.item(0);
        this.selectedFiles = undefined;
        this.isFileChanged = true;
        ////console.log(this.isFileChanged);
      }
    }
  } */

  /* upload() {
    const file = this.selectedFiles.item(0);
    this.marks.file = file;
    this.selectedFiles = undefined;

    this.uploadService.pushFileToStorage(
      this.marks,
      this.progress,
      this.uploadType
    );
  } */

  /* save() {
    if (this.isFileChanged) {
      ////console.log("deleting old file");
      this.uploadService.deleteQuizMarkingSchemeFile(this.marks);
      this.uploadService.pushFileToStorage(
        this.marks,
        this.progress,
        this.uploadType
      );
    } else {
      this.dbManager.saveQuizMarking(this.marks);
    }
  } */

  uploadMarksState: number = 1;

  uploadMarksStateChange(state: string) {
    if (state === "first") {
      this.uploadMarksState = 1;
    } else if (state === "second") {
      this.uploadMarksState = 2;
    } else if (state === "third") {
      this.uploadMarksState = 3;
    }
  }

  openCancelDownload() {
    this.dialog.open(CancelUploadEvaluationComponent);
  }
}
