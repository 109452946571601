export class video {
  videoId: string;
  videoTitle: string;
  videoUrl: string;
  subjectName: string
  videoDescription: string;
  teacherId: string;
  teacherPic: string;
  teacherName: string;
  discussionId: string;
  uploadedDate: string;
  uploadedTime: string;
  classId: string;
  subjectId: string;
  gradeId: string;
  gradeName: string;
  likes: number;
  dislikes: number;
  views: number;
  bookmarkArray: any[];
  key: string;
  fileName: string;
  file: File;
  srhVideoTitle: string;
  srhSubjectName: string;
  srhTeacherName: string;
  srhTimestamp: string;
  videoThumbnail: string;
}
