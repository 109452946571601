import { Time } from "@angular/common";

export class Quiz {
  quizId: string;
  quizTitle: string = null;
  quizDescription: string = null;
  uploadedDate: string = null;
  uploadTime: string = null;
  startDate: Date = null;
  endDate: Date = null;
  endTime: string = null;
  startTime: string = null;
  type: string = null;
  mcqChoise: number = null;
  noQuestions: number = null;
  fileUrl: string;
  fileName: string = null;
  file: File = null;
  answers: [];
  classId: string;
  subjectName: string = null;
  gradeName: string = null;
  durationHours: number = null;
  durationMinutes: number = null;
  teacherId: string;
  teacherPic: string;
  teacherName: string;
  teacherEvaluated: boolean = false;

  /*  constructor(
    file: File,
    id,
    date,
    time,
    title,
    description,
    type,
    mcqChoice,
    questionNo,
    startDate,
    endDate,
    mcqAnswers,
    subjectId,
    classId,
    teacherId,
    durationMinutes,
    durationHours
  ) {
    this.file = file;
    this.quizId = id;
    this.uploadedDate = date;
    this.uploadTime = time;
    this.quizTitle = title;
    this.quizDescription = description;
    this.type = type;
    this.mcqChoise = mcqChoice;
    this.noQuestions = questionNo;
    this.startDate = startDate;
    this.endDate = endDate;
    this.mcqAnswers = mcqAnswers;
    this.classId = classId;
    this.subjectId = subjectId;
    this.teacherId = teacherId;
    (this.durationMinutes = durationMinutes),
      (this.durationHours = durationHours);
  } */
}
