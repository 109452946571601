import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgxImageCompressService } from "ngx-image-compress";
import { Observable } from "rxjs";
import { DatabaseManager } from "../database/database-manager";
import {
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { Class } from "../model/Class";
import { ClassAd } from "../model/classAd";
import { ClassAdPayment } from "../model/classAdPayment";
import { Grade } from "../model/Grade";
import { Teacher } from "../model/teacher";
import { AlertService } from "../services/alert.service";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";

declare var payhere;

@Component({
  selector: "app-create-advertising",
  templateUrl: "./create-advertising.component.html",
  styleUrls: ["./create-advertising.component.scss"],
})
export class CreateAdvertisingComponent implements OnInit {
  //private readonly MERCHANT_ID: string = "215335";
  private readonly MERCHANT_ID: string = "1220895"; // sandbox key

  @ViewChild("stepper", { static: false }) public step: Observable<MatStepper>;
  paymentOptionList: any;

  monthmill = 2629800000;
  activeClassList: Class[] = this.dataService.getActiveClassList()
    ? this.dataService.getActiveClassList()
    : [];
  adForm: FormGroup;
  selectedImage: any;
  displayImage: any;
  classIdFromList: number;
  gradeList: Grade[] = [];
  orderId: string;
  ad: ClassAd = {
    adTitle: "",
    gradeId: "",
    gradeName: "",
    teacherId: "",
    adFileType: 0,
    adFileUrl: "",
    classAdId: "",
    expirationDate: 0,
    expirationDateString: "",
    addApprovalState: 0,
    isPaid: false,
    paymentStatus: 0,
    paymentId: "",
    isBankPayment: true,
    months: 0,
    ammount: 0,
  };
  adType: number = 0;
  url: string | ArrayBuffer;
  file: any;
  fileUpload: any;
  isBankpayemnt: boolean = true;
  payherePaid: boolean = false;

  progress: { percentage: number } = { percentage: 0 };
  bnkslipprogress: { percentage: number } = { percentage: 0 };
  storagesize: number = 0;
  total: number = 0;
  bnkslipfile: any;
  edit: boolean = false;
  editAdId: string = "";
  panelOpenState = false;
  sent: boolean = true;
  constructor(
    private title: Title,
    public dataService: DataService,
    private formBuilder: FormBuilder,
    private db: DatabaseManager,
    private compress: NgxImageCompressService,
    private authService: AuthService,
    private uploadService: UploadService,
    private route: ActivatedRoute,
    private alert: AlertService
  ) {
    const currentDate = new Date();
    let currentDatee = new Date(
      currentDate.setMonth(currentDate.getMonth() + 6)
    );

    //console.log(currentDatee.toLocaleString());
    this.buildAdForm();
    this.authService.isUserAvailable().then(() => {
      if (authService.userData) {
        this.ad = {
          adTitle: "",
          gradeId: "",
          gradeName: "",
          teacherId: authService.userData.uid,
          adFileType: 0,
          adFileUrl: "",
          classAdId: db.generateKey(),
          expirationDate: Date.now(),
          expirationDateString: "",
          addApprovalState: 0,
          isPaid: false,
          paymentStatus: 0,
          paymentId: "",
          isBankPayment: true,
          months: 0,
          ammount: 0,
        };
      }
    });
  }

  ngOnInit() {
    this.title.setTitle("Create a Advertisement | Tutor - Digi Panthiya");

    this.route.paramMap.subscribe((res) => {
      if (res.has("type")) {
        if (res.has("paynow")) this.sent = false;
        this.db.getAdvertisementById(res.get("id")).then((res: ClassAd) => {
          this.edit = true;
          this.ad = res;
          this.total = res.ammount;
          this.adForm = this.formBuilder.group({
            classId: this.ad.gradeId,
            adTitle: this.ad.adTitle,
            expirationDate: "",
          });

          // //console.log(this.step.selectedIndex);
        });
      } else {
        this.db
          .getGrades()
          .get()
          .subscribe((res) => {
            let grades: any = <Array<any>>res.docs.map((docs) => docs.data());
            this.gradeList = grades as Grade[];
          });
      }
    });

    this.db.getAdvertisePricing().then((res: any) => {
      this.paymentOptionList = res;
    });
  }

  buildAdForm() {
    this.adForm = this.formBuilder.group({
      classId: new FormControl("", Validators.compose([Validators.required])),
      adTitle: new FormControl("", Validators.compose([Validators.required])),
      expirationDate: "",
    });
  }

  bankbtn() {
    this.isBankpayemnt = true;
    this.ad.isBankPayment = true;
  }

  bnkslip(event: any) {
    this.bnkslipfile = event.target.files[0];
  }

  payherebtn() {
    this.isBankpayemnt = false;
    this.ad.isBankPayment = false;
  }

  ispay(stepper: MatStepper) {
    if (!this.sent && this.edit) {
      stepper.next();
      stepper.selectedIndex = 1;

      if (stepper.selectedIndex == 2) {
        this.sent = !this.sent;
      }
    }
  }

  selectFile(files: FileList, stepper: MatStepper) {
    if (files) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);

      switch (this.adType) {
        case 0:
          reader.onload = (_event) => {
            this.file = files[0];
            if (files[0].size < 7 * 1024 * 1024 && files[0].size > 200 * 1024) {
              this.storagesize = files[0].size;
              this.compress
                .compressFile(reader.result, -1, 50, 50)
                .then((result) => {
                  this.selectedImage = this.base64ToImage(
                    result,
                    files[0].name
                  );
                  this.displayImage = result;
                });
              this.selectMonth(1);
              stepper.next();
            } else if (files[0].size < 200 * 1024) {
              this.selectedImage = files[0];
              this.displayImage = reader.result;
              this.selectMonth(1);
              stepper.next();
            } else {
              this.alert.showAlert(
                "Image size should be less than 7MB!",
                MessageButtons.ok,
                MessageType.warning
              );
            }
          };
          break;
        case 1:
          const file = files && files[0];
          if (file.size < 30000000) {
            this.storagesize = file.size;
            var reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (event) => {
              this.url = (<FileReader>event.target).result;
              const media = new Audio(reader.result as string);
              this.file = file;
              // console.log(files);
              media.onloadeddata = () => {
                if (media.duration >= 20) {
                  this.file = undefined;
                  this.url = undefined;
                  this.alert.showAlert(
                    "Video duration should be less than 20s",
                    MessageButtons.ok,
                    MessageType.warning
                  );
                } else {
                  this.selectMonth(1);
                  stepper.next();
                }
              };
            };
          } else {
            this.alert.showAlert(
              "File size should be less than 30MB!",
              MessageButtons.ok,
              MessageType.warning
            );
          }
          break;
        default:
          console.error("Uncaught Error: Operation Completed Successfully");
          break;
      }
    }
  }

  selectFilevalid() {
    if (this.file) {
      return true;
    } else {
      return false;
    }
  }

  base64ToImage(dataURI, fileName) {
    const fileDate = dataURI.split(",");
    // const mime = fileDate[0].match(/:(.*?);/)[1];
    const byteString = atob(fileDate[1]);
    const bytes = new Array(byteString.length);
    for (var x = 0; x < byteString.length; x++) {
      bytes[x] = byteString.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], { type: "image/jpeg" });
    const file = new File([blob], fileName, {
      type: "image/jpeg",
      lastModified: Date.now(),
    });
    return file;
  }

  selectClass(evt: any) {
    this.classIdFromList = evt.target.value;

    if (parseInt(evt.target.value) >= 0) {
      this.ad.gradeId = this.gradeList[evt.target.value].gradeId;
      this.ad.gradeName = this.gradeList[evt.target.value].gradeName;
    } else {
      this.ad.gradeId = "All";
      this.ad.gradeName = "All";
    }
  }

  selectMonth(value: any) {
    this.ad.expirationDate = Date.now() + this.monthmill * value;
    const currentDate = new Date();
    let currentDatee = new Date(
      currentDate.setMonth(currentDate.getMonth() + parseInt(value))
    );
    this.ad.expirationDateString = currentDatee.toLocaleString();
    this.ad.months = parseInt(value);
    if (this.adType == 0) {
      console.log(this.paymentOptionList);
      this.total = parseInt(this.paymentOptionList.image[value.toString()]);
    } else {
      this.total = parseInt(this.paymentOptionList.video[value.toString()]);
    }
  }

  changeAdType(type) {
    this.adType = type;
    this.total = undefined;
  }

  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "LKR",
    });
    return formatter.format(value);
  }

  upload() {
    let { adTitle } = this.adForm.value;
    this.ad.adTitle = adTitle;
    if (!this.edit) {
      if (this.total) {
        this.ad.paymentId = this.db.generateKey();
        this.ad.adFileType = this.adType;
        this.ad.ammount = this.total;
        this.db.createAdvertisement(this.ad).then(() => {
          this.fileUpload = {
            file: this.file,
            teacherId: this.authService.userData.uid,
            classAdId: this.ad.classAdId,
            type: this.adType == 0 ? "Image" : "Video",
          };

          this.uploadService.pushFileToStorage(
            this.fileUpload,
            this.progress,
            9
          );
        });
      } else {
        this.alert.showAlert(
          "Please choose a duration for advertisement",
          MessageButtons.ok,
          MessageType.warning
        );
      }
    } else {
      this.db.updateAdvertisement(this.ad.classAdId, this.ad);
      this.progress.percentage = 100;
    }
  }

  finishPayment() {
    if (this.isBankpayemnt) {
      this.payNow();
    } else {
      this.payViaPayHere(this.ad.paymentId);
    }
  }

  payNow() {
    var payment: ClassAdPayment = {
      classId: this.ad.gradeId,
      months: this.ad.months,
      ammount: this.ad.ammount,
      isBankpayemnt: this.isBankpayemnt,
      bnkslip: null,
      classAdId: this.ad.classAdId,
      teacherId: this.authService.userData.uid,
      paymentId: this.ad.paymentId,
      status: 0,
    };

    if (this.isBankpayemnt) {
      let bnkFileUpload: any = {
        teacherId: this.ad.teacherId,
        classAdId: this.ad.classAdId,
        file: this.bnkslipfile,
      };

      if (this.bnkslipfile) {
        this.db.advertisePayment(payment).then(() => {
          this.uploadService.pushFileToStorage(
            bnkFileUpload,
            this.bnkslipprogress,
            10
          );
        });
      } else {
        this.alert.showAlert(
          "Please upload a payment slip",
          MessageButtons.ok,
          MessageType.warning
        );
      }
    } else {
      this.db.updateAdvertisementVariable(this.ad.classAdId, "isPaid", true);
      this.db.updateAdvertisementVariable(
        this.ad.classAdId,
        "isBankPayment",
        false
      );
      this.db.advertisePayment(payment);
      this.payherePaid = true;
    }
  }

  payViaPayHere(orderId: string) {
    var teacher: Teacher = this.dataService.getTeacherDetails();
    // my sand 1214048(payhere sandbox)
    var amount = this.total;
    var payment = {
      sandbox: true,
      merchant_id: this.MERCHANT_ID,
      return_url: "https://tutor.digipanthiya.com/advertising",
      cancel_url: "https://tutor.digipanthiya.com/advertising",
      notify_url:
        "https://us-central1-digipanthiya-bc66f.cloudfunctions.net/VerifyAdPayment",
      order_id: orderId,
      items: "Advertisement Payment - Digi Panthiya",
      amount: amount,
      currency: "LKR",
      first_name: teacher.teacherName,
      last_name: "",
      email: teacher.teacherEmail,
      phone: teacher.teacherTp1,
      address: teacher.teacherAddress,
      city: teacher.city,
      country: "Sri Lanka",
      delivery_address: "No",
      delivery_city: "No",
      delivery_country: "Sri Lanka",
      custom_1: this.authService.userData.uid,
      custom_2: this.ad.classAdId,
    };

    // Called when user completed the payment. It can be a successful payment or failure
    payhere.onCompleted = () => {
      this.payNow();
    };

    // Called when user closes the payment without completing
    payhere.onDismissed = () => {
      //Note: Prompt user to pay again or show an error page
      console.log("Payment dismissed");
      this.alert.showAlert(
        "Payment dismissed",
        MessageButtons.ok,
        MessageType.warning
      );
    };

    // Called when error happens when initializing payment such as invalid parameters
    payhere.onError = (error) => {
      // Note: show an error page
      console.log("Error:" + error);
      this.alert.showAlert(
        "Payment initializing failed. Please try again.",
        MessageButtons.ok,
        MessageType.warning
      );
    };
    payhere.startPayment(payment);
  }
}
