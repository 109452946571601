import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export enum MessageButtons {
  resend_verification_email,
  ok,
  YesNo,
  upload_remove,
}

export enum MessageType {
  wow = "ALMOST THERE...",
  accessBlocked = "OOPS!",
  warning = "UH-OH!",
  Delete = "Delete?",
  Done = "Done!",
  Success = "Successful",
  AccountCreated = "Account Created!",
}

export interface MsgBoxData {
  message: string;
  messageButtons: MessageButtons;
  messageType: MessageType;
}

export enum DialogResult {
  close = "close",
  ok = "ok",
  yes = "yes",
  resend_verification_email = "Resend Verification Email",
  upload = "upload",
  remove = "remove",
}

@Component({
  selector: "app-messagebox",
  templateUrl: "./messagebox.component.html",
  styleUrls: ["./messagebox.component.scss"],
})
export class MessageboxComponent implements OnInit {
  0: MessageButtons = MessageButtons.resend_verification_email;
  1: MessageButtons = MessageButtons.ok;
  2: MessageButtons = MessageButtons.YesNo;
  3: MessageButtons = MessageButtons.upload_remove;

  messageTypes = MessageType;
  //form var
  RESULT_OK: DialogResult = DialogResult.ok;
  RESULT_YES: DialogResult = DialogResult.yes;
  RESULT_CLOSE: DialogResult = DialogResult.close;
  RESEND_VERIFY_EMAIL: DialogResult = DialogResult.resend_verification_email;
  RESULT_UPLOAD: DialogResult = DialogResult.upload;
  RESULT_REMOVE: DialogResult = DialogResult.remove;

  constructor(
    public dialogRef: MatDialogRef<MessageboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MsgBoxData
  ) {
    ////console.log('data',data)
  }

  ngOnInit() {}

  onClose(parm?: boolean): void {
    this.dialogRef.close(DialogResult.close);
  }

  setResult(result: DialogResult) {
    this.dialogRef.close(result);
  }

  clickOk() {
    this.dialogRef.close(DialogResult.ok);
  }
}
