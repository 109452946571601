import { Component, OnInit, ViewChild } from "@angular/core";
import { UploadService } from "../services/upload.service";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../services/data.service";
import { DatabaseManager } from "../database/database-manager";
import { AuthService } from "../services/auth.service";
import { DatetimeService } from "../util/datetime.service";
import { AlertService } from "../services/alert.service";
import { Quiz } from "../model/quiz";
import { MatTabGroup } from "@angular/material";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { Class } from "../model/Class";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-evaluation",
  templateUrl: "./evaluation.component.html",
  styleUrls: ["./evaluation.component.scss"],
})
export class EvaluationComponent implements OnInit {
  @ViewChild(MatTabGroup, { static: false }) tabRef: MatTabGroup;
  teacherNonEvaluatedQuiz: Quiz[] = [];
  teacherEvaluatedQuiz: Quiz[] = [];
  teacherId: any;
  tab: number = 0;
  termSubscriber: Subscription;
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];
  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : "";

  constructor(
    public dataService: DataService,
    private title: Title,
    private dbManager: DatabaseManager,
    private auth: AuthService,
    private datetime: DatetimeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Evaluation | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
            ////console.log(this.classId)
          }
          if (param["tab"]) {
            this.tab = parseInt(param["tab"]);
            ////console.log(this.tab)
          }
          ////console.log("tab",this.tab,this.teacherId,this.classId)

          if (this.classId && this.teacherId) {
            this.getQuiz();
          }
        });
      }
    });
  }
  ngOnDestroy() {
    this.termSubscriber.unsubscribe();
  }

  getUnEvaluatedQuiz() {
    this.dbManager
      .getTeacherQuiz(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes
            .map((c) => c.payload.doc.data())
            .filter((x) => {
              return (
                !this.dataService.isQuizNotPassed(x) && !x.teacherEvaluated
              );
            })
        )
      )
      .subscribe((data) => {
        this.teacherNonEvaluatedQuiz = data;
        ////console.log("non evaluated list ", this.teacherNonEvaluatedQuiz);
      });
  }

  getEvaluatedQuiz() {
    this.dbManager
      .getTeacherQuiz(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes
            .map((c) => c.payload.doc.data())
            .filter((x) => {
              return !this.dataService.isQuizNotPassed(x) && x.teacherEvaluated;
            })
        )
      )
      .subscribe((data) => {
        this.teacherEvaluatedQuiz = data;
        ////console.log("EvaluatedQuiz list ", this.teacherEvaluatedQuiz);
      });
  }

  onClickEvaluatedQuiz() {
    // [routerLink]="['/evaluationDetails',index"
    this.dataService.setEvaluatedQuizList(this.teacherNonEvaluatedQuiz);
  }
  onClickUnEvaluatedQuiz() {
    //[routerLink]="['/evaluationDetails',index"
    this.dataService.setUnEvaluatedQuizList(this.teacherEvaluatedQuiz);
  }
  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  onChangeClass() {
    this.tab = this.tabRef.selectedIndex;
    this.route.params["classId"] = this.classId;
    this.route.params["tab"] = this.tab.toString();
    this.router.navigate(["/evaluation", this.classId, this.tab.toString()]);
    ////console.log("chanhging params")
  }

  getQuiz() {
    ////console.log(this.tabRef.selectedIndex,)
    this.tab = this.tabRef.selectedIndex;

    switch (this.tabRef.selectedIndex) {
      case 0:
        this.getUnEvaluatedQuiz();
        break;
      case 1:
        this.getEvaluatedQuiz();
        break;
    }
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
}
