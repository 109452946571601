import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { note } from "../model/notes";
import { NotesuploadComponent } from "../notesupload/notesupload.component";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatabaseManager } from "../database/database-manager";
import { AuthService } from "../services/auth.service";
import { DatetimeService } from "../util/datetime.service";
import { AlertService } from "../services/alert.service";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { AnimationOptions } from "ngx-lottie";
import { ProfileValidatorService } from "../services/profile-validator.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Class } from "../model/Class";
import { DatePipe } from "@angular/common";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit {
  quizList: note[] = [];
  teacherId: string;
  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : "";
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];

  noteList: note[] = [];
  noteIdList: any;
  links: note[] = [];

  pastValue: any = -1;
  termSubscriber: Subscription;

  constructor(
    public dialog: MatDialog,
    private title: Title,
    private uploadService: UploadService,
    public dataService: DataService,
    private dbManager: DatabaseManager,
    private auth: AuthService,
    private datetime: DatetimeService,
    private alertService: AlertService,
    private profileValidator: ProfileValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    // set title & meta
    this.title.setTitle("Notes | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
            ////console.log(this.classId)
          }
          ////console.log("tab", this.teacherId, this.classId)
          if (this.classId && this.teacherId) {
            this.getAllNotes();
          }
        });
      }
    });
  }
  ngOnDestroy() {
    this.termSubscriber.unsubscribe();
  }

  /**
   *
   * @param id
   */
  openNotesUpload(type: number, id: number) {
    //////console.log("index", id);
    //////console.log("type", type);
    if (this.profileValidator.isProfileValid()) {
      this.dataService.setNoteAction(type);
      this.dataService.setClassId(this.classId);
      if (this.classId) {
        this.dialog.open(NotesuploadComponent, {
          data: {
            noteuploadType: id,
            classId: this.classId,
            teacherId: this.teacherId,
            gradeName: this.dataService ///use this if we need
              .getActiveClassList()
              .find((x) => x.classId == this.classId).gradeName,
            subjectName: this.dataService
              .getActiveClassList()
              .find((x) => x.classId == this.classId).subjectName,
            note: new note(),
          },
        });
      } else {
        alert("no class id");
      }
    } else {
      this.alertService.showAlert(
        "Profile Not Valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
  editNote(type: number, id: number, note: note) {
    ////console.log("index", id);
    ////console.log("type", type);
    if (this.profileValidator.isProfileValid()) {
      this.dataService.setNoteAction(type);
      this.dataService.setEditNote(note);
      this.dialog.open(NotesuploadComponent, {
        data: {
          noteuploadType: id,
          note: note,
        },
      });
    } else {
      this.alertService.showAlert(
        "Profile Not Valid!",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  onSelectChange() {
    if (this.classId) {
      this.route.params["classId"] = this.classId;
      this.router.navigate(["/notes", this.classId]);
    }
  }

  getAllNotes() {
    this.dbManager
      .getAllNote(this.teacherId, this.classId)
      .snapshotChanges()
      .pipe(map((changes) => changes.map((c) => c.payload.doc.data())))
      .subscribe((data) => {
        this.noteList = data.filter((element) => element.webLink == false);
        this.links = data.filter((element) => element.webLink == true);
        ////console.log("note list ", this.noteList);
        ////console.log("weblinks list ", this.links);
      });
  }

  deleteRow(id: string, note: note) {
    var table = document.getElementById("noteTable") as HTMLTableElement;
    var el = document.getElementById(id) as HTMLTableRowElement;

    this.alertService
      .showAlert(
        "Do you want to Delete Note?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            table.deleteRow(el.rowIndex);

            this.uploadService.deleteNoteFileUpload(note); //delete note
            break;

          case DialogResult.close:
            break;
        }
      });
  }

  deleteLinkRow(id: string, link: note) {
    var table = document.getElementById("linkTable") as HTMLTableElement;
    var el = document.getElementById(id) as HTMLTableRowElement;
    this.alertService
      .showAlert(
        "Do you want to Delete Link?",
        MessageButtons.YesNo,
        MessageType.Delete
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            table.deleteRow(el.rowIndex);
            this.uploadService.deleteNoteFileUpload(link); //delete note
            break;

          case DialogResult.close:
            break;
        }
      });
  }
  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_notes.json",
    loop: false,
  };
  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }
  openNote(link) {
    window.open(link, "_blank");
  }
}
