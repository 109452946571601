import { DatePipe } from "@angular/common";
import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DatabaseManager } from "../database/database-manager";
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from "../messagebox/messagebox.component";
import { note } from "../model/notes";
import { AlertService } from "../services/alert.service";
import { DataService } from "../services/data.service";
import { UploadService } from "../services/upload.service";
import { DatetimeService } from "../util/datetime.service";

@Component({
  selector: "app-notesupload",
  templateUrl: "./notesupload.component.html",
  styleUrls: ["./notesupload.component.scss"],
})
export class NotesuploadComponent implements OnInit {
  public readonly FIRST_NOTE_TYPE: number = 0;
  public readonly SECOND_NOTE_TYPE: number = 1;
  isHovering: boolean;
  selectedFiles: FileList;
  currentNote = new note();
  progress: { percentage: number } = { percentage: 0 };

  isFileChanged: boolean = false;

  oldNote: note;
  uploadType: number = 2;
  isUploaded: boolean = false;
  format: string = "pdf";

  dialogAction: number; // 0 for new note and 1 for edit note

  noteActionType: number;
  formSubmitted: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadService: UploadService,
    private dataService: DataService,
    private dbManager: DatabaseManager,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NotesuploadComponent>,
    public datetime: DatetimeService,
    private alertService: AlertService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    ////console.log("note type", this.data.noteuploadType);
    //0 for new note and 1 for edit note
    this.currentNote = new note();
    this.dialogAction = this.dataService.getNoteAction();
    ////console.log("action type", this.dialogAction);

    if (this.dialogAction == 0) {
      this.currentNote.classId = this.data.classId;
      this.currentNote.teacherId = this.data.teacherId;
      this.currentNote.subjectName = this.data.subjectName;
      this.currentNote.gradeName = this.data.gradeName;
      this.currentNote.publishDate = this.formatDate(new Date());
      this.currentNote.publishTime = this.getCurrentTime(new Date());
      if (this.data.noteuploadType == this.FIRST_NOTE_TYPE) {
        this.currentNote.webLink = false;
        this.currentNote.webUrl = null;
      } else if (this.data.noteuploadType == this.SECOND_NOTE_TYPE) {
        this.currentNote.webLink = true;
      }
    } else if (this.dialogAction == 1) {
      this.loadNoteDetail();
    }
  }

  loadNoteDetail() {
    this.currentNote = { ...this.data.note };
    this.oldNote = { ...this.data.note };
    this.isUploaded = true;
  }

  changeNoteStatus() {
    if (this.isUploaded) {
      this.isUploaded = false;
    } else {
      this.isUploaded = true;
    }
  }

  selectFile(files) {
    this.selectedFiles = files;
    ////console.log(files);

    //////console.log(event);

    //  var format = "pdf";
    var array = files[0].name.split(".");
    var uploadedfileformat = array[array.length - 1];

    if (this.format == uploadedfileformat) {
      if (this.selectedFiles) {
        this.isUploaded = true;
        this.currentNote.fileName = files[0].name;

        if (this.dialogAction == 1) {
          this.currentNote.file = this.selectedFiles.item(0);
          this.selectedFiles = undefined;
          this.isFileChanged = true;
          ////console.log("file change", this.isFileChanged);
        } else {
        }
      }
    } else {
      this.alertService.showAlert(
        "Please add pdf type file only",
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }

  guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  }

  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }

  getCurrentTime(date: Date) {
    return this.datepipe.transform(date, "HH:mm:ss");
  }

  upload() {
    this.currentNote.srhTeacherName = this.dataService
      .getTeacherDetails()
      .teacherName.toLowerCase();
    this.currentNote.teacherName =
      this.dataService.getTeacherDetails().teacherName;
    this.currentNote.teacherPic =
      this.dataService.getTeacherDetails().profilePic;
    this.currentNote.srhSubjectName = this.data.subjectName.toLowerCase();
    this.currentNote.srhTimestamp = new Date().getTime() * -1;

    if (this.data.noteuploadType == this.FIRST_NOTE_TYPE) {
      this.currentNote.srhNoteTitle = this.currentNote.noteTitle.toLowerCase();
      const file = this.selectedFiles.item(0);
      this.currentNote.file = file;
    } else {
      this.currentNote.file = null;
    }
    this.selectedFiles = undefined;
    this.currentNote.noteId = this.guidGenerator();

    //////console.log(this.currentNote);
    if (!this.currentNote.webLink) {
      this.uploadService.pushFileToStorage(
        this.currentNote,
        this.progress,
        this.uploadType
      );
    } else if (this.currentNote.webLink) {
      this.currentNote.srhWebLink = this.currentNote.webUrl.toLowerCase();
      this.dbManager.saveNote(this.currentNote);
    }
  }

  save() {
    this.currentNote.srhTeacherName = this.dataService
      .getTeacherDetails()
      .teacherName.toLowerCase();
    this.currentNote.srhSubjectName =
      this.currentNote.subjectName.toLowerCase();
    if (!this.currentNote.webLink) {
      this.currentNote.srhNoteTitle = this.currentNote.noteTitle.toLowerCase();

      if (this.isFileChanged) {
        ////console.log("deleting old file");
        this.uploadService.deleteNoteFileStorage(this.oldNote);
        this.uploadService.pushFileToStorage(
          this.currentNote,
          this.progress,
          this.uploadType //2 for notes
        );
      } else {
        this.dbManager.saveNote(this.currentNote);
        this.close();
      }
    } else if (this.currentNote.webLink) {
      this.currentNote.srhWebLink = this.currentNote.webUrl.toLowerCase();

      this.dbManager.saveNote(this.currentNote);
      this.close();
    }
  }

  openCancelDialog() {
    this.alertService
      .showAlert(
        "Do you want to Cancel Uploading?",
        MessageButtons.ok,
        MessageType.warning
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        switch (result) {
          case DialogResult.yes:
            this.progress.percentage = 0;
            this.uploadService.cancelUploadTask();

            break;

          case DialogResult.close:
            break;
        }
      });
  }

  close() {
    this.uploadService.isActiveRunning().subscribe((resp) => {
      if (!resp) {
        this.uploadService.uploadTask = null;
        this.dialogRef.close();
      }
    });
  }

  closeWindow() {
    this.dialogRef.close();
    ////console.log("testfunction");
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    const mimeType = files[0].type;
    if (mimeType.match(/pdf\/*/) == null) {
      this.alertService.showAlert(
        "Please add pdf type file only",
        MessageButtons.ok,
        MessageType.warning
      );
      return;
    }

    this.selectFile(files);
  }

  isNoteFormValid() {
    if (
      this.currentNote.noteTitle &&
      this.currentNote.noteDescription &&
      !this.currentNote.webLink
    ) {
      return true;
    }
    return false;
  }

  isWebLinkFormValid() {
    if (
      this.currentNote.webUrl &&
      this.currentNote.noteDescription &&
      this.currentNote.webLink
    ) {
      return true;
    }
    return false;
  }
}
