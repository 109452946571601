import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ProfileComponent } from "./profile/profile.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { environment } from "../environments/environment";
import { ReactiveFormsModule } from "@angular/forms";

import { QuizComponent } from "./quiz/quiz.component";
import { QuizuploadComponent } from "./quizupload/quizupload.component";
import { DatePipe } from "@angular/common";
import { NotesComponent } from "./notes/notes.component";
import { NotesuploadComponent } from "./notesupload/notesupload.component";
import { VideoComponent } from "./video/video.component";
import { VideouploadComponent } from "./videoupload/videoupload.component";
import { CanceldialogComponent } from "./canceldialog/canceldialog.component";
import { AngularFireStorage, AngularFireStorageModule, } from "@angular/fire/storage";
import { EvaluationComponent } from "./evaluation/evaluation.component";
import { EvaluationDetailsComponent } from "./evaluation-details/evaluation-details.component";
import { EvaluationSingleComponent } from "./evaluation-single/evaluation-single.component";
import { CancelUploadEvaluationComponent } from "./cancel-upload-evaluation/cancel-upload-evaluation.component";
import { InsightComponent } from "./insight/insight.component";
import { MessageboxComponent } from "./messagebox/messagebox.component";
import { SigninComponent } from "./signin/signin.component";
import { AuthService } from "./services/auth.service";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { ImageUploadComponent } from "./image-upload/image-upload.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { CreateClassComponent } from "./create-class/create-class.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LiveClassesComponent } from "./live-classes/live-classes.component";
import { CreateLiveClassComponent } from "./create-live-class/create-live-class.component";
import { VideoViewerComponent } from "./video-viewer/video-viewer.component";
import { NoteComponent } from "./note/note.component";
import { EditVideoComponent } from "./edit-video/edit-video.component";
import { SubscribeSubjectComponent } from "./subscribe-subject/subscribe-subject.component";
import { DigiConferenceComponent } from "./digi-conference/digi-conference.component";
import { AdvertisingComponent } from "./advertising/advertising.component";
import { InquiryComponent } from "./inquiry/inquiry.component";

//
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";
import { NotificationService } from "./services/notification.service";
import { ProfileValidatorService } from "./services/profile-validator.service";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { DropzoneDirective } from "./directives/dropzone.directive";
import { PaymentclassComponent } from "./paymentclass/paymentclass.component";
import { HttpClientModule } from "@angular/common/http";
import { StudentDetailComponent } from "./student-detail/student-detail.component";
import { NgxImageCompressService } from "ngx-image-compress";
import { NoticesComponent } from './notices/notices.component';// image compresser
export function playerFactory() {
  return player;
}

// show more 
import { AngReadmoreModule } from 'ang-readmore';
import { Er404Component } from './er404/er404.component'
// time ago
import TimeAgo from 'javascript-time-ago'
// TimeAgo English.
import en from 'javascript-time-ago/locale/en';
import { CreateAdvertisingComponent } from './create-advertising/create-advertising.component';
import { CreateDigiConferenceComponent } from './create-digi-conference/create-digi-conference.component';
import { PaymentCardsComponent } from './payment-cards/payment-cards.component';
import { PaymentConferenceComponent } from './payment-conference/payment-conference.component';

//Config TimeAgo
TimeAgo.addDefaultLocale(en)

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    QuizComponent,
    QuizuploadComponent,
    NotesComponent,
    NotesuploadComponent,
    VideoComponent,
    VideouploadComponent,
    CanceldialogComponent,
    EvaluationComponent,
    EvaluationDetailsComponent,
    EvaluationSingleComponent,
    CancelUploadEvaluationComponent,
    InsightComponent,
    MessageboxComponent,
    SigninComponent,
    ImageUploadComponent,
    CreateClassComponent,
    LiveClassesComponent,
    CreateLiveClassComponent,
    VideoViewerComponent,
    NoteComponent,
    EditVideoComponent,
    SubscribeSubjectComponent,
    DigiConferenceComponent,
    AdvertisingComponent,
    InquiryComponent,
    LandingPageComponent,
    DropzoneDirective,
    PaymentclassComponent,
    StudentDetailComponent,
    NoticesComponent,
    Er404Component,
    CreateAdvertisingComponent,
    CreateDigiConferenceComponent,
    PaymentCardsComponent,
    PaymentConferenceComponent,
  ],
  entryComponents: [
    QuizuploadComponent,
    CanceldialogComponent,
    NotesuploadComponent,
    CancelUploadEvaluationComponent,
    EvaluationSingleComponent,
    SigninComponent,
    ImageUploadComponent,
    CreateLiveClassComponent,
    MessageboxComponent,
    NoteComponent,
    EditVideoComponent,
    SubscribeSubjectComponent,
    CreateClassComponent,
    CreateDigiConferenceComponent,
    StudentDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatStepperModule,
    MatDatepickerModule,
    FormsModule,
    MatTabsModule,
    MatMenuModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    //angularfire modules for auth
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    ImageCropperModule,
    NgbModule,
    LottieModule.forRoot({ player: playerFactory }),
    HttpClientModule,
    AngReadmoreModule,
    MatProgressBarModule
  ],
  providers: [
    DatePipe,
    AngularFireStorage,
    AuthService,
    ProfileValidatorService,
    NotificationService,
    NgxImageCompressService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
