export class note {
  noteId: string;
  noteUrl: string;
  noteTitle: string;
  noteDescription: string;
  webLink: boolean;
  webUrl: string;
  publishDate: string;
  publishTime: string;
  teacherId: string;
  teacherName: string;
  teacherPic: string;
  classId: string;
  subjectName: string;
  gradeName: string
  gradeId: string;
  key: string;
  fileName: string;
  file: File;
  srhNoteTitle: string;
  srhSubjectName: string;
  srhTeacherName: string;
  srhWebLink: string;
  srhTimestamp: number;
}
