import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { AnimationOptions } from "ngx-lottie";
import { DatabaseManager } from "../database/database-manager";
import { Class } from "../model/Class";
import { Student } from "../model/student";
import { AuthService } from "../services/auth.service";
import { DataService } from "../services/data.service";
import { DatetimeService } from "../util/datetime.service";

@Component({
  selector: "app-insight",
  templateUrl: "./insight.component.html",
  styleUrls: ["./insight.component.scss"],
})
export class InsightComponent implements OnInit {
  status: number = 1;
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];
  termSubscriber: any;
  classId: string;
  classType: string;
  startTime: string;
  endTime: string;
  day: String;
  participantsList = new Array<Student>();
  teacherId: any;
  isLoading: boolean = true;

  option: AnimationOptions = {
    path: '/assets/lti/lti_notices.json', loop: false
  };
  loadingAnim: AnimationOptions = {
    path: '/assets/lti/lti_loading.json', loop: true
  };

  constructor(
    private datetime: DatetimeService, private title: Title,
    private route: ActivatedRoute,
    public dataService: DataService,
    private dbManager: DatabaseManager,
    public datepipe: DatePipe,
    public auth: AuthService
  ) { }


  ngOnInit() {
    // set title & meta
    this.title.setTitle("Insight | Tutor - Digi Panthiya");

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
          }
          if (param["status"]) {
            this.status = parseInt(param["status"]);
          }
          if (param["classType"]) {
            this.classType = param["classType"];
          }
          if (param["startTime"]) {
            this.startTime = param["startTime"];
          }
          if (param["endTime"]) {
            this.endTime = param["endTime"];
          }
          if (param["classDay"]) {
            this.day = param["classDay"];
          }
          if (this.classId && this.classType && this.startTime) {
            this.getClassParticipation();
          }
        });
      }
    });
  }

  changeStatus(status: number) {
    this.status = status;
  }

  getClassParticipation() {
    this.dbManager
      .getStudentEnrolledClass(this.teacherId, this.classId)
      .then((data) => {
        if (data.data() !== undefined) {
          data.data().idSet.forEach(element => {
            this.dbManager.getStudent(element).then(std => {
              this.isLoading = false;
              this.participantsList.push(<Student>std.data());
            })
          });
        } else {
          this.isLoading = false;
        }
      });
  }

  getTime(time) {

    return this.datetime.changeTimeFormat2(time);
  }
  ngOnDestroy() {
    this.termSubscriber.unsubscribe();
  }
}
