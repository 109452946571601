export const environment = {
  production: true,
  firebaseConfig: {

    // real project
    apiKey: "AIzaSyBl4R_tkaoxco9VX0CNgNDWL1fd0x01O9I",
    authDomain: "digipanthiya-bc66f.firebaseapp.com",
    databaseURL: "https://digipanthiya-bc66f-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "digipanthiya-bc66f",
    storageBucket: "digipanthiya-bc66f.appspot.com",
    messagingSenderId: "824107063081",
    appId: "1:824107063081:web:4c0c5f8e18978696aac938",
    measurementId: "G-QG3VQ7QMEY"

    // //test project
    // apiKey: "AIzaSyAVEKQxutDYnaZGqSDc9Aa25iaKDoIDXls",
    // authDomain: "projectdigi-test.firebaseapp.com",
    // databaseURL: "https://projectdigi-test-default-rtdb.firebaseio.com",
    // projectId: "projectdigi-test",
    // storageBucket: "projectdigi-test.appspot.com",
    // messagingSenderId: "602173945597",
    // appId: "1:602173945597:web:9d0ddb4d65a1cb49433094",
    // measurementId: "G-PRY3KPY0CN"

  },
};