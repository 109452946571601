import { MatDialog } from "@angular/material";
import { Component, OnInit } from "@angular/core";
import { UploadService } from "../services/upload.service";
import { DataService } from "../services/data.service";
import { DatabaseManager } from "../database/database-manager";
import { DatetimeService } from "../util/datetime.service";
import { AnimationOptions } from "ngx-lottie";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Class } from "../model/Class";
import { DatePipe } from "@angular/common";
import { StudentDetailComponent } from "../student-detail/student-detail.component";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-paymentclass",
  templateUrl: "./paymentclass.component.html",
  styleUrls: ["./paymentclass.component.scss"],
})
export class PaymentclassComponent implements OnInit {
  pastValue;
  yearList = [2022, 2023, 2024, 2025, 2026, 2027];
  monthList = [
    { month: "January", val: 1 },
    { month: "February", val: 2 },
    { month: "March", val: 3 },
    { month: "April", val: 4 },
    { month: "May", val: 5 },
    { month: "June", val: 6 },
    { month: "July", val: 7 },
    { month: "August", val: 8 },
    { month: "September", val: 9 },
    { month: "October", val: 10 },
    { month: "November", val: 11 },
    { month: "December", val: 12 },
  ];
  paymentList: any[] = [];
  selectedYear: any;
  selectedMonth: any;
  totalParticipations = 0;
  totalAmount = 0;
  //percentage that determine institue's fee
  deductPercentage: number = 2;
  isDataLoaded: boolean = false;
  selected = true;
  activeClassList: Class[] =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()
      : [];
  teacherId: string;
  classId: string =
    this.dataService.getActiveClassList().length > 0
      ? this.dataService.getActiveClassList()[0].classId
      : "";
  termSubscriber: Subscription;
  lottie: AnimationOptions = {
    path: "assets/lti/lti_not-available-feature.json",
    loop: false,
  };
  nolottie: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
  constructor(
    public uploadService: UploadService,
    private title: Title,
    private dbManager: DatabaseManager,
    public auth: AuthService,
    public dialog: MatDialog,
    public dataService: DataService,
    private datetime: DatetimeService,
    private route: ActivatedRoute,
    public datepipe: DatePipe
  ) {}
  ngAfterContentInit() {
    // //console.log("runing ngAfterContentInit")
  }
  ngAfterViewInit() {
    // //console.log("runing ngAfterViewInit")
  }
  ngOnInit() {
    // set title & meta
    this.title.setTitle("Payment | Tutor - Digi Panthiya");

    // get fee for payment calculation
    this.dbManager.getPricing().then((result) => {
      this.deductPercentage = result.profit;
      this.isDataLoaded = true;
    });

    this.pastValue = -1;

    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.teacherId = this.auth.userData.uid;
        this.termSubscriber = this.route.params.subscribe((param: any) => {
          if (param["classId"]) {
            this.classId = param["classId"];
            ////console.log(this.classId);
          }

          ////console.log("tab",this.teacherId,this.classId)

          if (this.classId && this.teacherId) {
          }
        });
      }
    });
  }
  ngOnDestroy() {
    // ////console.log("destroying")
    this.termSubscriber.unsubscribe();
  }

  onSelectChange() {
    this.totalAmount = 0;
    this.paymentList = [];
    this.selected = false;
    let totalAmount = 0;
    if (this.classId && this.selectedMonth && this.selectedYear) {
      this.route.params["classId"] = this.classId;
      this.route.params["month"] = this.selectedMonth;
      this.route.params["year"] = this.selectedYear;
      ////console.log("class id", this.classId);

      this.dbManager
        .getEnrolledStudentsPayemnt(
          this.classId,
          parseInt(this.selectedYear),
          parseInt(this.selectedMonth)
        )
        .then((payments: Array<any>) => {
          payments.forEach((payment) => {
            totalAmount =
              totalAmount +
              payment.week1Amount +
              payment.week2Amount +
              payment.week3Amount +
              payment.week4Amount;
            // //console.log(totalAmount);
          });

          this.totalAmount = totalAmount;

          this.paymentList = this.paymentList.concat(payments);
        });

      //   this.dbManager
      //     .getStudentEnrolledClass(this.teacherId, this.classId)
      //     .then((data) => {
      //       if (data.data()) {
      //         const stdList = data.data().idSet;
      //         this.paymentList = [];
      //         stdList.forEach((x: any) => {
      //           this.dbManager
      //             .getStudentPayments(
      //               x,
      //               this.teacherId,
      //               this.selectedYear,
      //               this.selectedMonth
      //             )
      //             .then((result) => {
      //               // let data = result.data();
      //               // result.docs.map((data) => console.log(data.data()));
      //               // console.log();
      //               const paymnetArray = result.docs;
      //               if (paymnetArray.length > 0) {
      //                 let totalAmount = 0;
      //                 console.log(paymnetArray.length);
      //                 paymnetArray.forEach((x) => {
      //                   let stdValue = x.data();
      //                   // console.log(stdValue.week1Amount);

      //                   totalAmount =
      //                     totalAmount +
      //                     stdValue.week1Amount +
      //                     stdValue.week2Amount +
      //                     stdValue.week3Amount +
      //                     stdValue.week4Amount;
      //                   // //console.log(totalAmount);
      //                 });

      //                 const data = {
      //                   studentName: paymnetArray[0].data().studentName,
      //                   studentId: paymnetArray[0].data().studentId,
      //                   week1: paymnetArray.some((x) => x.data().week1 === 1),
      //                   week2: paymnetArray.some((x) => x.data().week2 === 1),
      //                   week3: paymnetArray.some((x) => x.data().week3 === 1),
      //                   week4: paymnetArray.some((x) => x.data().week4 === 1),
      //                   total: totalAmount,
      //                 };
      //                 // console.log(data);

      //                 this.paymentList.push(data);
      //                 // console.log(this.paymentList);
      //                 // const finalTotal = totalAmount - totalAmount * 0.2;
      //                 this.totalAmount = totalAmount + this.totalAmount;
      //               }
      //             });
      //           ////console.log("final payment lis", this.paymentList);
      //         });
      //       }
      //     });
      // }
    }
  }

  viewDetails(stdId) {
    this.dbManager.getStudent(stdId).then(
      (res) => {
        ////console.log(res.data());
        if (res.data()) {
          this.dialog.open(StudentDetailComponent, {
            data: {
              studentInfo: res.data(),
            },
          });
        }
      },
      (error) => {}
    );
  }

  getMenu(id) {
    this.pastValue = id;
  }

  quitMenu() {
    this.pastValue = -1;
  }
  getTime(time) {
    return this.datetime.changeTimeFormat(time);
  }

  option: AnimationOptions = {
    path: "/assets/lti/lti_notices.json",
    loop: false,
  };
  formatDate(date: Date) {
    return this.datepipe.transform(date, "yyyy-MM-dd");
  }
  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "LKR",
    });
    return formatter.format(value);
  }
}
