export class Marks {
  markId: string;
  marks: number;
  markedDate: string;
  markedTime: string;
  duration: string;
  isEvaluated: boolean;
  studentName: string;
  studentPic: string;
  studentId: string;
  answerSheetUrl: string;
  submittedDate: string;
  submittedTime: string;
  fileName: string;
  file: File;
}
