export class LiveClass {
  uploadedDate: string = null;
  uploadedTime: string = null;
  liveId: string = null;
  classId: string = null;
  meetingNumber: string = null;
  role: number = null;
  password: string = null;
  shareScreen: boolean = false;
  extraClass: boolean = false;
  extraClassDate: Date = null;
  classTitle: string = null;
  startingTime: string = null;
  day: string = null;
  endingTime: string = null;
  teacherPic: string = null;
  teacherId: string = null;
  subjectName: string = null;
  gradeName: string = null;
}
